// @flow
import storage from 'local-storage-fallback'
import unset from 'lodash/unset'
import get from 'lodash/get'
import set from 'lodash/set'
import entries from 'lodash/entries'

import { captureException } from 'conversional-exceptions'

import blackList from './blackList'
import { JourneySelectors } from 'conversional-journey'

const STORAGE_KEY = 'conversional-state'
export const STORAGE_EXPIRATION_IN_MILLIS = 360000
export const STORAGE_CREATED_AT_NAME = 'createdAt'

let questionnaireId

export const setKey = key => {
  questionnaireId = key
}

export const getItem = function(key) {
  const saveKey = namespaceKey(key)
  const item = storage.getItem(saveKey)
  return item
}

export const setItem = function(key, value) {
  const saveKey = namespaceKey(key)
  storage.setItem(saveKey, value)
  return saveKey
}

const namespaceKey = function(key) {
  const appendix = questionnaireId || ''
  return appendix ? `${key}-${appendix}` : key
}

export const loadState = () => {
  try {
    const serializedState = getItem(STORAGE_KEY)
    if (serializedState === null) {
      return undefined
    }

    const state = JSON.parse(serializedState)
    const validState = removeExpiredAttributes(state)

    return validState
  } catch (err) {
    captureException(err)
    return undefined
  }
}

export const saveState = state => {
  const stateToPersist = filter(state)
  const stateWithExpiryTime = setTimestamp(stateToPersist)
  try {
    const serializedState = JSON.stringify(stateWithExpiryTime)
    setItem(STORAGE_KEY, serializedState)
  } catch (err) {
    // Ignore write errors
  }
}

export const filter = state => {
  const filteredState = JSON.parse(JSON.stringify(state))

  for (const prop of blackList) {
    unset(filteredState, prop)
  }

  return filteredState
}

export const removeExpiredAttributes = state => {
  // Remove results if tokens don't match
  const tokens = entries(get(state, 'config.tokens', {}))
  tokens.forEach(([journeyId, token]) => {
    if (get(state, `result.${journeyId}.config.alias`) !== token) {
      unset(state, `result.${journeyId}`)
    }
  })

  // If state is not expired, return it as it is
  if (
    !state.journey ||
    !state.journey[STORAGE_CREATED_AT_NAME] ||
    !isExpired(state.journey[STORAGE_CREATED_AT_NAME])
  ) {
    return state
  }

  // Collect the values to keep
  const newState = {}
  const journeys = JourneySelectors.selectJourneys(state)
  entries(journeys).forEach(([journeyId, journey]) => {
    const token = get(state, `questionnaire.${journeyId}.token`)

    if (token) {
      set(newState, `questionnaire.${journeyId}.token`, token)
    }

    if (journey.hideExitIntent) {
      set(newState, `journey.${journeyId}.hideExitIntent`, journey.hideExitIntent)
    }
  })

  return newState
}

export const isExpired = timestamp => {
  return new Date().getTime() - timestamp > STORAGE_EXPIRATION_IN_MILLIS
}

export const setTimestamp = state => {
  if (state.journey && !state.journey[STORAGE_CREATED_AT_NAME]) {
    return {
      ...state,
      journey: {
        ...state.journey,
        [STORAGE_CREATED_AT_NAME]: new Date().getTime()
      }
    }
  }

  return state
}

const store = {
  getItem,
  setItem
}

export default store
