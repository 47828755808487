// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import { addCallbacks } from 'conversional-callbacks'
import { TrackingActions, Events } from 'conversional-journey'

import { Primary } from '../buttons/Buttons'
import ModalButtonComponent from './ModalButtonComponent'
import ModalComponent from './ModalComponent'

type IState = {
  modalIsOpen: boolean
}

type IProps = {
  dispatch: () => {},
  componentTitle?: string,
  spots: {
    button: React.Element<*>,
    modal: React.Element<*>
  },
  styles?: {
    desktop?: {},
    mobile?: {},
    general?: {}
  },
  modalStyles?: {
    desktop?: {},
    mobile?: {},
    general?: {}
  },
  theme: {},
  callbacks: {
    on: string,
    trigger: string
  }
}

/**
 * Purpose      Renders an interactive element. When clicked, it opens a modal that is passed as child component(s)
 * Responsive   Modal becomes full-screen on mobile.
 * Nestable     True, requires 2 children: BUTTON and MODAL
 * Base         False, Modal and Button are usually base components
 */
class ModalButtonContainer extends React.PureComponent<IProps, IState> {
  static defaultProps = {
    spots: {}
  }

  constructor(props) {
    super(props)
    this.state = {
      showModal: false
    }
  }

  componentDidMount = () => {
    const supportedCallbacks = {
      closeModalCallback: this.closeModal,
      toggleModalCallback: this.toggleModal,
      openModalCallback: this.openModal
    }
    addCallbacks(this, this.props.callbacks || {}, supportedCallbacks)
  }

  openModal = () => {
    this.props.dispatch(
      TrackingActions.event({
        ...Events.MODALBUTTON_OPEN,
        label: this.props.componentTitle || 'untitled'
      })
    )
    this.setState({ showModal: true })
  }

  closeModal = () => {
    this.props.dispatch(
      TrackingActions.event({
        ...Events.MODALBUTTON_CLOSE,
        label: this.props.componentTitle || 'untitled'
      })
    )
    this.setState({ showModal: false })
  }

  toggleModal = debounce(
    () => {
      this.setState({ showModal: !this.state.showModal })
    },
    500,
    { leading: true, trailing: false }
  )

  render() {
    return (
      <React.Fragment>
        <ModalButtonComponent
          styles={this.props.styles}
          openModal={this.openModal}
          theme={this.props.theme}
        >
          {this.props.spots.button || (
            <Primary theme={this.props.theme}>
              {this.props.componentTitle || 'Mehr erfahren'}
            </Primary>
          )}
        </ModalButtonComponent>
        <ModalComponent
          showModal={this.state.showModal}
          closeModal={this.toggleModal}
          theme={this.props.theme}
          styles={this.props.modalStyles}
        >
          {this.props.spots.modal}
        </ModalComponent>
      </React.Fragment>
    )
  }
}

export default connect(null)(ModalButtonContainer)
