import { style } from 'typestyle'

export const rootStyle = style({
  marginBottom: 10
})

export const inputStyle = style({
  border: '1px solid #ccc',
  padding: 16,
  width: 180
})

export const buttonStyle = style({
  border: '1px solid #ccc',
  padding: 16,
  backgroundColor: '#eee',
  marginLeft: 10
})

export const errorStyle = theme =>
  style({
    color: '#900',
    paddingTop: 6,
    fontSize: theme.typo.sizes.m
  })
