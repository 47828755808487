// @flow
import mergeWith from 'lodash/mergeWith'
import isArray from 'lodash/isArray'
import cloneDeep from 'lodash/cloneDeep'

import { colors } from './colors/colors'
import { borders } from './borders/borders'
import { elements } from './elements/elements'
import typography from './typography/typography'
import spaces from './spaces/spaces'

export const defaultTheme = {
  colors: { ...colors },
  typo: { ...typography },
  spaces: { ...spaces },
  borders: { ...borders },
  elements: { ...elements }
}

const overwriteMerge = (objValue, srcValue) => {
  if (isArray(objValue)) {
    return srcValue
  }
}

export function createTheme(theme = {}) {
  const newTheme = cloneDeep(defaultTheme)

  Object.keys(theme).forEach(themeProperty => {
    newTheme[themeProperty] = mergeWith(
      newTheme[themeProperty],
      theme[themeProperty],
      overwriteMerge
    )
  })

  return newTheme
}
