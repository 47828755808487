const createComponentsFromObject = function(object, theme) {
  const { default: createRecursiveComponent } = require('../../builder/builder')
  if (!object) return
  const componentList = Object.keys(object)

  if (componentList.length === 0) return
  componentList.sort()

  return componentList.map(key => {
    return createRecursiveComponent(key, object[key], theme)
  })
}

export default createComponentsFromObject
