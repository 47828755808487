// @flow
import * as React from 'react'
import { connect } from 'react-redux'

import { ResultActions } from 'conversional-journey'

import SelectListInput from './SelectListInput'

type IProps = {
  schema: {
    icon?: string
  },
  id: string,
  journeyId?: number,
  placeholder?: string,
  value?: string,
  required: boolean,
  onChange: () => {},
  getLocations: () => {},
  theme: any,
  className?: string,
  placeholder?: string
}

/**
 * Prepopulates the selection list with available locations
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const SelectListInputWithLocations = ({ locations, ...props }: IProps) => {
  const locationsAsList = {}

  if (!locations) {
    props.getLocations(props.journeyId)
  }

  locations.map(entry => {
    if (entry.key && entry.name) {
      locationsAsList[entry.key] = entry.name
    }
  })

  const selectProps = {
    ...props,
    schema: {
      ...props.schema,
      list: locationsAsList
    }
  }

  return <SelectListInput {...selectProps} />
}

SelectListInputWithLocations.defaultProps = {
  journeyid: null
}

const connector = state => ({
  locations: state.locations
})

const mapDispatchToProps = dispatch => ({
  getLocations: (closeTo, journeyId) => dispatch(ResultActions.getLocations(closeTo, journeyId))
})

export default connect(
  connector,
  mapDispatchToProps
)(SelectListInputWithLocations)
