import { createTheme, defaultTheme } from 'conversional-theme'

import * as Actions from '../actions'
import * as QuestionnaireActions from '../../questionnaire/actions'
import * as JourneyActions from '../../journey/actions'

export const initialState = {
  theme: {}
}

export const initialConfigState = {
  tokens: {}
}

export function common(state = initialState, action) {
  switch (action.type) {
    case Actions.LOAD_THEME:
      return {
        ...state,
        theme: {
          ...state.theme,
          [action.journeyId]: createTheme(action.theme)
        }
      }

    case JourneyActions.LOAD_JOURNEY:
      return {
        ...state,
        theme: {
          ...state.theme,
          [action.journeyId]: state.theme[action.journeyId] || defaultTheme
        }
      }

    case JourneyActions.LOAD_JOURNEY_SUCCEEDED:
      return {
        ...state,
        theme: {
          ...state.theme,
          [action.journeyId]: createTheme(action.config.theme)
        }
      }

    case Actions.LOG_CTA:
      return {
        ...state,
        last_cta: {
          cta: action.cta,
          label: action.label
        }
      }

    default:
      return state
  }
}

export const config = (state = initialConfigState, action) => {
  if (action.type === Actions.SET_CONFIG) {
    return {
      ...state,
      ...action.payload.config
    }
  }

  if (action.type === QuestionnaireActions.SEND_ANSWERS_SUCCEEDED) {
    return {
      ...state,
      tokens: {
        ...state.tokens,
        [action.journeyId]: action.response.token
      }
    }
  }

  return state
}
