// @flow
import * as React from 'react'
import { StyleSheet, css } from 'aphrodite/no-important'
import MarkdownComponent from '../markDown/MarkDownComponent'
import BaseComponent from '../base/BaseComponent'
import GridBase from '../grid/GridBase'
import { sizes, mediaBreakpointUp, mediaBreakpointDown } from 'conversional-theme'
import Icon from '../icons/Icon'
import { ICONS } from '../icons/IconList'

type IconGridProps = {
  backgroundColor?: string,
  cols: number,
  componentKey: string,
  defaultIcon: string,
  iconColor: string,
  iconSize: string,
  list: Array<{ icon: string, label: string }>,
  listType: 'text' | 'icon',
  notResponsive?: boolean,
  title?: string,
  subtitle?: string,
  iconRowStyle: {},
  styles: {},
  theme: {}
}

// Available list types
const LIST_TYPES = {
  icon: 'icon',
  text: 'text'
}

/**
 * IconGridComponent
 * Purpose      Organizes icon-lists in single- or multi column structures.
 *              List items will be equally split among columns, automatically calculating the number of rows.
 * Responsive   None / Uses responsive behavior of GridBase
 * Nestable     False
 * Base         False
 */
class IconGridComponent extends React.PureComponent<IconGridProps, null> {
  render() {
    const {
      cols,
      listType,
      list,
      title,
      subtitle,
      defaultIcon,
      componentKey,
      iconSize,
      iconRowStyle,
      theme
    } = this.props

    const iconColor = this.props.iconColor || theme.colors.text

    const iconList = list || []
    const colSize = Math.ceil(iconList.length / cols)
    const rows = 'auto '.repeat(colSize)
    return (
      <BaseComponent
        componentKey={componentKey}
        injectedStyles={this.props.styles}
        styles={Styles(theme).container}
      >
        {title && <h3 className={css(Styles(theme).title)}>{title}</h3>}
        {subtitle && <div className={css(Styles(theme).subtitle)}>{subtitle}</div>}

        <GridBase gridRows={rows} rows={colSize} cols={cols} mobileStyles={false} theme={theme}>
          {iconList.map((item, i) => {
            const _iconName = item.icon ? item.icon : defaultIcon
            const _icon = ICONS[_iconName]
            return (
              <div
                key={`${componentKey}_icon_${i}`}
                className={css(
                  Styles(theme, iconRowStyle).flexRow,
                  listType === LIST_TYPES.text && Styles(theme).spacing
                )}
              >
                {listType === LIST_TYPES.icon && (
                  <div className={css(Styles(theme).icon)}>
                    <Icon theme={theme} icon={_icon} color={iconColor} size={iconSize} />
                  </div>
                )}
                {listType === LIST_TYPES.text && (
                  <span className={css(DynamicStyles(theme, iconColor).counter)}>{i + 1}.</span>
                )}
                <span className={css(Styles(theme).iconLabel)}>
                  <MarkdownComponent text={item.label} />
                </span>
              </div>
            )
          })}
        </GridBase>
      </BaseComponent>
    )
  }
}

IconGridComponent.defaultProps = {
  cols: 1,
  defaultIcon: 'chevron',
  iconSize: '1rem',
  listType: 'text'
}

const Styles = (theme, iconRowStyle) =>
  StyleSheet.create({
    iconLabel: {
      paddingLeft: theme.spaces.desktop.s
    },
    container: {
      textAlign: 'left',
      [mediaBreakpointDown(sizes.sm)]: {
        paddingTop: '22px !important',
        marginLeft: 'auto !important',
        marginRight: 'auto !important'
      }
    },
    title: {
      textAlign: 'center',
      lineHeight: theme.spaces.desktop.L,
      [mediaBreakpointUp(sizes.md)]: {
        paddingTop: '40px !important',
        paddingBottom: '40px !important'
      },
      [mediaBreakpointUp(sizes.sm)]: {
        paddingBottom: theme.spaces.desktop.xs
      }
    },
    subtitle: {
      fontFamily: theme.typo.fonts.bold,
      paddingTop: 10,
      paddingBottom: 15
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'normal',
      ...iconRowStyle
    },
    spacing: {
      marginBottom: theme.spaces.desktop.m
    }
  })

const DynamicStyles = (theme, color) =>
  StyleSheet.create({
    counter: {
      paddingTop: '6px',
      lineHeight: '16px',
      fontSize: '32px',
      fontFamily: theme.typo.fonts.byHand,
      color: color
    }
  })

export default IconGridComponent
