// @flow
import React from 'react'
import { connect } from 'react-redux'
import { TrackingActions, Events, CommonSelectors } from 'conversional-journey'
import { createStructuredSelector } from 'reselect'

type LinkProps = {
  href?: string,
  theme: any,
  children?: Array<{}>,
  dispatch: () => {}
}

/**
 * This helper contains renders for the elements we want to override when
 * rendering the markdown.
 *
 * LinkRenderer sets:
 * - target = _blank
 * - color = brandColor
 * - font style = underline
 */
export const linkRenderer = (props: LinkProps) => {
  const onClick = () => {
    props.dispatch(TrackingActions.event({ ...Events.LINK_CLICK, label: props.href }))
  }

  const coreProps = {}
  coreProps.onClick = onClick
  coreProps.target = '_blank'
  coreProps.href = props.href
  coreProps.style = {
    color: props.theme.colors.brand,
    textDecoration: 'underline'
  }

  return React.createElement('a', coreProps, props.children)
}

const mapStateToProps = createStructuredSelector({
  theme: CommonSelectors.selectTheme
})

export default connect(mapStateToProps)(linkRenderer)
