// @flow
import * as React from 'react'
import { css, StyleSheet } from 'aphrodite/no-important'
import { sizes, mediaBreakpointUp, mediaBreakpointDown } from 'conversional-theme'

import Icon from '../icons/Icon'
import { ICONS } from '../icons/IconList'

type CardProps = {
  img: string,
  label: string,
  ratings: Array<{ name: string, stars: number }>,
  theme: {},
  selected?: boolean
}

export const CardSelectedIcon = ({ theme }: CardProps) => (
  <div className={css(SelectedStyle(theme).icon)} title="Ihre Auswahl">
    <Icon theme={theme} icon={ICONS.star} color="white" size={26} />
  </div>
)

function CardComponent({ img, label, ratings, selected, theme }: CardProps) {
  return (
    <div className={css(Styles(theme).card)}>
      <div className={css(DynamicStyles(img).imageBox)} />
      {selected && <CardSelectedIcon theme={theme} />}
      <div className={css(Styles(theme).flexVertical)}>
        <span className={css(Styles(theme).title)}>{label}</span>
        <div className={css(Styles(theme).flex)}>
          <table>
            <tbody>
              {ratings.map(rating => (
                <tr key={`rating_${label}_${rating.name}`}>
                  <td>{rating.name}</td>
                  <td className={css(Styles(theme).rating)}>
                    <Icon
                      theme={theme}
                      key={`rating_${label}_${rating.name}`}
                      icon={ICONS.star}
                      multiple={rating.stars}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const Styles = theme =>
  StyleSheet.create({
    card: {
      backgroundColor: theme.colors.backgroundLight,
      [mediaBreakpointDown(sizes.sm)]: {
        backgroundColor: theme.colors.backgroundRegular
      },
      display: 'inline-block',
      marginRight: theme.spaces.desktop.s,
      position: 'relative'
    },
    flex: {
      display: 'flex',
      flexDirection: 'row'
    },
    flexVertical: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spaces.desktop.s,
      [mediaBreakpointDown(sizes.sm)]: {
        margin: theme.spaces.mobile.xs
      }
    },
    rating: {
      paddingLeft: theme.spaces.desktop.s
    },
    title: {
      maxWidth: 180,
      fontFamily: theme.typo.fonts.bold,
      [mediaBreakpointDown(sizes.sm)]: {
        marginBottom: theme.spaces.mobile.xs
      },
      [mediaBreakpointUp(sizes.md)]: {
        textTransform: 'uppercase'
      }
    },
    description: {
      wordWrap: 'break-word',
      whiteSpace: 'normal',
      fontFamily: theme.typo.fonts.regular
    }
  })

const DynamicStyles = img =>
  StyleSheet.create({
    imageBox: {
      width: '226px',
      height: '172px',
      backgroundImage: `url(${img})`,
      backgroundPosition: 'center center',
      backgroundSize: 'cover'
    }
  })

const SelectedStyle = theme =>
  StyleSheet.create({
    icon: {
      position: 'absolute',
      top: 5,
      left: 5,
      boxShadow: '5px 5px 5px 5px rgba(255, 255, 255, 0.1)',
      borderRadius: 26,
      padding: 8,
      backgroundColor: theme.colors.brand,
      border: '2px solid white'
    }
  })

CardComponent.defaultProps = {
  ratings: []
}

export default CardComponent
