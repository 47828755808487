// @flow
import * as React from 'react'
import Markdown from 'react-markdown'
import BaseComponent from '../base/BaseComponent'
import linkRenderer from './MarkDownRenderers'

/**
 * Use './MarkDownRenderers' to define custom renderers, in order to override the native tag.
 * See https://github.com/rexxars/react-markdown#node-types
 */
const props = {
  renderers: {
    paragraph: 'span',
    link: linkRenderer
  }
}

type MarkDownProps = {
  text: string | Array<string>,
  componentKey: string,
  styles?: {}
}

// Todo: add key to children
// Request has been added to github: https://github.com/rexxars/react-markdown/issues/249

/**
 * MarkDownComponent
 * Purpose      Renders a string or an array of strings as Paragraph with Markdown styling incl. links.
 * Responsive   None
 * Nestable     False
 * Base         False
 */
class MarkDownComponent extends React.PureComponent<MarkDownProps, void> {
  // Todo: enable backend to return label as array and with string replacent
  render() {
    const { text, componentKey, styles, theme } = this.props
    //TOdo. add index to key
    const markup = Array.isArray(text) ? (
      text.map(function(line) {
        return (
          <BaseComponent
            componentKey={componentKey}
            key={`${componentKey}_${text.indexOf(line)}`}
            injectedStyles={styles}
            theme={theme}
            htmlTag="p"
          >
            <Markdown source={line} theme={theme} {...props} />
          </BaseComponent>
        )
      })
    ) : (
      <BaseComponent
        theme={theme}
        componentKey={componentKey}
        injectedStyles={styles}
        htmlTag="span"
      >
        <Markdown source={text} theme={theme} {...props} />
      </BaseComponent>
    )

    return <React.Fragment>{markup}</React.Fragment>
  }
}

export default MarkDownComponent
