// @flow
import * as React from 'react'
import { stringUtils } from 'conversional-utils'

import componentMap from './map'
import ErrorBoundary from '../components/error/ErrorBoundary'
import MarkDownComponent from '../components/markDown/MarkDownComponent'
import PlainComponent from '../components/plain/PlainComponent'

import BuilderError from './BuilderError'

type Component = {
  Content: {
    componentKey: string,
    format: string,
    type: string,
    props: {},
    label: string
  },
  Children: {
    [string]: Component
  },
  Product?: Array<{}>
}

/**
 * createRecursiveComponent
 * Gets a component tree and returns react elements for all components and children by
 * iterating the tree. Components with child components can place them in themselves using
 * by selecting its name/position from the child pro, e.g. {this.props.children['nameOfMyChild']}
 *
 * @param componentName: string, title of the components position
 * @param data
 * @returns {*}
 */
const createRecursiveComponent = (componentName: string, data: Component, theme: any) => {
  if (!componentName || !data.Content || !data.Content.format || !data.Content.type) {
    // check if type is in given range
    //console.log(`Bad data for component ${componentName}`);
    return null
  }

  const useKey = data.Content.componentKey || stringUtils.randomString(12)

  let children = {}
  switch (data.Content.format) {
    case 'markdown':
      return (
        <MarkDownComponent
          key={useKey}
          componentTitle={data.Content.label}
          componentKey={useKey}
          {...data.Content.props}
          theme={theme}
          text={data.Content.props.text || ''}
        />
      )

    case 'text':
      // Todo: use plain react components to enable passing of parameters
      return (
        <PlainComponent componentKey={useKey} key={useKey}>
          {' '}
          {React.createElement('span', { ...data.Content.props }, data.Content.label)}{' '}
        </PlainComponent>
      )

    case 'html':
      var childData = {
        type: data.Content.type,
        props: data.Content.props,
        label: data.Content.label
      }

      if (data.Children) {
        const keys = Object.keys(data.Children).sort()
        keys.forEach(child => {
          children[child] = createRecursiveComponent(child, data.Children[child], theme)
        })
      }

      return <PlainComponent key={useKey} childProps={childData} spots={children} />

    case 'component':
      if (!componentMap || !componentMap[data.Content.type]) {
        // check if type is in given range
        const type = data.Content.type || 'empty'

        throw new BuilderError(
          `Component of type ${type} not found for the position ${componentName}`
        )
      }

      var NewComponent: React.ElementType = componentMap[data.Content.type]

      // Todo: assign default props valid for with/without children elements

      if (!data.Children) {
        return (
          <ErrorBoundary key={useKey}>
            <NewComponent
              key={useKey}
              componentKey={useKey}
              {...data.Content.props}
              theme={theme}
              componentTitle={data.Content.label}
              product={data.Product}
            />
          </ErrorBoundary>
        )
      }

      Object.keys(data.Children).forEach(child => {
        children[child] = createRecursiveComponent(child, data.Children[child], theme)
      })

      return (
        <ErrorBoundary key={useKey}>
          <NewComponent
            key={useKey}
            componentKey={useKey}
            {...data.Content.props}
            spots={children}
            theme={theme}
            componentTitle={data.Content.label}
            product={data.Product}
          />
        </ErrorBoundary>
      )

    default:
      return null
  }
}

export default createRecursiveComponent
