// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { CommonSelectors } from 'conversional-journey'
import InteractiveGridElementsComponent from './InteractiveGridElementsComponent'

type IProps = {
  spots: {
    [string]: ?React.Element<*>
  },
  open: (component: string, e: Event) => void,
  styles: {
    [string]: {
      string?: string | number,
      gridColumnStart: number,
      gridColumnEnd: number,
      gridRowStart: number,
      gridRowEnd: number
    }
  },
  mobileStyles: {
    [string]: {
      order: number
    }
  },
  activeOverlay: string,
  theme: {}
}

class InteractiveGridElementsContainer extends React.PureComponent<IProps> {
  render() {
    return <InteractiveGridElementsComponent {...this.props} />
  }
}

const mapStateToProps = createStructuredSelector({
  theme: CommonSelectors.selectTheme
})

export default connect(mapStateToProps)(InteractiveGridElementsContainer)
