export const GENERAL_ERROR = 'GENERAL_ERROR'
export const generalError = () => ({
  type: GENERAL_ERROR
})

export const CLEAR_STATE = 'CLEAR_STATE'
export const clearState = () => ({
  type: CLEAR_STATE
})

export const LOAD_THEME = 'LOAD_THEME'
export const loadTheme = (journeyId, theme) => ({
  type: LOAD_THEME,
  journeyId,
  theme
})

export const LOG_CTA = 'LOG_CTA'
export const logCta = (type, value) => ({
  type: LOG_CTA,
  cta: type,
  label: value || 'undefined'
})

export const TRACK = 'TRACK'
export const track = ({ type, action, category, overrideHostConfig = false }) => ({
  type: TRACK,
  payload: { type, action, category, overrideHostConfig }
})

export const SET_CONFIG = 'SET_CONFIG'
export const setConfig = config => ({
  type: SET_CONFIG,
  payload: { config }
})

export const RPC_REQUEST = 'RPC_REQUEST'
export const rpcRequest = request => ({
  type: RPC_REQUEST,
  payload: request
})

export const RPC_RESPONSE = 'RPC_RESPONSE'
export const rpcResponse = response => ({
  type: RPC_RESPONSE,
  payload: response
})
