import { StyleSheet } from 'aphrodite/no-important'

import { sizes, mediaBreakpointDown } from 'conversional-theme'

export default theme =>
  StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 350,
      alignItems: 'justify'
    },
    headline: {
      paddingBottom: theme.spaces.desktop.m,
      textAlign: 'center'
    },
    hint: {
      fontSize: theme.typo.sizes.s,
      fontStyle: 'italic',
      paddingBottom: theme.spaces.desktop.s,
      display: 'block',
      maxWidth: 214
    },
    icon: {
      marginLeft: theme.spaces.desktop.xs
    },
    button: {
      marginTop: theme.spaces.desktop.m,
      width: 214
    },
    contentBox: {
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto'
    },
    imageContainer: {
      width: 360,
      [mediaBreakpointDown(sizes.xs)]: {
        width: 100
      }
    },
    image: {
      width: '100%'
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: theme.spaces.desktop.l,
      [mediaBreakpointDown(sizes.xs)]: {
        marginLeft: theme.spaces.mobile.l
      }
    }
  })
