import * as React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import BaseComponent from '../base/BaseComponent';

type imagePropTypes = {
  src: string,
  alt: string,
  width?: string
};

// Todo: Components throws a linting error about missing ALT tag while not tag IS missing...
/**
 * ResponsiveImage
 * Purpose      Renders an image tag and props as responsive image scaling up to 100% of its height.
 * Responsive   None
 * Nestable     False
 * Base         False
 */
function ResponsiveImage(imageProps): imagePropTypes {
  const { componentTitle, styles, width, ...props } = imageProps;
  return (
    <div>
      <BaseComponent
        componentKey={componentTitle}
        htmlTag="img"
        styles={Styles(width).image}
        injectedStyles={styles}
        htmlTagProps={{
          ...props
        }}
      />
    </div>
  );
}

ResponsiveImage.defaultProps = {
  alt: ''
};

const Styles = width =>
  StyleSheet.create({
    image: {
      width: width || 'auto',
      maxWidth: '100%',
      margin: 'auto',
      height: 'auto'
    }
  });
export default ResponsiveImage;
