// @flow
import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { sizes, mediaBreakpointDown } from 'conversional-theme';
import { normalizeGridProperties } from '../../utilities/ie11';

type Props = {
  spots: {
    [string]: ?React.Element<*>
  },
  open: (component: string, e: Event) => void,
  styles: {
    [string]: {
      string?: string | number,
      gridColumnStart: number,
      gridColumnEnd: number,
      gridRowStart: number,
      gridRowEnd: number
    }
  },
  mobileStyles: {
    [string]: {
      order: number
    }
  },
  activeOverlay: string,
  theme: {}
};

function InteractiveGridElementsComponent({
  spots,
  styles,
  open,
  mobileStyles,
  activeOverlay,
  theme
}: Props) {
  const spotList = Object.keys(spots || {});
  return (
    <React.Fragment>
      {spotList.map(spot => {
        if (!spots || !spot || !spots[spot]) {
          return null;
        }

        const prefixedStyles = normalizeGridProperties(
          styles && styles[spot],
          theme.spaces.desktop.xs
        );
        let childSpot = spots[spot] && spots[spot].props.children ? spots[spot].props.children : {};

        return (
          <div
            className={css(
              dynamicStyles(prefixedStyles).element,
              responsive((mobileStyles && mobileStyles[spot]) || {}).flexItem
            )}
            key={spot}
          >
            {React.cloneElement(childSpot, {
              open: e => open(spot, e),
              activeOverlay: spot === activeOverlay
            })}
          </div>
        );
      })}
    </React.Fragment>
  );
}

const dynamicStyles = props =>
  StyleSheet.create({
    element: {
      overflow: 'hidden',
      ...props
    }
  });

const responsive = props =>
  StyleSheet.create({
    flexItem: {
      [mediaBreakpointDown(sizes.sm)]: {
        order: 'auto',
        ...props
      }
    }
  });

export default InteractiveGridElementsComponent;
