var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styleIframe } from './iframe';
import { styleParentBody } from './parent';
import * as handlers from './messageHandlers';
var actions = {
    styleIframe: styleIframe,
    styleParentBody: styleParentBody
};
export var receiveMessage = function (url) { return function (event) {
    // Todo: remove this line before going to production
    // eslint-disable-next-line
    if (event.origin === url) {
        try {
            var data = JSON.parse(event.data);
            if (data.conversional) {
                var message = data.conversional;
                var key = Object.keys(message)[0];
                var payload = message[key];
                switch (key) {
                    case 'styleIframe':
                    case 'styleParentBody':
                        {
                            actions[key](message[key], message['replace'] || false);
                        }
                        break;
                    case 'focusModal':
                        handlers.onFocusModal();
                        break;
                    case 'ready':
                        handlers.onReady();
                        break;
                    case 'hostTrackingConfig':
                        {
                            handlers.onHostTrackingConfig(payload);
                        }
                        break;
                    case 'trackingEvent':
                        {
                            handlers.onTrackingEvent(payload);
                        }
                        break;
                    case 'routeChange':
                        {
                            handlers.onRouteChange(payload);
                        }
                        break;
                    case 'storageVar':
                        {
                            handlers.onStoreVar(payload);
                        }
                        break;
                    case 'enableExitIntentMobile': {
                        handlers.onEnableExitIntentMobile();
                        break;
                    }
                    case 'enableExitIntent': {
                        handlers.onEnableExitIntent();
                        break;
                    }
                    case 'jsonrpc': {
                        handlers.onReceiveRpcResponse(message);
                        break;
                    }
                }
            }
            else {
                throw new Error("Unknown message: " + event.data);
            }
        }
        catch (error) {
            // eslint-disable-next-line
        }
    }
}; };
export var sendMessage = function (message) {
    if (!window._conversionalWidget
        || !window._conversionalWidget.iframe
        || !window._conversionalWidget.iframe.contentWindow
        || !window._conversionalWidget.iframe.contentWindow.postMessage) {
        return false;
    }
    var type = message.type, payload = __rest(message, ["type"]);
    var markedMessage = __assign(__assign({ type: type }, payload), { conversional: true });
    window._conversionalWidget.iframe.contentWindow.postMessage(JSON.stringify(markedMessage), '*');
};
export var sendTrackingEvent = function (category, action, label, hostTracking) {
    if (hostTracking === void 0) { hostTracking = false; }
    sendMessage({
        type: 'TRACK',
        payload: {
            category: category,
            action: action,
            label: label,
            hostTracking: hostTracking
        }
    });
};
export var sendLogCTA = function (cta, label) {
    sendMessage({
        type: 'LOG_CTA',
        cta: cta,
        label: label
    });
};
var lastHostInfo = '';
export var updateHostInfo = function () {
    if (window.conversionalWidget) {
        // Skip sending identical configuration
        if (lastHostInfo === JSON.stringify(window.conversionalWidget)) {
            return;
        }
        sendMessage({ host: { conversionalWidget: window.conversionalWidget } });
        lastHostInfo = JSON.stringify(window.conversionalWidget);
    }
};
export var sendAction = function (type, payload) {
    sendMessage({ type: type, payload: payload });
};
export var sendOpenJourney = function (journeyId) {
    sendMessage({ type: 'OPEN_JOURNEY', journeyId: journeyId });
};
export default sendMessage;
