// @flow
import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';

import ErrorBoxComponent from './ErrorBoxComponent';

type IProps = {
  onClose: () => void
};

class ErrorComponent extends React.PureComponent<IProps, null> {
  render() {
    return (
      <ErrorBoxComponent>
        <h1 className={css(Styles.headline)}>
          Oh nein,
          <br />
          etwas ist schief gelaufen... <br />
          Das tut uns Leid!
        </h1>
        <p className={css(Styles.text)}>
          Der Support wurde informiert und kümmert sich bereits darum. <br />
          Vielleicht versuchen Sie es später noch einmal?
        </p>

        <div>
          <button className={css(Styles.button)} onClick={this.props.onClose}>
            schließen
          </button>
        </div>
      </ErrorBoxComponent>
    );
  }
}

const Styles = StyleSheet.create({
  headline: {
    color: '#3c3c3c',
    fontWeight: '600',
    fontSize: 36
  },

  text: {
    paddingTop: '24px',
    paddingBottom: '24px',
    color: '#3c3c3c',
    fontSize: 18,
    fontWeight: 400
  },

  button: {
    backgroundColor: '#3c3c3c',
    borderColor: '#3c3c3c',
    color: '#fff',
    padding: '12px 24px',
    display: 'inline-block',
    fontWeight: 400,
    fontSize: '20px',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: '#000'
    }
  }
});

export default ErrorComponent;
