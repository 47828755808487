// @flow
import * as React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import renderObjectOfComponents from '../../utilities/components/renderObjectOfComponents';
import { sizes, mediaBreakpointDown } from 'conversional-theme';
import BaseComponent from '../base/BaseComponent';

type FlexboxProps = {
  flexDirection: string,
  spots?: Array<React.Node> | null,
  styles: {},
  componentKey: string
};

/**
 * FlexboxComponent
 * Purpose      Organize child components in row or column order
 * Responsive   None
 * Nestable     True
 * Base         False
 */
class Flexbox extends React.PureComponent<FlexboxProps, null> {
  render() {
    const { spots, flexDirection, styles, componentKey } = this.props;

    return (
      <BaseComponent
        componentkey={componentKey}
        injectedStyles={styles}
        styles={[Style({ flexDirection }).flexBox]}
      >
        {renderObjectOfComponents(spots)}
      </BaseComponent>
    );
  }
}

Flexbox.defaultProps = {
  flexDirection: 'row'
};

const Style = props =>
  StyleSheet.create({
    flexBox: {
      display: 'flex',
      textAlign: 'left',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      ...props,
      [mediaBreakpointDown(sizes.sm)]: {
        flexWrap: 'wrap'
      }
    }
  });

export default Flexbox;
