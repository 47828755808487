import * as Sentry from '@sentry/browser';
import sizeof from 'object-sizeof';

const ENV = process.env.REACT_APP_JOURNEY_ENV || 'development';

const captureException = (error, info = {}) => {
  const size = sizeof(info);
  if (size > 20 * 1000) {
    return captureException(new Error('Payload Too Large'), {
      size,
      error: {
        message: error.message,
        stack: error.stack
      }
    });
  }

  if (ENV !== 'development') {
    Sentry.withScope(scope => {
      Object.keys(info).forEach(key => {
        scope.setExtra(key, info[key]);
      });
      Sentry.captureException(error);
    });
  } else {
    // eslint-disable-next-line
    console.error(error);
    // eslint-disable-next-line
    console.warn(info);
  }
};

export default captureException;
