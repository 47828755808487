/** animate
 * Vanilla JS for jquery animate
 *
 * @param elem: element to animate
 * @param style: e.g. scrollTop
 * @param unit: string/empty
 * @param from: starting state
 * @param to: ending state
 * @param time: duration of animation
 * @param prop: boolean
 */
export function animate(elem, style, unit, from, to, time, prop) {
  if (!elem) {
    return;
  }
  var start = new Date().getTime(),
    timer = setInterval(function() {
      var step = Math.min(1, (new Date().getTime() - start) / time);
      if (prop) {
        elem[style] = from + step * (to - from) + unit;
      } else {
        elem.style[style] = from + step * (to - from) + unit;
      }
      if (step === 1) {
        clearInterval(timer);
      }
    }, 25);
  if (prop) {
    elem[style] = from + unit;
  } else {
    elem.style[style] = from + unit;
  }
}
