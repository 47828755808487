import * as React from 'react'
import { css, StyleSheet } from 'aphrodite/no-important'
import BaseComponent from '../base/BaseComponent'
import createComponentsFromObject from '../../utilities/components/createComponentsFromObject'
import { sizes, mediaBreakpointDown } from 'conversional-theme'

type ImageCardProps = {
  img: string,
  children?: Array<React.Node>,
  styles: {},
  size: string,
  theme: {}
}

export const SIZES = {
  XL: 'XL',
  L: 'L',
  M: 'M'
}

const CONTAINER_SIZES = {
  M: {
    width: 226,
    height: 172
  },
  L: {
    width: 350,
    height: 350
  },
  XL: {
    width: 420,
    height: 300
  }
}

/**
 * ImageCardComponent
 * Purpose      Wraps an IconGrid with a card/imagebox.
 * Responsive   No
 * Nestable     False
 * Base         False (Base components are only used by other components, never directly)
 * // Todo: reconsider: maybe do the nesting per configuration? Is this a base component?
 */
function ImageCardComponent({ img, children, styles, size, theme }: ImageCardProps) {
  return (
    <BaseComponent injectedStyles={styles} styles={Styles(size, theme).card} componentKey={img}>
      <div className={css(DynamicStyles(img, size).imageBox)} />

      <div className={css(Styles(size, theme).inner)}>
        {createComponentsFromObject(children, theme)}
      </div>
    </BaseComponent>
  )
}

const Styles = (size, theme) =>
  StyleSheet.create({
    card: {
      backgroundColor: theme.colors.backgroundLight,
      display: 'inline-block',
      marginRight: !size || size === SIZES.M ? theme.spaces.desktop.s : theme.spaces.desktop.mL,
      [mediaBreakpointDown(sizes.sm)]: {
        margin: theme.spaces.mobile.xs
      },
      verticalAlign: 'top'
    },
    inner: {
      maxWidth: size ? CONTAINER_SIZES[size].width : CONTAINER_SIZES.M.height,
      whiteSpace: 'normal',
      paddingTop: 10,
      [mediaBreakpointDown(sizes.sm)]: {
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 10,
        maxWidth: 300,
        minWidth: 300
      }
    }
  })

const DynamicStyles = (img, size) =>
  StyleSheet.create({
    imageBox: {
      width: size ? CONTAINER_SIZES[size].width : CONTAINER_SIZES.M.height,
      height: size ? CONTAINER_SIZES[size].height : CONTAINER_SIZES.M.height,
      backgroundImage: `url(${img})`,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      [mediaBreakpointDown(sizes.sm)]: {
        width: '100%',
        height: 200
      }
    }
  })

ImageCardComponent.defaultProps = {
  size: SIZES.M
}

export default ImageCardComponent
