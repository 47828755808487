export const sizes = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl'
};

export const boxes = {
  xs: {
    min: '0px',
    max: '500px'
  },
  sm: {
    min: '501px',
    max: '800px'
  },
  md: {
    min: '801px',
    max: '1223px'
  },
  lg: {
    min: '1224px',
    max: '1900px'
  },
  xl: {
    min: '1901px'
  }
};

/**
 * Creates media query string from certain breakpoint up
 * @param size
 * @returns {string}
 */
export const mediaBreakpointUp = function(size) {
  return `@media only screen and (min-width: ${boxes[size].min})`;
};

/**
 * Creates string from certain breakpoint down
 * @param size
 * @returns {string}
 */
export const mediaBreakpointDown = function(size) {
  const mq = boxes[size].max ? `@media only screen and (max-width: ${boxes[size].max})` : '';
  return mq;
};

export const mediaBreakpointBetween = function(lower, upper) {
  const lowerLimit = `(min-width: ${boxes[lower].min})`;
  const upperLimit = boxes[upper] ? `and (max-width: ${boxes[upper].max})` : '';
  const mq = `@media only screen and ${lowerLimit} ${upperLimit}`;
  return mq;
};

export const mediaBreakpointOnly = function(size) {
  const lowerLimit = `(min-width: ${boxes[size].min})`;
  const upperLimit = `and (max-width: ${boxes[size].max})`;
  const mq = `@media only screen and ${lowerLimit} ${upperLimit}`;
  return mq;
};

export default boxes;
