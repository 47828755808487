// @flow
import * as React from 'react'
import ImageCardComponent from './ImageCardComponent'

type IProps = {
  img: string,
  children?: Array<React.Node>,
  styles: {},
  size: string,
  theme: {}
}

class ImageCardContainer extends React.PureComponent<IProps> {
  render() {
    return <ImageCardComponent {...this.props} />
  }
}

export default ImageCardContainer
