// @flow
import React from 'react'
import { classes } from 'typestyle'

import * as styles from './PlaceSearchComponent.styles'

const PATTERN = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'+-]+$/i
const PLACEHOLDER = 'Ortsname'
const SUBMIT_LABEL = 'Studios finden'
export const INVALID_PATTERN_ERROR_MESSAGE = 'Bitte geben Sie einen Ortsnamen ein'

type IProps = {
  error?: string,
  setPlace: Function,
  place: string,
  theme: any,
  uniqueId: string,
  submitLabel?: string,
  children?: any,
  onSubmit: () => {}
}

const PlaceSearchComponent = (props: IProps) => {
  const [place, setPlaceValue] = React.useState(props.place)
  const [localError, setLocalError] = React.useState('')
  const [displayParentError, setDisplayParentError] = React.useState(true)
  const clearLocalError = () => setLocalError('')
  const clearParentError = () => setDisplayParentError(false)
  const clearErrors = () => {
    clearLocalError()
    clearParentError()
  }

  const onChange = event => {
    clearErrors()
    setPlaceValue(event.currentTarget.value)
  }

  const onSubmit = () => {
    if (PATTERN.test(place)) {
      props.setPlace(place)
    } else {
      setLocalError(INVALID_PATTERN_ERROR_MESSAGE)
    }
  }

  const onKeyDown = event => {
    if (event.keyCode === 13) {
      onSubmit()
    }
  }

  let error

  if (localError) {
    error = <div className={classes('error', styles.errorStyle(props.theme))}>{localError}</div>
  } else if (props.error && displayParentError) {
    error = <div className={classes('error', styles.errorStyle(props.theme))}>{props.error}</div>
  }

  return (
    <div className={styles.rootStyle}>
      <div>
        <input
          className={styles.inputStyle}
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder={PLACEHOLDER}
          type="text"
          value={place}
        />
        {props.children ? (
          <CustomButton onSubmit={onSubmit}>{props.children}</CustomButton>
        ) : (
          <DefaultButton onSubmit={onSubmit} submitLabel={props.submitLabel} />
        )}
      </div>
      {error}
    </div>
  )
}

type CustomButtonProps = {
  onSubmit: () => {},
  children: React.node
}

const CustomButton = ({ onSubmit, children }: CustomButtonProps) => (
  <div onClick={onSubmit}>{children}</div>
)

type DefaultButtonProps = {
  onSubmit: () => void,
  submitLabel?: string
}

const DefaultButton = ({ onSubmit, submitLabel }: DefaultButtonProps) => (
  <input
    className={styles.buttonStyle}
    onClick={onSubmit}
    type="submit"
    value={submitLabel || SUBMIT_LABEL}
  />
)

export default PlaceSearchComponent
