function parseQueryString() {
  return window.location.search
    .substr(1)
    .split('&')
    .reduce((obj, item) => {
      let parts = item.split('=')
      obj[parts[0]] = parts[1]

      return obj
    }, {})
}

function parseHref(url) {
  var params = {}
  var parser = document.createElement('a')
  parser.href = url
  var query = parser.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    params[pair[0]] = decodeURIComponent(pair[1])
  }
  return params
}

module.exports = {
  parseQueryString,
  parseHref
}
