const base = 6;
const spaces = {
  // Gaps
  desktop: {
    xs: `${base}px`,
    s: `${base * 2}px`,
    m: `${base * 3}px`, // 18px
    mL: `${base * 4}px`, // 24px
    l: `${base * 6}px`, // 36px
    xl: `${base * 8}px`, // 48px
    xxl: `${base * 10}px`, // 60px

    // Spacer
    spacer: '80px',
    sectionSpacer: '180px'
  },
  mobile: {
    xs: `${base}px`,
    s: `${base * 2}px`, // 20px
    m: `${base * 3}px`, // 24px
    mL: `${base * 4}px`, // 30px
    l: `${base * 5}px`, // 50px
    xl: `${base * 9}px`, // 80px

    spacer: `${base * 15}px`,
    sectionSpacer: `${base * 25}px`
  }
};

export default spaces;
