export const OPEN_MODAL = 'OPEN_MODAL';
export const openModal = () => ({
  type: OPEN_MODAL
});

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const closeModal = () => ({
  type: CLOSE_MODAL
});

export const SCROLL_MODAL = 'SCROLL_MODAL';
export const scrollModal = top => ({
  type: SCROLL_MODAL,
  payload: { top }
});
