// Todo: test loading remote fonts that are only available in production environment and provided seperately

const fontByHand = {
  fontFamily: 'Conversional Jrhand',
  fontStyle: 'normal',
  fontWeight: '300',
  src: "url(https://www.roomtailors.de/fonts/google/jrhand.woff) format('woff')"
};

const fontRegular = {
  fontFamily: 'Conversional Open Sans',
  fontStyle: 'normal',
  fontWeight: '300',
  src: "url(https://www.roomtailors.de/fonts/google/opensans300.woff) format('woff')"
};

const fontStronger = {
  fontFamily: 'Conversional Open Sans Regular',
  fontStyle: 'normal',
  fontWeight: '400',
  src: "url(https://www.roomtailors.de/fonts/google/opensans400.woff) format('woff')"
};

const fontBold = {
  fontFamily: 'Conversional Open Sans Semi-Bold',
  fontStyle: 'normal',
  fontWeight: '600',
  src: "url(https://www.roomtailors.de/fonts/google/opensans600.woff) format('woff')"
};

const fontExtraBold = {
  fontFamily: 'Conversional Open Sans',
  fontStyle: 'normal',
  fontWeight: '700',
  src: "url(https://www.roomtailors.de/fonts/google/opensans700.woff) format('woff')"
};

const fonts = {
  regular: [fontRegular, 'sans-serif'],
  stronger: [fontStronger, 'sans-serif'],
  bold: [fontBold, 'sans-serif'],
  extraBold: [fontExtraBold, 'sans-serif'],
  byHand: [fontByHand, 'Times New Roman']
};

const sizes = {
  xxl: '4.375rem',
  xxlMobile: '2.5rem',
  xl: '2.75rem',
  xlMobile: '2rem',
  l: '2.125rem',
  lMobile: '1.75rem',
  mL: '1.125rem',
  m: '1rem',
  mMobile: '1.25rem',
  s: '0.8rem'
};

export default {
  fonts: { ...fonts },
  sizes: { ...sizes }
};
