export const colors = {
  // General
  brand: '#c00000',
  light: '#fff',
  error: '#FF0000',
  success: '#088C00',

  // Text
  text: '#3c3c3c',
  textLight: '#bfbfbf',
  textOnBrand: '#fff',

  // Elements
  border: '#f7f7f7',

  // Backgrounds
  backgroundDark: '#363636',
  backgroundRegular: '#f2f2f2',
  backgroundLight: '#fff',
  menuElement: '#000'
};
