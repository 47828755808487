// @flow
import * as React from 'react'
import { css } from 'aphrodite/no-important'
import { connect } from 'react-redux'

import { publish } from 'conversional-callbacks'
import { TrackingActions, Events } from 'conversional-journey'
import { ComponentStyles } from 'conversional-theme'

import PreparedStyles from './Button.styles'

type BaseProps = {
  onClick: () => void,
  type?: string,
  disabled?: boolean,
  theme: any,
  buttonType: string,
  buttonSize: string,
  children: React.Node,
  customStyles: string,
  styles: {
    // Styles passed from configuration
    general?: {},
    desktop?: {},
    mobile?: {}
  },
  componentKey?: string,
  trackEvent: () => void
}

const CLICK_EVENT = 'Button.clicked'

export const TYPES = {
  PRIMARY: 'primary',
  OUTLINE: 'outline',
  STANDARD: 'standard',
  TEXT: 'text'
}

export const BaseButton = ({
  onClick,
  type,
  disabled,
  buttonType,
  buttonSize,
  children,
  customStyles,
  styles,
  componentKey,
  theme,
  trackEvent
}: BaseProps) => {
  const executeOnClick = function() {
    publish(CLICK_EVENT, {}, componentKey)
    trackEvent({ ...Events.BUTTON_CLICK, label: componentKey || 'undefined' })
    onClick()
  }

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={executeOnClick}
      className={css(
        PreparedStyles(theme).button,
        PreparedStyles(theme)[buttonSize],
        PreparedStyles(theme)[buttonType],
        customStyles,
        ComponentStyles(styles)
      )}
    >
      {children}
    </button>
  )
}

BaseButton.defaultProps = {
  buttonSize: TYPES.STANDARD,
  onClick: () => {}
}

const actions = dispatch => ({
  trackEvent: event => dispatch(TrackingActions.event(event))
})

export default connect(
  null,
  actions
)(BaseButton)
