// @flow
import * as React from 'react'
import { StyleSheet, css } from 'aphrodite/no-important'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import { ComponentStyles } from 'conversional-theme'
import { CommonSelectors } from 'conversional-journey'

import styles from './IconInput.styles'
import selectStyles from './SelectInput.styles'
import generalStyles from './General.styles'
import { ICONS } from '../../icons/IconList'
import Icon from '../../icons/Icon'

type IProps = {
  schema: {
    list: Array<any>,
    icon?: string
  },
  id: string,
  placeholder?: string,
  value?: string,
  required: boolean,
  onChange: () => {},
  theme: any,
  className?: string,
  placeholder?: string
}

/**
 * The select list input is a wrapper to enable populating select fields with simple key-value-lists
 *
 * @param props
 * @returns {*}
 * @constructor
 */
function SelectListInput(props: IProps) {
  const {
    theme,
    schema: { list, icon, placeholder }
  } = props

  const stylesForSelect = css(
    ComponentStyles(generalStyles.input),
    ComponentStyles(selectStyles.select)
  )
  const containerStyles = css(StyleSheet.create(styles).container)
  const options = Object.keys(list)

  return (
    <div key={props.id} className={containerStyles}>
      {icon && (
        <Icon
          theme={theme}
          icon={ICONS[icon]}
          color={theme.colors.brand}
          injectedStyles={styles.icon}
        />
      )}
      <select
        id={props.id}
        value={props.value}
        required={props.required}
        className={stylesForSelect}
        onChange={event => props.onChange(event.target.value)}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map(entry => {
          if (list[entry]) {
            return (
              <option key={entry} value={entry}>
                {list[entry]}
              </option>
            )
          }
        })}
      </select>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  theme: CommonSelectors.selectTheme
})

export default connect(mapStateToProps)(SelectListInput)
