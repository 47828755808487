// @flow
import * as React from 'react'
import { StyleSheet, css } from 'aphrodite/no-important'
import { connect } from 'react-redux'
import { CommonSelectors } from 'conversional-journey'
import { createStructuredSelector } from 'reselect'

import styles from './TimekitInput.styles'

import { ICONS } from '../../icons/IconList'
import Icon from '../../icons/Icon'

type IProps = {
  schema: {
    icon: string
  },
  id: string,
  placeholder?: string,
  value?: string,
  theme: any,
  required: boolean,
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  className?: string
}

function TimekitInput(props: IProps) {
  const {
    theme,
    schema: { icon }
  } = props
  return (
    <div key={props.id} className={css(StyleSheet.create(styles).container)}>
      {icon && (
        <Icon
          theme={theme}
          icon={ICONS[icon]}
          color={theme.colors.brand}
          injectedStyles={styles.icon}
        />
      )}
      {!props.value && (
        <input
          id={props.id}
          placeholder={props.placeholder}
          type="text"
          value={props.value}
          required={props.required}
          onChange={event => props.onChange(event.target.value)}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
        />
      )}
      {Boolean(props.value) && (
        <div className={css(StyleSheet.create(styles).timeslotText)}>
          <div>{props.value}</div>
          <Icon
            theme={theme}
            icon={ICONS.close}
            color={theme.colors.brand}
            injectedStyles={styles.closeIcon}
            onClick={() => props.onChange('')}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  theme: CommonSelectors.selectTheme
})

export default connect(mapStateToProps)(TimekitInput)
