export default {
  icon: {
    general: {
      width: '2.8rem',
      height: '2.8rem',
      position: 'absolute',
      left: '-3.4rem',
      top: -4
    },
    mobile: {
      display: 'none'
    }
  },
  container: {
    display: 'inline-block',
    position: 'relative'
  }
};
