// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { css, StyleSheet } from 'aphrodite/no-important'
import { createStructuredSelector } from 'reselect'
import { ResultSelectors, CommonSelectors } from 'conversional-journey'

import TagGalleryComponent from './TagGalleryComponent'
import BaseComponent from '../base/BaseComponent'

type Tag = {
  name: string,
  group: string
}

type Props = {
  styles: {},
  label: string,
  title: string,
  subtitle: string,
  product: Array<{
    Image: {
      tagCount: number,
      url: string,
      id: string
    },
    Tag: Array<Tag>
  }>,
  selectedTags: {
    [string]: Array<string>
  },
  theme: {},
  dispatch: () => {}
}

type ImageItem = {
  title: string,
  original: string,
  thumbnail: string,
  thumbnailClass: string,
  originalAlt?: string,
  srcSet?: string,
  sizes?: string,
  id: string,
  intro: boolean,
  tags: Array<Tag>
}

type State = {
  activeImage: number,
  images: Array<ImageItem>
}

/**
 * TagGalleryContainer
 * Purpose      Renders an image gallery with an overlay that contains tag descriptions.
 *              Can have a title on its frontpage.
 *              Later, tag based filters may be added.
 *              Is currently optimized for max. 5 images.
 * Responsive   Shows only images and navigation on mobile
 * Nestable     False
 * Base         False
 */
class TagGalleryContainer extends React.Component<Props, State> {
  static defaultProps = {
    product: []
  }

  constructor(props: Props) {
    super(props)
    const products = props.product && Array.isArray(props.product) ? props.product : []
    const images = products.map((image, i) => {
      let intro
      intro = i === 0 ? true : false
      return {
        title: props.title,
        subtitle: props.subtitle,
        original: image.Image.url,
        thumbnail: image.Image.url,
        thumbnailClass: css(Styles.thumbnail),
        tags: image.Tag,
        id: image.Image.id,
        intro: intro
      }
    })

    this.state = {
      activeImage: 1,
      images: images
    }
  }

  render() {
    const { styles } = this.props
    return (
      <BaseComponent injectedStyles={styles}>
        <TagGalleryComponent
          title={this.props.label}
          subtitle={this.props.subtitle}
          images={this.state.images}
          theme={this.props.theme}
          selectedTags={this.props.selectedTags}
          dispatch={this.props.dispatch}
        />
      </BaseComponent>
    )
  }
}

TagGalleryContainer.defaultProps = {
  styles: {}
}

const Styles = StyleSheet.create({
  thumbnail: {
    opacity: '0.25',
    ':hover': {
      opacity: '1 !important'
    }
  }
})

// Todo: Make round buttons their own button type
// Todo: Add headline as its own component, centered over image
// Customer: Global State
// Style: Global State

const mapStateToProps = createStructuredSelector({
  selectedTags: ResultSelectors.selectResultProfile,
  theme: CommonSelectors.selectTheme
})

export default connect(mapStateToProps)(TagGalleryContainer)
