// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { CommonSelectors } from 'conversional-journey'
import SectionComponent from './SectionComponent'

class SectionContainer extends React.PureComponent {
  render() {
    return <SectionComponent {...this.props} />
  }
}

const mapStateToProps = createStructuredSelector({
  theme: CommonSelectors.selectTheme
})

export default connect(mapStateToProps)(SectionContainer)
