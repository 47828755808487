// @flow
import { css, StyleSheet } from 'aphrodite/no-important'
import React from 'react'
import noop from 'lodash/noop'

import { stringUtils } from 'conversional-utils'
import { ComponentStyles } from 'conversional-theme'

export type IconProps = {
  path: string,
  viewbox: string
}

type Props = {
  color?: string,
  icon: IconProps,
  size: string,
  hoverColor?: string,
  multiple?: number,
  rotation: number,
  onClick?: Function,
  theme: any,
  injectedStyles: any
}

const Icon = (props: Props) => {
  // Render same item multiple times, e.g. for star ratings
  if (props.multiple) {
    let icons = []
    for (let i = 0; i < props.multiple; ++i) {
      icons.push(<Icon {...props} multiple={0} key={stringUtils.randomString(5)} />)
    }
    return <React.Fragment>{icons}</React.Fragment>
  }

  const { injectedStyles } = props
  const className = css(Styles(props.theme, props).svg, ComponentStyles(injectedStyles))

  return (
    <svg
      className={className}
      width={`${props.size}`}
      height={`${props.size}`}
      viewBox={`${props.icon.viewbox}`}
      onClick={props.onClick}
    >
      <path className={css(Styles(props.theme, props).path)} d={props.icon.path} />
    </svg>
  )
}

Icon.defaultProps = {
  size: '1rem',
  onClick: noop,
  rotation: 0
}

const Styles = (theme, props) =>
  StyleSheet.create({
    svg: {
      display: 'inline-block',
      verticalAlign: 'middle',
      transform: `rotate(${props.rotation}deg)`,
      height: `${props.size}`,
      ':hover > path': {
        fill: props.hoverColor || props.color || theme.colors.text
      }
    },
    path: {
      fill: props.color ? props.color : theme.colors.text
    }
  })

export default Icon
