export const SEND_FEEDBACK = 'SEND_FEEDBACK'
export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS'
export const SEND_FEEDBACK_FAILURE = 'SEND_FEEDBACK_FAILURE'
export const sendFeedback = (rating, feedback) => ({
  feedback,
  rating,
  type: SEND_FEEDBACK
})

export const GET_LOCATIONS = 'GET_LOCATIONS'
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS'
export const GET_LOCATIONS_FAILURE = 'GET_LOCATIONS_FAILURE'
export const getLocations = (closeTo, journeyId = null) => ({
  type: GET_LOCATIONS,
  payload: {
    closeTo,
    journeyId
  }
})

export const getLocationsSuccess = response => ({
  type: GET_LOCATIONS_SUCCESS,
  response
})
export const getLocationsFailure = error => ({
  type: GET_LOCATIONS_FAILURE,
  error
})

export const SET_LOCATIONS = 'SET_LOCATIONS'
export const setLocations = function setLocations(locationList) {
  return {
    type: SET_LOCATIONS,
    payload: locationList
  }
}

export const OPEN_RESULT = 'OPEN_RESULT'
export const openResult = () => ({
  type: OPEN_RESULT
})

export const LOAD_RESULTS = 'LOAD_RESULTS'
export const LOAD_RESULTS_SUCCEEDED = LOAD_RESULTS + '_SUCCEEDED'
export const loadResults = token => ({
  type: LOAD_RESULTS,
  token
})
export const loadResultsSucceeded = (journeyId, result) => ({
  type: LOAD_RESULTS_SUCCEEDED,
  journeyId,
  result
})

export const FILTER_LOCATIONS_BY_PLACE_SEARCH = 'FILTER_LOCATIONS_BY_PLACE_SEARCH'
export const filterLocationsByPlaceSearchAction = place => ({
  type: FILTER_LOCATIONS_BY_PLACE_SEARCH,
  payload: { place }
})

export const SEND_FORM = 'SEND_FORM'
export const sendForm = (endpoint, payload) => ({
  endpoint: endpoint,
  payload: payload,
  type: SEND_FORM
})
