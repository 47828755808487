import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { sizes, mediaBreakpointDown } from 'conversional-theme';

type IProps = {
  rows: number,
  cols: number
};

const GridOpacityBase = ({ rows, cols }: IProps) => {
  const gridCols = '1/' + (cols + 1);
  const gridRows = rows + '/' + (rows + 1);
  return <div className={css(Styles(gridRows, gridCols).container)} />;
};

const Styles = (rows, cols) =>
  StyleSheet.create({
    container: {
      zIndex: 1001,
      background:
        'linear-gradient(to bottom, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 1) 100%)',
      gridColumn: cols,
      gridRow: rows,
      marginTop: -50,
      [mediaBreakpointDown(sizes.md)]: {
        display: 'none !important'
      }
    }
  });

export default GridOpacityBase;
