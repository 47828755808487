export const normalizeGridProperties = (styles, gap) => {
  const MAX = 1000;

  if (!styles) return {};

  if (styles.gridColumn) {
    const colSplit = styles.gridColumn.split('/');
    const rowSplit = styles.gridRow.split('/');

    styles.gridColumnStart = colSplit[0];
    styles.gridColumnEnd = colSplit[1];
    styles.gridRowStart = rowSplit[0];
    styles.gridRowEnd = rowSplit[1];

    styles.MsGridColumn = colSplit[0];
    styles.MsGridColumnSpan = colSplit[1] === 'end' ? MAX : '' + (colSplit[1] - colSplit[0]);
    styles.MsGridRow = rowSplit[0];
    styles.MsGridRowSpan = rowSplit[1] === 'end' ? MAX : '' + (rowSplit[1] - rowSplit[0]);

    if (gap) {
      styles.margin = gap;
    }

    delete styles.gridColumn;
    delete styles.gridRow;
  }

  return styles;
};

export const matches = (target, selector) => {
  if (typeof target.matches === 'function') {
    return target.matches(selector);
  }
  if (typeof target.msMatchesSelector === 'function') {
    return target.msMatchesSelector(selector);
  }
  return false;
};
