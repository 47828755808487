//@flow
import * as React from 'react';
import { css } from 'aphrodite/no-important';
import posed from 'react-pose';
import { ComponentStyles } from 'conversional-theme';

// todo: fix import of aphrodite typings, module not found!

type Styles = {
  general?: {},
  desktop?: {},
  mobile?: {}
};

type BaseComponentProps = {
  children: Array<Node>,
  componentKey: string,
  styles?: {} | Array<{}>,
  injectedStyles?: Styles,
  htmlTag?: string,
  htmlTagProps?: {},
  useAnimation?: boolean
};

const selfClosingTags = ['hr', 'img', 'br'];

/**
 * BaseComponent
 * Purpose      Base component wraps all other components to provide shared functionality (tracking, responsive styling,
 *              animation). The BaseComponent can be rendered as any tag and defaults to div.
 * Nestable     True
 * Props        styles prop needs to be pre-processed with StyleSheet.create. For multiple classes pass them as array
 */
class BaseComponent extends React.PureComponent<BaseComponentProps, null> {
  static defaultProps = {
    htmlTag: 'div',
    injectedStyles: {},
    styles: null,
    componentKey: Math.random() * 10000,
    useAnimation: true
  };

  isSelfClosing = tag => {
    return selfClosingTags.includes(tag);
  };

  render() {
    const {
      htmlTag,
      styles,
      injectedStyles,
      htmlTagProps,
      componentKey,
      useAnimation
    } = this.props;

    const className = css(styles && styles, ComponentStyles(injectedStyles));
    const key = `base-${componentKey}`;
    const props = { key, ...htmlTagProps, className, componentKey };

    // SELF CLOSING COMPONENTS CANNOT BE ANIMATED AT THE MOMENT
    if (this.isSelfClosing(htmlTag)) {
      delete htmlTagProps.componentKey;
      delete props.componentKey;
      return SelfClosingComponent(htmlTag, props);
    }

    if (useAnimation) {
      return AnimatedComponent(htmlTag, props, this.props.children);
    }

    return NotAnimatedComponent(htmlTag, props, this.props.children);
  }
}

function AnimatedComponent(Tag, props, children) {
  const Wrapped = posed[Tag]({
    open: {
      y: 0,
      opacity: 1,
      staggerChildren: 100
    },
    initialPose: 'closed',
    closed: {
      y: -80,
      opacity: 0
    }
  });

  return <Wrapped {...props}>{children}</Wrapped>;
}

function NotAnimatedComponent(Tag, props, children) {
  return <Tag {...props}>{children}</Tag>;
}

function SelfClosingComponent(Tag, props) {
  return <Tag {...props} />;
}

export default BaseComponent;
