// @flow
import React from 'react';

type IProps = {
  color?: string,
  width?: string,
  height?: string
};

class EnvelopeIcon extends React.PureComponent<IProps, null> {
  render() {
    const color = this.props.color ? this.props.color : '#000';
    const width = this.props.width ? this.props.width : '35px';
    const height = this.props.height ? this.props.height : '35px';

    return (
      <svg
        x="0px"
        y="0px"
        viewBox="0 0 57 50"
        style={{
          enableBackground: 'new 0 0 57 50',
          position: 'absolute',
          top: '-4px',
          left: '-43px'
        }}
        height={height}
        width={width}
      >
        <polygon
          style={{ fill: color }}
          points="2.4,10.3 3.8,8.6 29,28.6 54.2,8.6 55.6,10.3 29,31.5 "
        />
        <path
          style={{ fill: color }}
          d="M4.3,39.7h49.5V10.5H4.3V39.7z M54.9,40.8v-1.1V40.8z M2.1,41.9V8.3H56v32.5v1.1H2.1z"
        />
        <rect
          x="44.6"
          y="20"
          transform="matrix(0.6718 -0.7407 0.7407 0.6718 -9.0492 44.5231)"
          style={{ fill: color }}
          width="2.2"
          height="24.9"
        />
        <rect
          x="-0.1"
          y="31.4"
          transform="matrix(0.7405 -0.672 0.672 0.7405 -18.6915 16.6843)"
          style={{ fill: color }}
          width="24.6"
          height="2.2"
        />
      </svg>
    );
  }
}

export default EnvelopeIcon;
