//@flow
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';

type IProps = {
  children?: Array<React.Node>,
  theme: {}
};

class ErrorComponent extends React.PureComponent<IProps, null> {
  render() {
    return <p className={css(Styles(this.props.theme).error)}>{this.props.children}</p>;
  }
}

const Styles = theme =>
  StyleSheet.create({
    error: {
      color: theme.colors.error
    }
  });

export default ErrorComponent;
