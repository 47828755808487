// @flow
import * as React from 'react';
import BaseComponent from '../base/BaseComponent';

type ChildProps = {
  type: string,
  props: {
    style?: {
      general?: {},
      desktop?: {},
      mobile?: {}
    }
  },
  label: string,
  spots: {
    [string]: ?React.Element<*>
  }
};

type Props = {
  childProps: ChildProps,
  componentKey: string,
  spots: {
    [string]: ?React.Element<*>
  }
};

type SubChildren = any | string; // Todo: specify type checking

/**
 * PlainComponent
 * Purpose      Render any HTML tag with its props and styling. The label will be the first child.

 * @param style
 * @param styleDesktop
 * @param styleMobile
 * @param childProps
 * @param label
 * @param type
 * @returns <React.Node>
 */
class PlainComponent extends React.PureComponent<Props, void> {
  static defaultProps: {
    childProps: {
      styles: {},
      props: {}
    }
  };

  render() {
    const { childProps, spots, componentKey } = this.props;

    // Setting default nested props
    if (!childProps.props) {
      childProps.props = {};
    }

    // htmlTagProps contain only props the HTML tag will understand
    const { styles, ...htmlTagProps } = childProps.props;
    const injectedStyles = styles || {};
    const children: Array<SubChildren> = [childProps.label, Object.values(spots)];

    // create child from label
    return (
      <BaseComponent
        componentKey={`base_${componentKey}`}
        htmlTag={childProps.type}
        injectedStyles={injectedStyles}
        htmlTagProps={htmlTagProps}
      >
        {children}
      </BaseComponent>
    );
  }
}

export default PlainComponent;
