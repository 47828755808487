// @flow
import * as React from 'react'
import BaseButton, { TYPES } from './BaseButton'

type ButtonProps = {
  onClick?: () => void,
  type?: string,
  disabled?: boolean,
  buttonSize?: string,
  children: React.Node
}

export const SIZES = {
  L: 'l',
  M: 'm'
}

export const Primary = (props: ButtonProps) => (
  <BaseButton {...props} buttonType={TYPES.PRIMARY}>
    {props.children}
  </BaseButton>
)

export const Outline = (props: ButtonProps) => (
  <BaseButton {...props} buttonType={TYPES.OUTLINE}>
    {props.children}
  </BaseButton>
)

export default Primary
