import { publish } from './index'

const urlEventsHandler = () => {
  const params = window.conversional.urlParams
  const events = []

  // eslint-disable-next-line no-unused-vars
  for (const param in params) {
    if (/^conversional\.event\./.test(param)) {
      events.push({ name: param.replace(/^conversional\.event\./, '') })
    }
    if (/^conversional_event_/.test(param)) {
      const eventName = transformUnderscoreToDot(param)
      events.push({ name: eventName })
    }
  }
  // eslint-disable-next-line no-unused-vars
  for (const event of events) {
    publish(event.name, {})
  }
}

/**
 * Replaces _ with . because Wordpress is rewriting dot parameters to underscore parameters
 * @param string url parameter
 */
const transformUnderscoreToDot = param => {
  return param.replace(/^conversional_event_/, '').replace(/_/, '.')
}

export default urlEventsHandler
