import * as JourneyActions from '../actions'
import * as CommonActions from '../../common/actions'
import * as QuestionnaireActions from '../../questionnaire/actions'
import * as ResultActions from '../../result/actions'
import * as JourneyConstants from '../constants'

export const defaultJourneyValue = {
  config: {
    callToAction: {
      exitIntentMobile: {}
    }
  }
}

// TODO: Add remaining attributes required for initial page load.
export const initialState = {
  selectedJourney: JourneyConstants.DEFAULT_JOURNEY_ID,
  // keeping this just for fallback
  [JourneyConstants.DEFAULT_JOURNEY_ID]: defaultJourneyValue
}

export const mergeJourneyData = (state = {}, data) => ({
  ...state,
  [state.selectedJourney]: {
    ...(state[state.selectedJourney] || defaultJourneyValue),
    ...data
  }
})

export function journey(state = initialState, action) {
  switch (action.type) {
    case CommonActions.SET_CONFIG:
      if (action.payload.config.questionnaireId) {
        const newState = { ...state }
        newState.selectedJourney = action.payload.config.questionnaireId
        if (!newState[newState.selectedJourney]) {
          newState[newState.selectedJourney] = defaultJourneyValue
        }

        return newState
      } else {
        return state
      }

    case JourneyActions.LOAD_JOURNEY:
      return mergeJourneyData(state, {
        loading: true
      })

    case JourneyActions.LOAD_JOURNEY_SUCCEEDED:
      return mergeJourneyData(state, {
        config: action.config,
        loading: false
      })

    case QuestionnaireActions.LOAD_QUESTIONNAIRE:
      return mergeJourneyData(state, {
        loadingQuestionnaire: true,
        questionnaire: undefined
      })

    case QuestionnaireActions.LOAD_QUESTIONNAIRE_SUCCEEDED:
      return mergeJourneyData(state, {
        loadingQuestionnaire: false,
        questionnaire: action.questionnaire
      })

    case ResultActions.LOAD_RESULTS:
      return mergeJourneyData(state, {
        loading: true,
        result: undefined
      })

    case ResultActions.LOAD_RESULTS_SUCCEEDED:
      return mergeJourneyData(state, {
        loading: false,
        result: action.result
      })

    case CommonActions.GENERAL_ERROR:
      return { ...state, error: true, loading: false, loadingQuestionnaire: false }

    case JourneyActions.SELECT_JOURNEY:
      return { ...state, selectedJourney: action.payload }

    default:
      return state
  }
}

export function journeyLoaded(state = {}, action) {
  if (action.type === JourneyActions.LOAD_JOURNEY_SUCCEEDED) {
    return {
      ...state,
      [action.journeyId]: true
    }
  }
  return state
}
