import { StyleSheet } from 'aphrodite/no-important'

export default (theme, image) =>
  StyleSheet.create({
    rtIntroGrid: {
      overflow: 'hidden',
      color: '#353535',
      alignItems: 'center',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundImage: `url('${image}')`,
      display: 'grid;display: -ms-grid;',
      gridTemplateColumns: '1fr minmax(300px, 400px) 1fr',
      ['-ms-grid-columns']: '1fr minmax(300px, 500px) 1fr',
      gridTemplateRows: '20px auto 20px'
    },
    rtIntroContent: {
      '-ms-grid-column': 2,
      color: '#353535',
      gridColumn: '2 / span 1',
      gridRow: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      zIndex: 2,
      position: 'relative'
    },
    img: {
      paddingBottom: '1.7rem',
      maxWidth: 250
    },
    rtIntroBackground: {
      '-ms-grid-column': 2,
      gridColumn: '2 / span 1',
      gridRow: 2,
      zIndex: 1,
      height: '100%',
      background: '#FFF' /* For browsers that do not support gradients */,
      opacity: 0.85,
      filter: 'alpha(opacity=85),'
    },
    rtSmall: {
      fontSize: '10px',
      paddingTop: '2rem',
      paddingBottom: '0.5rem'
    },
    rtText: {
      paddingBottom: theme.spaces.desktop.m
    }
  })
