// @flow
import * as React from 'react'
import { css, StyleSheet } from 'aphrodite/no-important'
import TagGalleryOverlayComponent from './TagGalleryOverlayComponent'
import { sizes, mediaBreakpointUp, mediaBreakpointDown } from 'conversional-theme'

import Icon from '../icons/Icon'
import { ICONS } from '../icons/IconList'

type ImageItem = any

class ImageGalleryRenderer extends React.PureComponent<ImageItem, null> {
  render() {
    const { item, theme } = this.props

    return (
      <div>
        {item.intro && item.title && (
          <h1 className={css(Styles(theme).title)}>
            {item.title}
            <div className={css(Styles(theme).underline)}>
              <Icon theme={theme} icon={ICONS.line} color={theme.colors.brand} size={'400px'} />
            </div>
          </h1>
        )}
        <img
          src={item.original}
          alt={item.originalAlt || ''}
          srcSet={item.srcSet}
          sizes={item.sizes}
        />
        <TagGalleryOverlayComponent
          theme={theme}
          title={item.subtitle}
          tags={item.tags}
          id={item.id}
          selectedTags={this.props.selectedTags}
        />
      </div>
    )
  }
}

const Styles = theme =>
  StyleSheet.create({
    title: {
      color: theme.colors.brand,
      textAlign: 'center',
      maxWidth: '450px',
      margin: 'auto',
      whiteSpace: 'normal',
      fontFamily: theme.typo.fonts.byHand,
      [mediaBreakpointDown(sizes.md)]: {
        lineHeight: theme.typo.sizes.xl,
        padding: `${theme.spaces.mobile.xs} 0`
      },
      [mediaBreakpointUp(sizes.md)]: {
        lineHeight: theme.typo.sizes.xxl,
        position: 'absolute',
        zIndex: 2,
        left: '50%',
        top: '36%',
        transform: 'translate3d(-50%,-50%,0) rotate(3deg)'
      }
    },
    form: {
      textTransform: 'capitalize'
    },
    underline: {
      marginTop: '-180px',
      position: 'absolute',
      [mediaBreakpointDown(sizes.sm)]: {
        display: 'none'
      }
    }
  })

export default ImageGalleryRenderer
