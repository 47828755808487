export const DEFAULT_JOURNEY = 'default'
export const CURRENT_JOURNEY = 'current'

// Dynamic values are supposed to be replaced by the event payload
const DYNAMIC = 'dynamic'

// CALL TO ACTIONS
export const EXITINTENTMOBILE_CLOSE = {
  category: 'exitIntentMobile',
  action: 'click',
  label: 'close',
  hostTracking: true,
  journeyId: DEFAULT_JOURNEY
}
export const EXITINTENTMOBILE_OPEN = {
  category: 'exitIntentMobile',
  action: 'click',
  label: 'open',
  hostTracking: true,
  journeyId: DEFAULT_JOURNEY
}

export const EXITINTENT_OPEN = {
  category: 'exitIntent',
  action: 'click',
  label: 'open',
  hostTracking: true,
  journeyId: DEFAULT_JOURNEY
}
export const EXITINTENT_CLOSE = {
  category: 'exitIntent',
  action: 'click',
  label: 'close',
  hostTracking: true,
  journeyId: DEFAULT_JOURNEY
}
export const EXITINTENT_SHOW = {
  category: 'exitIntent',
  action: 'show',
  label: 'undefined',
  hostTracking: true,
  journeyId: DEFAULT_JOURNEY
}

export const LAUNCHER_CLICK = {
  category: 'Launcher',
  action: 'click',
  label: 'undefined',
  hostTracking: false,
  journeyId: DEFAULT_JOURNEY
}

export const LAUNCHER_CLOSE = {
  category: 'Launcher',
  action: 'close',
  label: 'undefined',
  hostTracking: false,
  journeyId: DEFAULT_JOURNEY
}

export const LAUNCHER_OPEN = {
  category: 'Launcher',
  action: 'open',
  label: 'undefined',
  hostTracking: false,
  journeyId: DEFAULT_JOURNEY
}

export const CONVERTINGCTA = {
  category: 'convertingCallToAction',
  action: DYNAMIC,
  label: DYNAMIC,
  hostTracking: false,
  journeyId: DEFAULT_JOURNEY
}

// CLOSURE FORM
export const CLOSUREFORM_SUBMITTED = {
  category: 'closureForm',
  action: 'submitted',
  label: DYNAMIC,
  hostTracking: false,
  journeyId: CURRENT_JOURNEY
}

export const PLACESEARCH_SUBMITTED = {
  category: 'placeSearch',
  action: 'submitted',
  label: DYNAMIC,
  hostTracking: false,
  journeyId: CURRENT_JOURNEY
}

export const PLACESEARCH_SHOWN = {
  category: 'placeSearch',
  action: 'shown',
  label: 'undefined',
  hostTracking: false,
  journeyId: CURRENT_JOURNEY
}

// Questionnaire
export const QUESTIONNAIRE_PROGRESS = {
  category: 'questionnaire',
  action: 'progress',
  label: DYNAMIC,
  hostTracking: false,
  journeyId: CURRENT_JOURNEY
}
export const QUESTIONNAIRE_STARTED = {
  category: 'questionnaire',
  action: 'started',
  label: 'undefined',
  hostTracking: true,
  journeyId: CURRENT_JOURNEY
}
export const ANSWER_CLICKED = {
  category: 'Question',
  action: 'answer clicked',
  label: 'undefined',
  hostTracking: true,
  journeyId: CURRENT_JOURNEY
}

// Modal
export const JOURNEY_CLOSE = {
  category: 'Journey',
  action: 'click',
  label: 'close',
  hostTracking: true,
  journeyId: CURRENT_JOURNEY
}
export const JOURNEY_RESTART = {
  category: 'Journey',
  action: 'click',
  label: 'restart',
  hostTracking: false,
  journeyId: CURRENT_JOURNEY
}

// COMPONENTS
export const APPOINTMENTMAKER_CONFIRMATION = {
  category: 'appointment-confirmation',
  action: 'viewed',
  label: DYNAMIC,
  hostTracking: false,
  journeyId: CURRENT_JOURNEY
}
export const APPOINTMENTMAKER_SUBMIT = {
  category: 'appointment',
  action: 'submitted',
  label: DYNAMIC,
  hostTracking: true,
  journeyId: CURRENT_JOURNEY
}
export const APPOINTMENTMAKER_LOCATION = {
  category: 'appointment-location',
  action: 'selected',
  label: DYNAMIC,
  hostTracking: false,
  journeyId: CURRENT_JOURNEY
}
export const CALLTOACTION_CLICK = {
  category: 'callToAction',
  action: 'clicked',
  label: DYNAMIC,
  hostTracking: true,
  journeyId: CURRENT_JOURNEY
}
export const COLLAPSIBLE_TOGGLED = {
  category: 'collapsible',
  action: 'toggled',
  label: DYNAMIC,
  hostTracking: false,
  journeyId: CURRENT_JOURNEY
}
export const FORM_SUBMIT = {
  category: 'form',
  action: 'submitted',
  label: DYNAMIC,
  hostTracking: true,
  journeyId: CURRENT_JOURNEY
}
export const INTERACTIVEGRID_CLOSE = {
  category: 'interactiveGrid',
  action: 'close component',
  label: DYNAMIC,
  hostTracking: false,
  journeyId: CURRENT_JOURNEY
}
export const INTERACTIVEGRID_OPEN = {
  category: 'interactiveGrid',
  action: 'open component',
  label: DYNAMIC,
  hostTracking: false,
  journeyId: CURRENT_JOURNEY
}
export const INTERACTIVEGRID_ACTIVE = {
  category: 'interactiveGrid',
  action: 'action: closed component',
  label: DYNAMIC,
  hostTracking: false,
  journeyId: CURRENT_JOURNEY
}
export const LINK_CLICK = {
  category: 'Link',
  action: 'click',
  label: DYNAMIC,
  hostTracking: true,
  journeyId: CURRENT_JOURNEY
}
export const MODALBUTTON_OPEN = {
  category: 'modal',
  action: 'opened',
  label: DYNAMIC,
  hostTracking: true,
  journeyId: CURRENT_JOURNEY
}
export const MODALBUTTON_CLOSE = {
  category: 'modal',
  action: 'closed',
  label: DYNAMIC,
  hostTracking: true,
  journeyId: CURRENT_JOURNEY
}
export const SECTION_VIEW = {
  category: DYNAMIC,
  action: 'viewed',
  label: DYNAMIC,
  hostTracking: false,
  journeyId: CURRENT_JOURNEY
}
export const TAGGALLERY_LEFT = {
  category: 'tagGallery',
  action: 'click',
  label: 'left',
  hostTracking: true,
  journeyId: CURRENT_JOURNEY
}
export const TAGGALLERY_RIGHT = {
  category: 'tagGallery',
  action: 'click',
  label: 'right',
  hostTracking: true,
  journeyId: CURRENT_JOURNEY
}
export const FEEDBACK_SHOW = {
  category: 'feedback',
  action: 'show',
  label: 'undefined',
  hostTracking: false,
  journeyId: CURRENT_JOURNEY
}
export const FEEDBACK_CLOSE = {
  category: 'feedback',
  action: 'close',
  label: 'undefined',
  hostTracking: false,
  journeyId: CURRENT_JOURNEY
}
export const FEEDBACK_SUBMITTED = {
  category: 'feedback',
  action: 'submitted',
  label: DYNAMIC,
  hostTracking: false,
  journeyId: CURRENT_JOURNEY
}

export const BUTTON_CLICK = {
  category: 'Button',
  action: 'click',
  label: DYNAMIC,
  hostTracking: false,
  journeyID: CURRENT_JOURNEY
}
