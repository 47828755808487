import { StyleSheet } from 'aphrodite/no-important'
import { text, areas } from '../classes/classes'

const globalSelectorHandler = (selector, _, generateSubtreeStyles) => {
  if (selector[0] !== '*') {
    return null
  }
  return generateSubtreeStyles(selector.slice(1))
}
const globalExtension = { selectorHandler: globalSelectorHandler }
const extended = StyleSheet.extend([globalExtension])

const globalStyles = theme =>
  extended.StyleSheet.create({
    globals: {
      // Headlines
      '*.conversional-journey-globals h1': {
        ...text(theme).h1
      },
      '*.conversional-journey-globals h2': {
        ...text(theme).h2
      },
      '*.conversional-journey-globals h3': {
        ...text(theme).h3
      },
      '*.conversional-journey-globals h5': {
        ...text(theme).h5
      },
      '*.conversional-journey-globals h6': {
        ...text(theme).h6
      },
      // Images
      '*.conversional-journey-globals img': {
        maxWidth: '100%',
        height: 'auto'
      },
      // Text
      '*.conversional-journey-globals': {
        ...text(theme).standard
      },
      '*.conversional-journey-globals a': {
        fontSize: 'unset',
        ...text(theme).link
      },
      // Section
      '*.conversional-journey-globals section': {
        ...areas(theme).section
      }
    }
  })

function insertGlobalStyles(theme) {
  extended.css(globalStyles(theme).globals)
}

export default insertGlobalStyles
