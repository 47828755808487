import React from 'react';
import EnvelopeIcon from './EnvelopeIcon';
import PersonIcon from './PersonIcon';
import TickIcon from './TickIcon';
import ArrowRightIcon from './ArrowRightIcon';

type IProps = {
  name: string
};

const IconComponent = (props: IProps) => {
  switch (props.name) {
    case 'arrow-right':
      return <ArrowRightIcon {...props} />;
    case 'envelope':
      return <EnvelopeIcon {...props} />;
    case 'person':
      return <PersonIcon {...props} />;
    case 'tick':
      return <TickIcon {...props} />;
  }
  return null;
};

export default IconComponent;
