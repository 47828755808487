import { createSelector } from 'reselect'
import { initialState } from '../reducers'
import * as JourneySelectors from '../../journey/selectors'

export const selectQuestionnaire = state => state.questionnaire || initialState

export const selectClosureState = createSelector(
  selectQuestionnaire,
  JourneySelectors.selectSelectedJourneyId,
  (questionnaire, selectedJourney) => questionnaire[selectedJourney] || {}
)
