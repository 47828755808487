// @flow
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { sizes, mediaBreakpointDown } from 'conversional-theme';

type Tag = {
  group: string,
  name: string
};
type TagOverlayProps = {
  tags: Array<Tag>,
  id: string,
  title: string,
  selectedTags: {
    [string]: Array<string>
  },
  theme: {}
};

class TagGalleryOverlayComponent extends React.PureComponent<TagOverlayProps, null> {
  isInSelectedTags = (tag: Tag): boolean => {
    return (
      this.props.selectedTags &&
      this.props.selectedTags[tag.group] &&
      this.props.selectedTags[tag.group].indexOf(tag.name) !== -1
    );
  };

  render() {
    const { id, title, tags, theme } = this.props;
    return (
      <div className={css(Styles(theme).overlay)}>
        <p className={css(Styles(theme).headline)}>{title}</p>
        {tags.map(tag => {
          return (
            <p
              key={`${id}_${tag.group}_${tag.name}`}
              className={css(
                Styles(theme).text,
                !this.isInSelectedTags(tag) && Styles(theme).unselected
              )}
            >
              {tag.group}: {tag.name}
            </p>
          );
        })}
      </div>
    );
  }
}

const Styles = theme =>
  StyleSheet.create({
    overlay: {
      [mediaBreakpointDown(sizes.sm)]: {
        display: 'none'
      },
      position: 'absolute',
      backgroundColor: 'rgba(255,255,255, 0.8)',
      bottom: theme.spaces.desktop.s,
      left: theme.spaces.desktop.s,
      padding: theme.spaces.desktop.s,
      textAlign: 'left',
      textTransform: 'capitalize',
      display: 'flex',
      flexDirection: 'column'
    },
    headline: {
      marginTop: `${theme.spaces.desktop.s}`,
      marginBottom: `${theme.spaces.desktop.s}`,
      fontFamily: theme.typo.fonts.byHand,
      lineHeight: '16px',
      fontSize: '28px'
    },
    text: {
      lineHeight: '1.5rem',
      padding: 0,
      margin: 0
    },
    unselected: {
      color: theme.colors.textLight,
      order: 1 // makes sure unselected items are listed last
    }
  });

export default TagGalleryOverlayComponent;
