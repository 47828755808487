export default {
  input: {
    general: {
      border: 'none',
      borderBottom: '1px solid #eee',
      textAlign: 'center',
      width: 350,
      backgroundColor: '#FFF',
      minHeight: 25,
      fontSize: '1rem',
      fontWeight: 100,
      outline: 'none',
      maxWidth: '100%',
      minWidth: 150,
      padding: 6
    }
  }
};
