// @flow
import * as React from 'react'
import { css, StyleSheet } from 'aphrodite/no-important'
import { sizes, mediaBreakpointDown, mediaBreakpointUp } from 'conversional-theme'
import { TrackingActions, Events } from 'conversional-journey'
import { connect } from 'react-redux'

import { Primary, Outline } from '../buttons/Buttons'
import ImageBoxBase from '../imageBox/ImageBoxBase'
import BaseComponent from '../base/BaseComponent'
import Icon from '../icons/Icon'
import { ICONS } from '../icons/IconList'
import renderObjectOfComponents from '../../utilities/components/renderObjectOfComponents'

type Props = {
  buttonType: 'primary' | 'outline',
  url: string,
  linkText: string,
  title: string,
  img: string,
  spots?: Array<React.Node> | null,
  styles?: {
    desktop?: {},
    mobile?: {},
    general?: {}
  },
  theme: any,
  dispatch: () => {}
}

const Button = function(buttonType, children, restProps) {
  if (buttonType === 'outline') {
    return <Outline {...restProps}>{children}</Outline>
  } else {
    return <Primary {...restProps}>{children}</Primary>
  }
}

/**
 * CallToAction
 * Purpose      This component provides a stylable element that is mainly a button with some context.
 * Responsive   Collapses to a single button.
 * Nestable     True
 * Base         False (Base components are only used by other components, never directly)
 */

// Todo: button hover color for icon buttons! => new type of button

class CallToActionComponent extends React.PureComponent<Props, null> {
  onClick = () => {
    this.props.dispatch(
      TrackingActions.event({
        ...Events.CALLTOACTION_CLICK,
        label: this.props.url || this.props.title || 'undefined'
      })
    )
  }

  render() {
    const { title, url, linkText, styles, img, buttonType, spots, theme } = this.props

    return (
      <BaseComponent injectedStyles={styles} styles={staticStyles(theme).wrapper}>
        <ImageBoxBase img={img}>
          <div className={css(staticStyles(theme).innerBox)}>
            <h3 className={css(staticStyles(theme).headline)}>{title}</h3>
            <div className={css(staticStyles(theme).cta)}>
              {url && (
                <a href={url} target="_blank" rel="noopener noreferrer" onClick={this.onClick}>
                  {Button(
                    buttonType,
                    <React.Fragment>
                      {linkText}
                      <span className={css(staticStyles(theme).icon)}>
                        <Icon theme={theme} icon={ICONS.chevron} color={theme.colors.textOnBrand} />
                      </span>
                    </React.Fragment>,
                    { theme }
                  )}
                </a>
              )}
              {renderObjectOfComponents(spots)}
            </div>
          </div>
        </ImageBoxBase>
      </BaseComponent>
    )
  }
}

CallToActionComponent.defaultProps = {
  buttonType: 'primary'
}

const staticStyles = theme =>
  StyleSheet.create({
    teaser: {
      textDecoration: 'underline',
      cursor: 'pointer',
      fontStyle: 'italic'
    },
    headline: {
      [mediaBreakpointDown(sizes.sm)]: {
        display: 'none !important'
      },
      textTransform: 'uppercase',
      marginBottom: 0,
      wordBreak: 'break-word'
    },
    cta: {
      [mediaBreakpointUp(sizes.md)]: {
        margin: '18px 12px'
      },
      [mediaBreakpointDown(sizes.sm)]: {
        margin: 'auto',
        marginTop: '12px',
        marginBottom: '12px',
        width: '100%'
      }
    },
    icon: {
      marginLeft: theme.spaces.desktop.xs
    },
    wrapper: {
      height: '100%'
    },
    innerBox: {
      [mediaBreakpointUp(sizes.md)]: {
        margin: `${theme.spaces.desktop.s}`
      },
      [mediaBreakpointDown(sizes.sm)]: {
        margin: 'auto',
        paddingLeft: `${theme.spaces.desktop.s}`,
        paddingRight: `${theme.spaces.desktop.s}`,
        width: '100%'
      }
    }
  })

export default connect(null)(CallToActionComponent)
