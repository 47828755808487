import { mediaBreakpointUp, sizes } from 'conversional-theme'
import { StyleSheet } from 'aphrodite/no-important'

const Styles = theme =>
  StyleSheet.create({
    button: {
      border: 'none',
      cursor: 'pointer',
      fontSize: theme.typo.sizes.m,
      fontFamily: theme.typo.fonts.stronger,
      padding: '11px 17px',
      textAlign: 'center',
      textDecoration: 'none !important',
      transition: 'all 500ms ease-out',
      ':hover': {
        transition: '200ms',
        filter: 'brightness(120%)'
      },
      ':active': {
        filter: 'brightness(100%)'
      },
      ':focus': {
        outline: 'none'
      },
      ...theme.borders,
      [mediaBreakpointUp(sizes.md)]: {
        maxWidth: '300px'
      },
      width: '100%',
      ':hover *': {
        fill: theme.colors.textOnBrand
      }
    },
    standard: {
      borderRadius: theme.borders.borderRadius,
      ...theme.elements.buttons.standard
    },
    primary: {
      backgroundColor: theme.colors.brand,
      borderColor: theme.colors.brand,
      color: theme.colors.textOnBrand,
      ...theme.elements.buttons.primary
    },
    outline: {
      backgroundColor: 'transparent',
      borderColor: theme.colors.light,
      color: theme.colors.light,
      ':hover': {
        color: theme.colors.brand,
        borderColor: theme.colors.light,
        backgroundColor: theme.colors.light,
        filter: 'brightness(100%)'
      },
      ...theme.elements.buttons.outline
    },
    text: {
      backgroundColor: 'transparent',
      border: 'none',
      textDecoration: 'underline',
      color: theme.colors.text,
      ':hover': {
        color: theme.colors.brand
      }
    },
    l: {
      fontSize: theme.typo.sizes.l
    }
  })

export default Styles
