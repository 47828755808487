// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'

import makeStyles from './LoadingScreenComponent.styles'
const headlineClass = css(makeStyles().headline)
const textClass = css(makeStyles().text)
const containerClass = css(makeStyles().container)

type IProps = {
  loadingImage?: string
}

const LoadingScreenComponent = ({ loadingImage }: IProps) => {
  return (
    <div className={containerClass}>
      <h5 className={headlineClass}>Bitte haben Sie einen Augenblick Geduld!</h5>
      <p className={textClass}>
        Unser System sucht jetzt die passenden Küchen-Fachhändlern für Ihre Anfrage heraus.
      </p>
      {loadingImage && <img src={loadingImage} />}
    </div>
  )
}

export default LoadingScreenComponent
