export const OPEN_EXIT_INTENT = 'OPEN_EXIT_INTENT';
export const openExitIntent = () => ({
  type: OPEN_EXIT_INTENT,
  payload: {}
});

export const CLOSE_EXIT_INTENT = 'CLOSE_EXIT_INTENT';
export const closeExitIntent = () => ({
  type: CLOSE_EXIT_INTENT,
  payload: {}
});

export const OPEN_EXIT_INTENT_MOBILE = 'OPEN_EXIT_INTENT_MOBILE';
export const openExitIntentMobile = () => ({
  type: OPEN_EXIT_INTENT_MOBILE,
  payload: {}
});

export const CLOSE_EXIT_INTENT_MOBILE = 'CLOSE_EXIT_INTENT_MOBILE';
export const closeExitIntentMobile = () => ({
  type: CLOSE_EXIT_INTENT_MOBILE,
  payload: {}
});
