// flow
import React from 'react'
import { connect } from 'react-redux'

import { ResultActions, TrackingActions, Events } from 'conversional-journey'

import renderObjectOfComponents from '../../utilities/components/renderObjectOfComponents'
import PlaceSearchWrapper from './PlaceSearchWrapper'
import TransitionContainer from './TransitionContainer'
import BaseComponent from '../base/BaseComponent'

const NOT_LOADED = 'IDLE'
const LOADED = 'DONE'
const FAILED = 'FAILED'

type IProps = {
  placeSearchProps: any,
  styles: any,
  theme: any,
  image?: string,
  loadingImage?: string,
  joureyid?: number
}
/**
 * This component
 * - renders place search
 * - shows intermittent loading screen
 * - renders children after loading is done
 */
const PreloadLocationsContainer = (props: IProps) => {
  const { placeSearchProps, styles, theme, image, loadingImage } = props
  const [firstView, setFirstView] = React.useState(true)

  // Preload images
  React.useEffect(() => {
    props.trackEvent({ ...Events.PLACESEARCH_SHOWN })
    preloadImage(image)
    preloadImage(loadingImage)
  }, [])

  const preloadImage = url => {
    if (url) {
      const img = new Image()
      img.src = url
    }
  }

  const setPlace = closeTo => {
    setFirstView(false)
    props.trackEvent({ ...Events.PLACESEARCH_SUBMITTED, label: `Location: ${closeTo}` })
    props.getLocations(closeTo, props.journeyId || null)
  }

  const doneLoading = () => {
    return Boolean(props.getLocationsStatus === LOADED)
  }

  if (
    props.getLocationsStatus === undefined ||
    props.getLocationsStatus === NOT_LOADED ||
    props.getLocationsStatus === FAILED ||
    firstView === true
  ) {
    return (
      <BaseComponent injectedStyles={styles}>
        <PlaceSearchWrapper image={image} theme={theme} setPlace={setPlace} {...placeSearchProps} />
      </BaseComponent>
    )
  }

  const children = renderObjectOfComponents(props.spots)

  return (
    <BaseComponent injectedStyles={styles}>
      <TransitionContainer loadingImage={loadingImage} theme={theme} doneLoading={doneLoading()}>
        {children}
      </TransitionContainer>
    </BaseComponent>
  )
}

const connector = state => ({
  getLocationsStatus: state.getLocationsStatus,
  locations: state.locations
})

const mapDispatchToProps = dispatch => ({
  getLocations: (closeTo, journeyId) => dispatch(ResultActions.getLocations(closeTo, journeyId)),
  trackEvent: event => dispatch(TrackingActions.event(event))
})

export default connect(
  connector,
  mapDispatchToProps
)(PreloadLocationsContainer)
