import { onLeaveSite } from 'conversional-exit-intent';
import { sendAction } from "./messages";
export var enableExitIntent = function () {
    onLeaveSite(function () { return sendAction('OPEN_EXIT_INTENT'); });
};
// Watch scroll and send the exit intent mobile flag to iframe
export var enableExitIntentMobile = function () {
    var listenToScrollUp = function () {
        window.document.removeEventListener('touchstart', listenToScrollUp);
        var previousTop = 0;
        var hasScrolledDown = false;
        var shown = false;
        var signaledClose = false;
        var listener = function () {
            var doc = document.documentElement;
            var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
            var dir = top > previousTop ? 'down' : 'up';
            if (dir === 'down' && !hasScrolledDown) {
                hasScrolledDown = true;
                signaledClose = false;
            }
            else if (dir === 'up' && hasScrolledDown && !shown) {
                sendAction('OPEN_EXIT_INTENT_MOBILE');
                shown = true;
                hasScrolledDown = false;
                window.document.removeEventListener('scroll', listener);
            }
            previousTop = top;
        };
        window.document.addEventListener('scroll', listener);
    };
    // Attaches an onTouch event that ensures this code will only be executed on devices used as touch
    window.document.addEventListener('touchstart', listenToScrollUp);
};
