export default {
  select: {
    general: {
      height: '38px !important',
      width: 362,
      borderRadius: '0 !important',
      ':child():option[value=""]': {
        color: 'red'
      }
    },
    mobile: {
      width: 260
    }
  }
};
