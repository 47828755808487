// @flow
import React from 'react';
import ReactLoading from 'react-loading';
import { StyleSheet, css } from 'aphrodite/no-important';

type IProps = {
  message: string,
  color?: string,
  messageColor?: string
};

class Loading extends React.PureComponent<IProps, null> {
  static defaultProps = {
    color: '#A8A8A8',
    message: 'Einen Moment...'
  };
  render() {
    const messageExtraStyle = {};
    if (this.props.messageColor) {
      messageExtraStyle.color = this.props.messageColor;
    }
    return (
      <div className={css(styles().journeyLoader)}>
        {this.props.message && (
          <p className={css(styles().loaderLabel)} style={messageExtraStyle}>
            {this.props.message}
          </p>
        )}
        <ReactLoading type="bars" color={this.props.color} delay={100} />
      </div>
    );
  }
}

type WithLoadingProps = {
  isLoading?: boolean,
  message?: string
};

export function withLoadingIndicator(Component) {
  return function EnhancedComponent({ isLoading, ...props }: WithLoadingProps) {
    if (!isLoading) {
      return <Component {...props} />;
    }
    const { message } = props;
    return <Loading message={message} />;
  };
}

const styles = () =>
  StyleSheet.create({
    journeyLoader: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center'
    },
    loaderLabel: {
      color: '#A8A8A8'
    }
  });

export default Loading;
