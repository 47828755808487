// @flow
import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';

type IProps = {
  restart: () => void,
  children: React.Node
};

class ErrorBoxComponent extends React.PureComponent<IProps, null> {
  render() {
    return (
      <div className={css(Styles.errorBox)}>
        <div>
          <img
            src="https://roomtailors.de/files/filestore/file/1728/error.jpg"
            alt="Ein Fehler ist aufgetreten"
          />
        </div>
        {this.props.children}
      </div>
    );
  }
}

const Styles = StyleSheet.create({
  errorBox: {
    backgroundColor: '#FFF',
    color: '#3c3c3c',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Conversional Open Sans',
    height: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%'
  }
});

export default ErrorBoxComponent;
