import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash.get'
import isEqual from 'lodash.isequal'

import { TrackingActions, Events } from 'conversional-journey'

import { matches } from '../../utilities/ie11'

const THRESHOLD = 0
const SCROLLED_ELEMENT = '#conversional-posed-modal'

const SectionTracking = (props: any) => {
  let currentlyViewedSection = 0
  let timer = Date.now()
  let blurTime = null

  const getCurrentlyViewedSection = target => {
    const sections = target.querySelectorAll('section')

    let i = 0
    let selected

    const p = window.innerHeight / 2

    for (const section of sections) {
      const box = section.getBoundingClientRect()
      const c1 = Math.ceil(box.y)
      const match = c1 < p

      if (match) {
        selected = i
      }
      i++
    }

    return selected
  }

  const trackCurrentlyViewedSection = () => {
    const time = Date.now() - timer

    if (time > THRESHOLD) {
      const index = currentlyViewedSection
      const section = props.sections[index]
      if (section) {
        const time = Date.now() - timer
        const category = get(section, 'Content.componentKey')

        if (category) {
          props.dispatch(
            TrackingActions.event({ ...Events.SECTION_VIEW, category: category, label: time })
          )
        }
      }
    }
  }

  const onScroll = event => {
    const { target } = event

    if (target && matches(target, SCROLLED_ELEMENT)) {
      const nextCurrentlyViewedSection = getCurrentlyViewedSection(target)

      if (nextCurrentlyViewedSection != currentlyViewedSection) {
        trackCurrentlyViewedSection()
        timer = Date.now()
      }

      currentlyViewedSection = nextCurrentlyViewedSection
    }
  }

  const onBlur = () => {
    blurTime = Date.now()
  }

  const onFocus = () => {
    if (blurTime !== null) {
      const diff = Date.now() - blurTime
      timer += diff
      blurTime = null
    }
  }

  const listenerOptions = {
    capture: true,
    passive: true
  }

  React.useEffect(() => {
    window.addEventListener('blur', onBlur)
    window.addEventListener('focus', onFocus)
    timer = Date.now()

    window.document.addEventListener('scroll', onScroll, listenerOptions)

    return () => {
      window.removeEventListener('blur', onBlur)
      window.removeEventListener('focus', onFocus)
      window.document.removeEventListener('scroll', onScroll, listenerOptions)
    }
  })

  return <div />
}

const getSectionsFromState = state => {
  const sections = []
  const { result } = state
  if (result) {
    const { content } = result
    if (content) {
      const [root] = Object.keys(content)
      if (root) {
        const { Children } = content[root]
        if (Children) {
          for (const key in Children) {
            sections.push(Children[key])
          }
        }
      }
    }
  }
  return sections
}

const connector = state => {
  const sections = getSectionsFromState(state)
  return { sections }
}

const options = {
  areStatesEqual: (next, prev) => {
    const nextSections = getSectionsFromState(next)
    const prevSections = getSectionsFromState(prev)
    return isEqual(nextSections, prevSections)
  }
}

export default connect(connector, null, null, options)(SectionTracking)
