import * as PubSub from 'pubsub-js'
import Events from './GlobalEvents'
import urlEventsHandler from './urlEventsHandler'

export const GlobalEvents = Events

export function addCallbacks(component = {}, callbacks = false, callbackMap) {
  if (!callbacks) {
    return
  }

  // Add all subscribers - requires callbackMap due to limited ability to reference functions with strings in ES6 Classes
  component._subscriptions = component._subscriptions || []
  _addSubscribers(component, callbacks, callbackMap)

  // Automated remove on unmount to avoid repetitive removal of subscribers
  _removeSubscribers(component, callbacks, callbackMap)
}

export function publish(event, payload = {}, senderId = false) {
  const eventName = senderId ? `${event}.${senderId}` : event
  PubSub.publish(eventName, payload)
  log('Event published', eventName, payload)
}

const environment = process.env.REACT_APP_JOURNEY_ENV || 'development'
function log(message, ...props) {
  if (environment === 'development' || environment === 'testing') {
    // eslint-disable-next-line no-console
  }
}

/**
 *
 * @param component
 * @param callbacks
 * @param callbackMap
 * @private
 *
 * Supported callback properties:
 * on: which function is called
 * delay: how much time is passed before calling it
 * pick: variable to extract from payload
 */
function _addSubscribers(component, callbacks, callbackMap) {
  // eslint-disable-next-line no-unused-vars
  for (const key in callbacks) {
    const trigger = callbackMap[`${callbacks[key].trigger}Callback`]
    const callback = _buildCallbackFunction(callbacks[key], trigger)
    const event = callbacks[key].on
    if (callback) {
      _addCallback(component, event, callback)
      log('Subscriber added', event, callbacks[key], trigger)
    }
  }
}

/**
 * Modifies callback function by adding additional configuration behavior, e.g. delaying the callback or modifying payload before passing paramters.
 * @private
 */
function _buildCallbackFunction(configuration, callback) {
  const { on, pick, delay, addPayload } = configuration
  let modifiedCallback = callback

  if (!on || !callback) {
    return
  }

  if (pick) {
    modifiedCallback = function(on, payload = {}) {
      payload = payload[pick]
      return callback(on, payload)
    }
  }

  if (delay) {
    modifiedCallback = _wrapInTimeout(modifiedCallback, delay)
  }

  if (addPayload) {
    modifiedCallback = function(on, payload = {}) {
      payload = { ...payload, ...addPayload }
      return callback(on, payload)
    }
  }

  return modifiedCallback
}

function _wrapInTimeout(callback, delay) {
  return function() {
    setTimeout(callback, delay)
  }
}

function _removeSubscribers(component, callbacks, callbackMap) {
  component.componentWillUnmount = (function(wrapped) {
    function extendsInit() {
      // eslint-disable-next-line no-unused-vars
      for (const key in callbacks) {
        PubSub.unsubscribe(callbackMap[`${callbacks[key].trigger}Callback`])
      }
      if (typeof wrapped === 'function') {
        wrapped()
      }
    }

    return extendsInit
  })(component.componentWillUnmount)
}

/**
 * Attaches a subscriber to trigger CALLBACK in case of EVENT.
 * Returns a token to be used for unsubscribing.
 *
 * @param _component
 * @param event
 * @param callback
 * @returns {*}
 * @private
 */
function _addCallback(_component, event, callback) {
  if (!callback) {
    return
  }
  return PubSub.subscribe(event, callback)
}

const callbacks = {
  addCallbacks,
  publish,
  urlEventsHandler
}

export default callbacks
