import React from 'react';

type IProps = {
  width?: number,
  height?: number,
  color?: string,
  className?: string
};

class TickIcon extends React.PureComponent<IProps, null> {
  render() {
    const color = this.props.color ? this.props.color : '#FFFFFF';

    let width = '64px';
    let height = '64px';

    if (this.props.width) {
      width = this.props.width;
      height = this.props.width;
    } else if (this.props.height) {
      width = this.props.height;
      height = this.props.height;
    }

    return (
      <svg
        viewBox="0 0 50 50"
        style={{ enableBackground: 'new 0 0 50 50' }}
        height={height}
        width={width}
        className={this.props.className}
      >
        <circle
          cx="24.9"
          cy="24.3"
          r="22.1"
          style={{ fill: 'none', stroke: color, strokeWidth: 3 }}
        />
        <polyline
          points="16.6,24.4 20.2,28.9 33.2,19.4"
          style={{
            fill: 'none',
            stroke: color,
            strokeWidth: 3,
            strokeLinecap: 'round'
          }}
        />
      </svg>
    );
  }
}

export default TickIcon;
