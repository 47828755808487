//@flow
import * as React from 'react'
import { css, StyleSheet } from 'aphrodite/no-important'
import { text } from 'conversional-theme'

import Icon from '../icons/Icon'
import { ICONS } from '../icons/IconList'

type IProps = {
  theme: any,
  children?: Array<React.Node>
}

class SuccessComponent extends React.PureComponent<IProps, null> {
  render() {
    const { theme, children } = this.props
    return (
      <div className={css(Styles(theme).container)}>
        <div>
          <Icon theme={theme} size="4rem" icon={ICONS.check} color={theme.colors.success} />
        </div>
        <span className={css(Styles(theme).success)}>{children}</span>
      </div>
    )
  }
}

const Styles = theme =>
  StyleSheet.create({
    container: {
      paddingTop: 48,
      paddingBottom: 48,
      alignItems: 'center',
      height: '100%',
      justifyContent: 'center',
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    success: {
      color: theme.colors.success,
      ...text(theme).h3
    }
  })

export default SuccessComponent
