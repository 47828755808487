import { captureException } from 'conversional-exceptions'

export const formatMessage = message => {
  try {
    return JSON.stringify({
      conversional: message
    })
  } catch (error) {
    captureException(error, { message })
    return ''
  }
}

const sendMessage = message => {
  window.parent.postMessage(formatMessage(message), window.conversional.origin)
}

export const sendRpcResponse = (id, result) => {
  sendMessage({
    jsonrpc: '2.0',
    id,
    result,
    error: null
  })
}

export const sendReady = () => {
  sendMessage({ ready: true })
}

export const focusModal = () => {
  sendMessage({ focusModal: true })
}

export const styleIframe = (style, replace = false) => {
  sendMessage({ styleIframe: style, replace })
}

export const styleParentBody = (style, replace = false) => {
  sendMessage({ styleParentBody: style, replace })
}

export const sendTrackingEvent = (
  category,
  action,
  label = 'undefined',
  hostTracking = false,
  journeyId = null
) => {
  sendMessage({
    trackingEvent: {
      category,
      action,
      label,
      hostTracking,
      journeyId
    }
  })
}

// Todo: remove, instead use reportRouteChange to have event paths according to this
export const sendPageViewEvent = (
  url,
  hostTracking = false,
  usePrefix = true,
  journeyId = null
) => {
  sendMessage({
    pageViewEvent: {
      url,
      hostTracking,
      usePrefix,
      journeyId
    }
  })
}

export const reportRouteChange = (route, hostTracking = false, usePrefix = true) => {
  sendMessage({
    routeChange: {
      route,
      hostTracking,
      usePrefix
    }
  })
}

export const sendHostTrackingConfig = hostTrackingConfig => {
  sendMessage({ hostTrackingConfig })
}

export const sendStorageVar = (varName, varValue) => {
  sendMessage({
    storageVar: {
      varName,
      varValue
    }
  })
}

export const sendEnableExitIntentMobile = () => {
  sendMessage({
    enableExitIntentMobile: true
  })
}

export const sendEnableExitIntent = () => {
  sendMessage({
    enableExitIntent: true
  })
}
