export const SEND_ANSWERS = 'SEND_ANSWERS'
export const SEND_ANSWERS_SUCCEEDED = SEND_ANSWERS + '_SUCCEEDED'
export const SEND_ANSWERS_FAILED = SEND_ANSWERS + '_FAILED'
export const SET_VIEW = 'SET_VIEW'

export const sendAnswers = (journeyId, params) => ({
  type: SEND_ANSWERS,
  params,
  journeyId
})

export const sendAnswersSucceeded = (journeyId, response) => ({
  type: SEND_ANSWERS_SUCCEEDED,
  journeyId,
  response
})

export const sendAnswersFailed = error => ({
  type: SEND_ANSWERS_FAILED,
  error
})

export const setCurrentView = params => ({
  type: SET_VIEW,
  params
})

export const OPEN_CLOSURE = 'OPEN_CLOSURE'
export const openClosure = (journeyId, questions) => ({
  type: OPEN_CLOSURE,
  payload: { questions, journeyId }
})

export const OPENED_CLOSURE = 'OPENED_CLOSURE'
export const openedClosure = () => ({
  type: OPENED_CLOSURE
})

export const SAVE_ANSWERS = 'SAVE_ANSWERS'
export const saveAnswers = questions => ({
  type: SAVE_ANSWERS,
  payload: { questions }
})

export const OPEN_QUESTIONNAIRE = 'OPEN_QUESTIONNAIRE'
export const openQuestionnaire = journeyId => ({
  type: OPEN_QUESTIONNAIRE,
  payload: { journeyId }
})

export const LOAD_QUESTIONNAIRE = 'LOAD_QUESTIONNAIRE'
export const LOAD_QUESTIONNAIRE_SUCCEEDED = LOAD_QUESTIONNAIRE + '_SUCCEEDED'
export const loadQuestionnaire = () => ({
  type: LOAD_QUESTIONNAIRE
})
export const loadQuestionnaireSucceeded = questionnaire => ({
  type: LOAD_QUESTIONNAIRE_SUCCEEDED,
  questionnaire
})
