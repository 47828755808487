// @flow
import find from 'lodash.find'
import ReactGA from 'react-ga'

import store from 'conversional-persistent-storage'
import { AB_STORAGE_KEY } from 'conversional-environment'

const GTM = 'gtm'
const GTAG = 'gtag'
const GA = 'ga'
const supportedTrackers = [GA, GTM, GTAG]

export class Tracker {
  static instance
  eventPath
  gid
  handler
  hostConfig = []
  journeyId = null
  prefix
  trackerName
  useAbTest = true
  noCookies = false
  abStorageKey

  constructor({ gid, prefix, trackerName, journeyId, useAbTest = true, noCookies = false }) {
    if (Tracker.instance) {
      return Tracker.instance
    }

    this.gid = gid
    this.prefix = prefix
    this.trackerName = trackerName
    this.journeyId = journeyId
    this.useAbTest = useAbTest
    this.noCookies = noCookies
    this.initializeTracker()
    this.handler = null

    Tracker.instance = this
  }

  initializeTracker = () => {
    ReactGA.initialize(this.gid, {
      debug: false,
      testMode: process.env.NODE_ENV === 'test',
      gaOptions: {
        anonymizeIp: true,
        name: this.trackerName,
        trackingId: this.gid,
        storage: !this.noCookies
      }
    })
  }

  updateConfig = config => {
    Object.assign(this, config)
  }

  updateHostConfig = ({ events, tool }) => {
    if (this.validateTracker(tool)) {
      this.handler = this.trackingObjects[tool]
      this.hostConfig = events
    }
  }

  validateTracker = tool => {
    if (!supportedTrackers.includes(tool)) {
      return false
    }
    if (!this.trackingObjects[tool].validate()) {
      return false
    }
    return true
  }

  setEventPath = path => {
    this.eventPath = path
  }

  trackingObjects = {
    //Google Tag Manager
    gtm: {
      system: 'gtm',
      event: (category, action, label) => {
        window.dataLayer.push({
          event: this.prefix ? `${this.prefix}-${category}` : category,
          eventAction: action,
          eventLabel: label,
          nameSpace: 'conversional'
        })
      },
      pageView: url => {
        window.dataLayer.push({
          event: 'VirtualPageview',
          virtualPageURL: `/${this.prefix}${url}`,
          nameSpace: 'conversional'
        })
      },
      validate: () => !!window.dataLayer
    },

    //Google Analytics gTag (new version)
    gtag: {
      system: 'gtm',
      event: (category, action, label) => {
        window.gtag('event', action, {
          event_category: this.prefix ? `${this.prefix}-${category}` : category,
          event_label: label
        })
      },
      pageView: () => {
        // Currently not supported - requires GA Measurement Id which cannot be read from the DOM easily
        /*if(GA_MEASUREMENT_ID) {
            window.gtag('config', GA_MEASUREMENT_ID, {
              'page_path': url
            })
          }*/
      },
      validate: () => !!window.gtag
    },

    //Google Analytics
    ga: {
      system: 'gtm',
      event: function(category, action, label) {
        const prefixedCategory = this.prefix ? `${this.prefix}-${category}` : category
        window.ga('send', 'event', prefixedCategory, action, label)
      },
      pageView: function(url) {
        window.ga('send', 'pageview', `/${this.prefix}${url}`)
      },
      validate: () => !!window.ga
    }
  }

  event = ({ category, action, label = 'undefined', hostTracking = false, journeyId }) => {
    const payload = this.eventPath
      ? { location: `${window.location.origin}/conversional${this.eventPath}` }
      : {}

    //Track event for internal logging
    ReactGA.ga(`${this.trackerName}.send`, 'event', category, action, label, {
      dimension1: journeyId || this.journeyId,
      dimension2: this.useAbTest ? store.getItem(AB_STORAGE_KEY) : null,
      ...payload
    })
    //Track event for external logging
    let applyHostTracking
    const existsForBoth = find(this.hostConfig, { action, category })
    const existsForAction = find(this.hostConfig, { action })
    const existsForCategory = find(this.hostConfig, { category })

    if (existsForBoth) {
      applyHostTracking = true
    } else if (existsForAction && typeof existsForAction.category === 'undefined') {
      applyHostTracking = true
    } else if (existsForCategory && typeof existsForCategory.action === 'undefined') {
      applyHostTracking = true
    } else {
      applyHostTracking = hostTracking
    }

    // const applyHostTracking = exists || hostTracking;
    if (applyHostTracking) {
      if (this.handler) {
        this.handler.event(category, action, label)
      }
    }
  }

  pageView = ({ page, hostTracking = false, usePrefix = true, journeyId }) => {
    //Track pageview for internal logging
    const path = usePrefix ? `/${this.prefix}${page}` : page
    ReactGA.ga(`${this.trackerName}.send`, 'pageview', path, {
      dimension1: journeyId || this.journeyId,
      dimension2: this.useAbTest ? store.getItem(AB_STORAGE_KEY) : null
    })

    //Track pageview for external logging
    const exists = Boolean(find(this.hostConfig, { action: 'viewed' }))
    const applyHostTracking = exists || hostTracking
    if (applyHostTracking) {
      if (this.handler) {
        this.handler.pageView(page)
      }
    }
  }
}
let trackerInstance = null
export const unsetTracker = () => {
  if (Tracker.instance) {
    delete Tracker.instance
  }
  trackerInstance = null
}

export const NoTracker = {
  event: () => {},
  pageView: () => {},
  setEventPath: () => {}
}

export default config => {
  if (!config) {
    config = {}
  }

  if (!trackerInstance) {
    if (!config.gid) {
      return NoTracker
    }
    //update tracker and return new one
    trackerInstance = new Tracker(config)
    return trackerInstance
  }

  trackerInstance.updateConfig(config)
  return trackerInstance
}
