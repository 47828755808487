import trackingConfig from './config/trackingConfig';
import * as widgetPaths from './config/widgetPaths';
import * as exitIntentConfig from './config/exitIntent';
import * as launcherConfig from './config/launcherConfig';

export { default } from './config/config';

export { widgetPaths, exitIntentConfig, trackingConfig, launcherConfig };

export const AB_STORAGE_KEY = 'conversional-abtest';
