// noFlow: forwardRef is not yet support by flowjs
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';

import DraggableComponent from '../base/DraggableComponent';

type SliderProps = {
  children?: Array<React.Node>,
  nativeScroll: boolean,
  containerWidth: number,
  viewWidth: number,
  theme: {}
};

const ScrollableSliderComponent = React.forwardRef(
  ({ children, nativeScroll, containerWidth, viewWidth, theme }: SliderProps, ref) => (
    <div className={css(Styles(nativeScroll).viewBox)}>
      <div ref={ref.viewBox}>
        <div className={css(Styles().slider)}>
          <div className={css(Styles().sliderSleigh)} ref={ref.slider}>
            {nativeScroll ? (
              children
            ) : (
              <DraggableComponent
                showArrows={!nativeScroll}
                viewWidth={viewWidth}
                containerWidth={containerWidth}
                theme={theme}
              >
                {children}
              </DraggableComponent>
            )}
          </div>
        </div>
      </div>
    </div>
  )
);
ScrollableSliderComponent.displayName = 'ScrollableSliderComponent';

const Styles = nativeScroll =>
  StyleSheet.create({
    viewBox: {
      position: 'relative',
      overflow: nativeScroll ? 'auto' : 'hidden'
    },
    slider: {
      whiteSpace: 'nowrap',
      alignItems: 'center',
      height: '100%',
      transition: 'margin 300ms ease-out',
      width: 'fit-content'
    },
    sliderSleigh: {
      display: 'flex'
    }
  });

export default ScrollableSliderComponent;
