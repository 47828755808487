import axios from 'axios'
import axiosRetry from 'axios-retry'
import { get } from 'lodash'

import { captureException } from 'conversional-exceptions'

axios.defaults.timeout = 50000
axios.defaults.params = {}

axiosRetry(axios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: error => {
    return (
      axiosRetry.isNetworkOrIdempotentRequestError(error) ||
      (axiosRetry.isIdempotentRequestError(error) && error.code === 'ECONNABORTED')
    )
  }
})

//isIdempotentRequest && error.code = ...

export default class API {
  abTest
  apiKey
  baseUrl
  domain
  questionnaireId
  token
  version

  constructor(questionnaireId, apiKey, domain, version, { token, abTest } = {}) {
    if (!questionnaireId) {
      throw new Error('Missing questionnaire id')
    }
    if (!apiKey) {
      throw new Error('Missing api key')
    }
    if (!domain) {
      throw new Error('Missing api domain')
    }
    if (!version) {
      throw new Error('Missing api version')
    }
    this.abTest = abTest
    this.apiKey = apiKey
    this.baseUrl = `${decodeURIComponent(domain)}${version}`
    this.domain = domain
    this.questionnaireId = questionnaireId
    this.token = token
    this.version = version
  }

  makeUrl = path =>
    `${this.baseUrl}${path}`
      .replace(/:apiKey/g, this.apiKey)
      .replace(/:token/g, this.token)
      .replace(/:questionnaireId/g, this.questionnaireId)

  async request({ method = 'GET', path, payload, query = {} }) {
    let response
    const url = this.makeUrl(path)
    const params = {
      ...query,
      token: this.token,
      abTest: this.abTest,
      apiKey: this.apiKey
    }
    try {
      switch (method) {
        default: {
          throw new Error(`Unknown method ${method}`)
        }
        case 'GET':
          {
            response = await axios.get(url, { params })
          }
          break
        case 'POST':
          {
            response = await axios.post(url, payload, { params })
          }
          break
      }
      return get(response, 'data.data') || response.data
    } catch (error) {
      captureException(error, {
        abTest: this.abTest,
        apiKey: this.apiKey,
        baseUrl: this.baseUrl,
        domain: this.domain,
        method,
        path,
        payload,
        query,
        questionnaireId: this.questionnaireId,
        response,
        token: this.token,
        url,
        version: this.version
      })
      throw error
    }
  }

  async postRequest(endpoint, data) {
    return this.request({
      method: 'POST',
      path: `/${endpoint}?apiKey=${this.apiKey}`,
      payload: data
    })
  }
}
