// @flow
import * as React from 'react';

import GridBase from './GridBase';
import BaseComponent from '../base/BaseComponent';
import renderObjectOfComponents from '../../utilities/components/renderObjectOfComponents';

type GridProps = {
  cols: number,
  rows: number,
  spots?: Array<React.Node> | null,
  notResponsive?: boolean,
  gridColumns?: string,
  gridRows?: string,
  styles: {},
  mobileStyles: {},
  theme: {}
};

/**
 * GridComponent
 * Purpose      Wraps grid component in a Base for styling pruposes.
 * Responsive   None
 * Nestable     True
 * Base         False
 */
class GridComponent extends React.PureComponent<GridProps, null> {
  render() {
    const { spots, cols, rows, gridColumns, gridRows, styles, mobileStyles, theme } = this.props;
    return (
      <BaseComponent injectedStyles={styles}>
        <GridBase
          key="Base Grid"
          cols={cols}
          rows={rows}
          gridColumns={gridColumns}
          gridRows={gridRows}
          mobileStyles={mobileStyles}
          theme={theme}
        >
          {renderObjectOfComponents(spots)}
        </GridBase>
      </BaseComponent>
    );
  }
}

export default GridComponent;
