// @flow
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { ComponentStyles } from 'conversional-theme';

type IProps = {
  children: React.Element<*>,
  openModal: () => {},
  styles?: {
    desktop?: {},
    general?: {},
    mobile?: {}
  }
};

class ModalButtonComponent extends React.PureComponent<IProps, null> {
  render() {
    const { openModal, children, styles } = this.props;
    const className = css(Styles().clickable, ComponentStyles(styles));

    return (
      <div onClick={openModal} className={className}>
        {children}
      </div>
    );
  }
}

const Styles = () =>
  StyleSheet.create({
    clickable: {
      cursor: 'pointer'
    }
  });

export default ModalButtonComponent;
