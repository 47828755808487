import { makeStyles } from '@material-ui/core/styles'

export const useStyles = (theme, hasError) =>
  makeStyles({
    root: {
      fontFamily: theme.typo.fonts.regular,
      fontSize: theme.typo.sizes.m,
      height: 50,
      width: '100%',
      marginBottom: hasError ? '1rem' : 0
    },
    container: {
      marginTop: 20,
      padding: 0,
      width: '100%'
    },
    label: {
      color: `${theme.colors.brand} !important`
    }
  })
