// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { CommonSelectors } from 'conversional-journey'
import GridComponent from './GridComponent'

type IProps = {
  cols: number,
  rows: number,
  spots?: Array<React.Node> | null,
  notResponsive?: boolean,
  gridColumns?: string,
  gridRows?: string,
  styles: {},
  mobileStyles: {},
  theme: {}
}

class GridContainer extends React.PureComponent<IProps> {
  render() {
    return <GridComponent {...this.props} />
  }
}

const mapStateToProps = createStructuredSelector({
  theme: CommonSelectors.selectTheme
})

export default connect(mapStateToProps)(GridContainer)
