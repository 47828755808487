// @flow
import * as React from 'react';
import GridBase from '../grid/GridBase';
import GridOpacityBase from './GridOpacityBase';

type Props = {
  spots: {
    GridElements: React.Element<*>,
    GridDetails: ?React.Element<*>
  },
  rows: number,
  cols: number,
  activeOverlay: ?string,
  open: (component: string, e: Event) => void,
  close: () => void,
  theme: {}
};

/**
 * InteractiveGridComponent
 * Purpose      Render elements in a grid. Child elements receive an onClick event handler to open a details view
 *              Todo: Long term perspective: Enable onClick handling/referencing in configuration as well
 * Responsive   Renders grid elements as accordeon
 * Nestable     True
 * Base         False
 */
function InteractiveGridComponent({ spots, rows, cols, activeOverlay, open, close, theme }: Props) {
  const GridElements =
    spots.GridElements && spots.GridElements.props && spots.GridElements.props.children
      ? React.cloneElement(spots.GridElements.props.children, { close, open, activeOverlay })
      : null;
  const GridDetails =
    spots.GridDetails && spots.GridDetails.props && spots.GridDetails.props.children
      ? React.cloneElement(spots.GridDetails.props.children, { close, open, activeOverlay })
      : null;

  return (
    <GridBase cols={cols} rows={rows} autoAdjustGrid={false} theme={theme}>
      {GridElements}
      {GridDetails}
      {activeOverlay && <GridOpacityBase rows={rows} cols={cols} />}
    </GridBase>
  );
}

export default InteractiveGridComponent;
