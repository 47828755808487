// @flow
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import posed, { PoseGroup } from 'react-pose';
import MediaQuery from 'react-responsive';

import { IconButton } from 'conversional-components';
import { boxes } from 'conversional-theme';

type IProps = {
  wasOpened: boolean,
  onOpen: () => {},
  hide: () => {},
  display: string,
  showModal: boolean,
  close: () => {},
  backdrop?: string,
  theme: {},
  children?: React.Node
};

const PosedModal = posed.div({
  enter: {
    delay: 0,
    WebkitTransition: 'opacity 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946)',
    transition: {
      opacity: {
        ease: [0.465, 0.183, 0.153, 0.946],
        duration: 300
      }
    },
    pointerEvents: 'auto',
    opacity: 1
  },
  exit: {
    WebkitTransition: 'opacity 0.1s cubic-bezier(0.465, 0.183, 0.153, 0.946)',
    transition: {
      opacity: {
        ease: [0.465, 0.183, 0.153, 0.946],
        duration: 200
      }
    },
    opacity: 0
  }
});

const ExitIntentComponent = function({
  close,
  hide,
  onOpen,
  display,
  showModal,
  backdrop,
  theme,
  children
}: IProps) {
  return (
    <MediaQuery minWidth={boxes.md.min}>
      <PoseGroup onRest={hide}>
        {showModal && (
          <PosedModal key="conversional-exitintent-modal">
            <div className={css(Styles(theme, backdrop).backdrop)} onClick={close}>
              <div className={css(Styles(theme).overlay)} onClick={e => e.stopPropagation()}>
                {display && (
                  <img
                    src={display}
                    onClick={onOpen}
                    alt="Start"
                    className={css(Styles(theme, backdrop).image)}
                  />
                )}
                {children}
                <div className={css(Styles(theme).close)}>
                  <IconButton close={close} />
                </div>
              </div>
            </div>
          </PosedModal>
        )}
      </PoseGroup>
    </MediaQuery>
  );
};

const Styles = (theme, backdrop) =>
  StyleSheet.create({
    backdrop: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: backdrop,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999999,
      cursor: 'pointer'
    },
    overlay: {
      font: theme.typo.fonts.regular,
      minWidth: 500,
      minHeight: 300,
      position: 'relative'
    },
    close: {
      position: 'absolute',
      top: `-${theme.spaces.desktop.mL}`,
      right: `-${theme.spaces.desktop.mL}`
    },
    image: {
      cursor: 'pointer'
    }
  });

export default ExitIntentComponent;
