import { StyleSheet } from 'aphrodite/no-important'

export default () =>
  StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      textAlign: 'center'
    },
    headline: {
      paddingBottom: 18
    },
    text: {
      fontSize: 16,
      paddingTop: 12,
      paddingBottom: 18
    }
  })
