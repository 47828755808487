// @flow
import * as React from 'react'
import ExpandableImageBoxComponent from './ExpandableImageBoxComponent'

type IProps = {
  linkText: string,
  teaser: string,
  title: string,
  img: string,
  open: (component: string, e: Event) => {},
  activeOverlay: boolean,
  styles: {},
  theme: {}
}

class ExpandableImageBoxContainer extends React.PureComponent<IProps> {
  render() {
    return <ExpandableImageBoxComponent {...this.props} />
  }
}

export default ExpandableImageBoxContainer
