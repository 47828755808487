// @flow
import * as React from 'react'
import { css, StyleSheet } from 'aphrodite/no-important'
import MediaQuery from 'react-responsive'
import { text, boxes, sizes, mediaBreakpointUp, mediaBreakpointDown } from 'conversional-theme'
import ImageBoxBase from './ImageBoxBase'
import BaseComponent from '../base/BaseComponent'
import Icon from '../icons/Icon'
import { ICONS } from '../icons/IconList'

type Props = {
  linkText: string,
  teaser: string,
  title: string,
  img: string,
  open: (component: string, e: Event) => {},
  activeOverlay: boolean,
  styles: {},
  theme: {}
}

/**
 * ExpandableImageBoxComponent
 * Purpose      TODO: Describe
 * Responsive   TODO: Describe
 * Nestable     False
 * Base         False
 */
export class ExpandableImageBoxComponent extends React.PureComponent<Props, null> {
  render() {
    const { title, img, teaser, linkText, open, activeOverlay, styles, theme } = this.props
    return (
      <BaseComponent injectedStyles={styles} styles={staticStyles(theme).wrapper}>
        <ImageBoxBase img={img}>
          <div className={css(staticStyles(theme).innerBox)}>
            <h3 className={css(staticStyles(theme).headline)} onClick={open}>
              {title}
            </h3>
            <MediaQuery maxWidth={boxes.sm.max}>
              <span
                onClick={open}
                className={css(staticStyles(theme).icon, activeOverlay && staticStyles(theme).open)}
              >
                <Icon theme={theme} icon={ICONS.chevron} />
              </span>
            </MediaQuery>
            <MediaQuery minWidth={boxes.md.min}>
              <span>
                {teaser}
                <span
                  onClick={open}
                  className={css(staticStyles(theme).teaser, staticStyles(theme).teaserOverwrite)}
                >
                  {linkText}
                </span>
              </span>
            </MediaQuery>
          </div>
        </ImageBoxBase>
      </BaseComponent>
    )
  }
}

const staticStyles = theme =>
  StyleSheet.create({
    innerBox: {
      display: 'flex',
      [mediaBreakpointDown(sizes.sm)]: {
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center',
        width: '100%'
      },
      [mediaBreakpointUp(sizes.md)]: {
        margin: `${theme.spaces.desktop.s} ${theme.spaces.desktop.s} ${theme.spaces.desktop.s} ${theme.spaces.desktop.s}`,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        height: '100%',
        marginBottom: theme.spaces.desktop.l,
        marginTop: theme.spaces.desktop.l
      }
    },
    teaser: {
      ...text(theme).link,
      fontStyle: 'italic'
    },
    teaserOverwrite: {
      color: theme.colors.light
    },
    headline: {
      margin: 0,
      cursor: 'pointer'
    },
    icon: {
      transition: 'transform 200ms ease-out',
      cursor: 'pointer'
    },
    open: {
      transform: 'rotate(90deg)'
    },
    wrapper: {
      height: '100%',
      flexGrow: 1
    }
  })

export default ExpandableImageBoxComponent
