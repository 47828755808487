// @flow
import * as React from 'react'

import LoadingScreenComponent from './LoadingScreenComponent'

type IProps = {
  doneLoading: boolean,
  children: React.node,
  loadingImage?: string
}

const TransitionContainer = ({ doneLoading, children, loadingImage }: IProps) => {
  const [showResult, setShowResult] = React.useState(false)
  if (doneLoading && showResult === false) {
    setTimeout(() => {
      setShowResult(true)
    }, 2000)
  }
  return (
    <React.Fragment>
      {showResult ? children : <LoadingScreenComponent loadingImage={loadingImage} />}
    </React.Fragment>
  )
}

export default TransitionContainer
