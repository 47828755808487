// @flow
import React from 'react';

type IProps = {
  color?: string,
  width?: string,
  height?: string
};

class PersonIcon extends React.PureComponent<IProps, null> {
  render() {
    const color = this.props.color ? this.props.color : '#000';
    const width = this.props.width ? this.props.width : '35px';
    const height = this.props.height ? this.props.height : '35px';

    return (
      <svg
        x="0px"
        y="0px"
        viewBox="0 0 481 527.3"
        style={{
          left: '-40px',
          top: 0,
          fill: color,
          position: 'absolute',
          stroke: color,
          strokeWidth: 5,
          strokeMiterlimit: 10
        }}
        height={height}
        width={width}
      >
        <path
          className="st0"
          d="M258.7 415.9c-17.8 7.6-38.5-.7-46.1-18.6-2-4.8-7.6-7-12.4-5-4.8 2-7 7.6-5 12.4 8.8 20.5 28.8 32.9 49.8 32.9 7.1 0 14.2-1.4 21.1-4.3 4.8-2 7-7.6 5-12.4-2-4.8-7.6-7.1-12.4-5z"
        />
        <path
          className="st0"
          d="M465 312c0-13-4.7-25.3-13.3-34.9 3.5-70.9 5.3-118.3-17.1-161.9-15.3-29.7-40-54.6-73.5-73.9-39-22.5-83.6-33.5-128.8-31.9-39.7 1.4-76.7 20-102.2 51.1-32.9 1.9-62.9 19.7-79 47.4-1.4 2.4-2.7 4.8-4 7.3-22.4 43.7-20.6 91-17.1 161.9-8 9.3-13 21.5-13 34.9 0 27.6 21.3 50.2 48.3 52.4 11.2 36.2 41 77.3 89 122.5 24.3 22.9 55.5 34.4 86.8 34.4 31.2 0 62.4-11.5 86.8-34.4 48-45.2 77.7-86.3 89-122.5 26.8-2.2 48.1-24.8 48.1-52.4zM233.1 28.4c41.7-1.5 82.7 8.7 118.7 29.4 30.3 17.4 52.5 39.7 66.1 66.1 18.9 36.7 18.6 76.7 15.2 147.9L412 273c-142.3 4.7-220.9-61.5-244.2-85L145 72.4c22-26.8 53.9-42.8 88.1-44zm-169 95.4c1.1-2.1 2.3-4.3 3.5-6.4 12.3-21.1 34.7-35 59.6-37.7l22 111.6c-15 34.5-40.7 52.6-80.6 57-4.8.5-8.4 4.6-8.4 9.4v2.5c-4.1.7-7.9 1.9-11.6 3.6-3.1-66.2-2.6-104.6 15.5-140zM35.9 312c0-15.3 10.3-28.3 24.3-32.4V333c0 3.8.3 7.7.7 11.6-14.4-3.9-25-17-25-32.6zm278.9 161.2c-41.4 39-106.2 39-147.6 0C109.5 418.8 79 370.3 79 333v-66.9c38.5-6.2 65.8-25.1 82.9-57.6 14.5 13.4 38.4 31.9 72.2 48.2 50.2 24.2 107 36 168.9 35.3v41c0 37.3-30.5 85.8-88.2 140.2zm106.3-128.6c.5-3.9.7-7.8.7-11.6v-41.7l16.4-.9c5.1 6.1 7.9 13.6 7.9 21.6 0 15.6-10.6 28.7-25 32.6z"
        />
        <path
          className="st0"
          d="M124.5 314.1c-3.7 3.7-3.7 9.7 0 13.4 3.7 3.7 9.7 3.7 13.4 0 6.8-6.8 17.8-6.8 24.6 0 1.8 1.8 4.3 2.8 6.7 2.8s4.8-.9 6.7-2.8c3.7-3.7 3.7-9.7 0-13.4-14.2-14.2-37.2-14.2-51.4 0z"
        />
        <circle className="st0" cx="333" cy="316.9" r="17.8" />
      </svg>
    );
  }
}

export default PersonIcon;
