var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { urlHelpers } from 'conversional-api';
import { ComponentStyles } from 'conversional-theme';
import { css } from 'aphrodite';
var iframeStyles = {};
export var styleIframe = function (style, replace) {
    if (replace === void 0) { replace = false; }
    iframeStyles = replace ? style : __assign(__assign({}, iframeStyles), style);
    window._conversionalWidget.iframe.className = css(ComponentStyles(iframeStyles));
};
var getPath = function () {
    var urlParams = urlHelpers.parseQueryString();
    var path = '/launcher';
    var params = [
        "origin=" + encodeURIComponent(window._conversionalWidget.origin),
        "ref=" + encodeURIComponent(window.location.href)
    ];
    // In some cases it was not possible to get the query string parameters from window.location
    // This is probably due to the use of Vue
    // Instead the href string needs to be parsed manually
    var widgetShouldOpen = Boolean('openWidget' in urlParams);
    if (!widgetShouldOpen && window.location.href.indexOf('openWidget')) {
        urlParams['openWidget'] = urlHelpers.parseHref(window.location.href)['openWidget'];
    }
    var openWidget = urlParams['openWidget'] || widgetShouldOpen;
    if (openWidget) {
        params.push("openWidget=" + urlParams.openWidget);
        path = '/modal/journey';
        var token = urlParams.token;
        if (token) {
            params.push("token=" + token);
        }
    }
    for (var key in urlParams) {
        if (/^conversional/.test(key)) {
            if (urlParams[key]) {
                params.push(key + "=" + urlParams[key]);
            }
            else {
                params.push(key);
            }
        }
    }
    return path + "?" + params.join('&');
};
var QUESTIONNAIRE_ELEMENT_ID = 'conversional-journey';
var URL_ATTRIBUTE_ID = 'data-api';
var VERSION_ATTRIBUTE_ID = 'data-version';
var KEY_ATTRIBUTE_ID = 'data-key';
var QID_ATTRIBUTE_ID = 'data-qid';
var NO_COOKIES = 'data-noCookies';
var configAvailable = function () {
    var config = document.getElementById(QUESTIONNAIRE_ELEMENT_ID);
    return (config);
};
export var runWhenConfigAvailable = function (run) {
    if (configAvailable()) {
        return run();
    }
    // Attach observer waiting for configurations that are inserted later
    var observer = new MutationObserver(function () {
        if (configAvailable()) {
            observer.disconnect();
            run();
        }
    });
    observer.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: false,
        characterData: false
    });
};
export var getJourneyId = function () {
    var questionnaireElem = document.getElementById(QUESTIONNAIRE_ELEMENT_ID);
    return questionnaireElem && questionnaireElem.getAttribute(QID_ATTRIBUTE_ID);
};
export var getNoCookies = function () {
    var questionnaireElem = document.getElementById(QUESTIONNAIRE_ELEMENT_ID);
    return questionnaireElem && questionnaireElem.getAttribute(NO_COOKIES);
};
var getFullPath = function () {
    var questionnaireElem = document.getElementById(QUESTIONNAIRE_ELEMENT_ID);
    var baseUrl = questionnaireElem && questionnaireElem.getAttribute(URL_ATTRIBUTE_ID);
    var apiKey = questionnaireElem && questionnaireElem.getAttribute(KEY_ATTRIBUTE_ID);
    var version = questionnaireElem && questionnaireElem.getAttribute(VERSION_ATTRIBUTE_ID);
    var questionnaireId = getJourneyId();
    window.conversional = {
        questionnaireId: questionnaireId
    };
    var nextParams = {
        apiKey: apiKey,
        baseUrl: baseUrl,
        questionnaireId: questionnaireId,
        version: version
    };
    var nextSearch = [];
    for (var param in nextParams) {
        // @ts-ignore
        var value = nextParams[param];
        if (value) {
            nextSearch.push(param + "=" + value);
        }
    }
    var nextPath = getPath();
    if (nextSearch.length) {
        nextPath += "&" + nextSearch.join('&');
    }
    return nextPath;
};
export var makeIframe = function (url) {
    var path = getFullPath();
    var src = "" + url + path;
    var iframe = window.document.createElement('iframe');
    window._conversionalWidget.iframe = iframe;
    iframe.style.overflow = 'hidden';
    iframe.style.position = 'fixed';
    iframe.style.top = '-100vh';
    iframe.style.bottom = '100vh';
    iframe.style.left = '-100vh';
    iframe.style.right = '-100vh';
    iframe.style.border = 'none';
    iframe.style.zIndex = '16777271';
    iframe.src = src;
    iframe.id = 'conversional-widget-iframe';
    window.document.body.prepend(iframe);
};
