import {
  sizes,
  boxes,
  mediaBreakpointOnly,
  mediaBreakpointUp,
  mediaBreakpointDown,
  mediaBreakpointBetween
} from './styles/sizes/sizes'
import insertGlobalStyles from './styles/globals/globals'
import { defaultTheme, createTheme } from './styles/theme'
import { ComponentStyles, text, areas } from './styles/classes/classes'
import { shadeColor } from './utilities/colors'
import { isTouchableDevice } from './utilities/mediaHelpers'

export {
  defaultTheme,
  createTheme,
  ComponentStyles,
  areas,
  boxes,
  mediaBreakpointBetween,
  mediaBreakpointDown,
  mediaBreakpointOnly,
  mediaBreakpointUp,
  insertGlobalStyles,
  isTouchableDevice,
  shadeColor,
  sizes,
  text
}
