//@flow
import * as React from 'react'

import { Primary } from '../buttons/Buttons'

type IProps = {
  styles: any,
  hideSubmit: boolean,
  theme: any,
  children: any
}

const SubmitButtonComponent = ({ styles, hideSubmit = false, children, theme }: IProps) => {
  if (hideSubmit) {
    return null
  }

  return (
    <Primary theme={theme} customStyles={styles}>
      {children}
    </Primary>
  )
}

export default SubmitButtonComponent
