import {
  sizes,
  mediaBreakpointDown,
  mediaBreakpointUp,
  mediaBreakpointBetween
} from '../sizes/sizes'
import { StyleSheet } from 'aphrodite/no-important'

export const text = theme => ({
  h1: {
    [mediaBreakpointDown(sizes.sm)]: {
      fontSize: theme.typo.sizes.xxlMobile
    },
    [mediaBreakpointUp(sizes.md)]: {
      fontSize: theme.typo.sizes.xxl
    }
  },
  h2: {
    display: 'block',
    [mediaBreakpointDown(sizes.sm)]: {
      fontSize: theme.typo.sizes.xlMobile
    },
    [mediaBreakpointUp(sizes.md)]: {
      fontSize: theme.typo.sizes.xl,
      paddingBottom: theme.spaces.desktop.mL
    },
    color: theme.colors.brand,
    fontFamily: theme.typo.fonts.byHand
  },
  h3: {
    display: 'block',
    fontFamily: [theme.typo.fonts.bold],
    fontSize: theme.typo.sizes.l,
    fontWeight: 600,
    margin: 0,
    [mediaBreakpointDown(sizes.sm)]: {
      padding: `${theme.spaces.mobile.m} ${theme.spaces.mobile.mL}`,
      fontSize: theme.typo.sizes.lMobile,
      fontFamily: [theme.typo.fonts.regular]
    },
    [mediaBreakpointBetween(sizes.sm, sizes.lg)]: {
      fontFamily: [theme.typo.fonts.bold],
      fontSize: theme.typo.sizes.lMobile,
      textTransform: 'uppercase'
    },
    [mediaBreakpointUp(sizes.lg)]: {
      fontSize: theme.typo.sizes.l,
      fontFamily: [theme.typo.fonts.bold],
      textTransform: 'uppercase'
    }
  },
  h4: {
    [mediaBreakpointDown(sizes.sm)]: {
      fontSize: theme.typo.sizes.mMobile
    },
    [mediaBreakpointUp(sizes.md)]: {
      fontSize: theme.typo.sizes.mL,
      fontFamily: theme.typo.fonts.bold,
      fontWeight: 600,
      textTransform: 'uppercase'
    }
  },
  h5: {
    fontSize: theme.typo.sizes.l,
    margin: 0,
    [mediaBreakpointDown(sizes.sm)]: {
      fontSize: theme.typo.sizes.lMobile
    }
  },
  h6: {
    fontSize: theme.typo.sizes.mL,
    fontWeight: 300,
    margin: `${theme.spaces.desktop.xs} 0 ${theme.spaces.desktop.l}`
  },
  light: {
    color: theme.colors.textLight
  },
  standard: {
    // Standard class is applied to root element
    fontFamily: [theme.typo.fonts.regular],
    [mediaBreakpointDown(sizes.sm)]: {
      fontSize: theme.typo.sizes.mMobile
    },
    [mediaBreakpointUp(sizes.md)]: {
      fontSize: theme.typo.sizes.m
    },
    color: theme.colors.text
  },
  link: {
    color: theme.colors.text,
    cursor: 'pointer',
    textDecoration: 'underline',
    transition: 'color 300ms ease-out',
    ':hover': {
      color: theme.colors.brand,
      transitionDuration: '100ms'
    },
    ':visited': {
      color: theme.colors.text
    }
  }
})

export const areas = theme => ({
  section: {
    [mediaBreakpointDown(sizes.sm)]: {
      marginBottom: theme.spaces.mobile.sectionSpacer
    },
    [mediaBreakpointUp(sizes.md)]: {
      marginBottom: theme.spaces.desktop.sectionSpacer
    }
  },
  root: {
    [mediaBreakpointDown(sizes.sm)]: {
      backgroundColor: theme.colors.backgroundLight
    }
  }
})

export const images = () => ({
  responsive: {
    width: '100%',
    height: 'auto'
  }
})

export const behavior = () => ({
  hideOnMobile: {
    [mediaBreakpointDown(sizes.sm)]: {
      display: 'none'
    }
  }
})

const GeneralStyles = styleProps => {
  if (!styleProps) {
    return {}
  }
  return {
    ...styleProps
  }
}

const DesktopStyles = styleProps => {
  if (!styleProps) {
    return {}
  }
  return {
    [mediaBreakpointUp(sizes.md)]: {
      ...styleProps
    }
  }
}

const MobileStyles = styleProps => {
  if (!styleProps) {
    return {}
  }
  return {
    [mediaBreakpointDown(sizes.sm)]: {
      ...styleProps
    }
  }
}

// Creates classes based on component-specific styles
export const ComponentStyles = (injectedStyles = {}) => {
  const { general, mobile, desktop, ...direct } = injectedStyles
  return StyleSheet.create({
    custom: {
      ...direct,
      ...GeneralStyles({ ...general }), // Custom styles can overwrite component styles
      ...DesktopStyles(desktop),
      ...MobileStyles(mobile)
    }
  }).custom
}
