import { createSelector } from 'reselect'
import { widgetPaths } from 'conversional-environment'

export const initialRouterState = {
  location: {},
  action: undefined
}

export const selectRouter = state => state.router || initialRouterState
export const selectCurrentPath = createSelector(selectRouter, router => router.location.pathname)

export const selectIsQuestionnairePath = createSelector(
  selectCurrentPath,
  path => path === widgetPaths.PATH_TO_QUESTIONNAIRE
)
export const selectIsClosurePath = createSelector(
  selectCurrentPath,
  path => path === widgetPaths.PATH_TO_CLOSURE_SCREEN
)

export const selectIsResultPath = createSelector(
  selectCurrentPath,
  path => path === widgetPaths.PATH_TO_RESULT
)
