// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { CommonSelectors } from 'conversional-journey'
import IconButtonComponent from './IconButtonComponent'

type IProps = {
  close: () => void,
  icon?: string,
  backgroundColor?: string,
  backgroundHoverColor?: string,
  color?: string,
  hoverColor?: string,
  theme: {}
}

class IconButtonContainer extends React.PureComponent<IProps> {
  render() {
    return <IconButtonComponent {...this.props} />
  }
}

const mapStateToProps = createStructuredSelector({
  theme: CommonSelectors.selectTheme
})

export default connect(mapStateToProps)(IconButtonContainer)
