// @flow
import * as React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import { sizes, mediaBreakpointUp } from 'conversional-theme';
import renderObjectOfComponents from '../../utilities/components/renderObjectOfComponents';
import BaseComponent from '../base/BaseComponent';
/**
 * Article
 * An article is a part of a section.
 * Defines the "frame" of some content and its appearance.
 */

type ComponentProps = {
  componentKey: string,
  componentTitle: string
};

type SectionProps = {
  style: {
    backgroundColor?: string,
    marginBottom: string,
    padding: string
  },
  innerPadding?: boolean,
  spots: {
    [string]: (props: ComponentProps) => React.Node
  },
  styles: {},
  theme: {}
};

function SubsectionComponent({ styles, spots, innerPadding, theme }: SectionProps) {
  return (
    <BaseComponent
      injectedStyles={styles}
      styles={[Styles(theme).subsection, innerPadding && staticStyles(theme).innerPadding]}
    >
      {renderObjectOfComponents(spots)}
    </BaseComponent>
  );
}

SubsectionComponent.defaultProps = {
  styles: {}
};

const Styles = theme =>
  StyleSheet.create({
    subsection: {
      backgroundColor: 'transparent',
      marginBottom: theme.spaces.desktop.m
    }
  });

const staticStyles = theme =>
  StyleSheet.create({
    innerPadding: {
      [mediaBreakpointUp(sizes.md)]: {
        padding: `${theme.spaces.desktop.l} ${theme.spaces.desktop.spacer}`
      }
    }
  });

export default SubsectionComponent;
