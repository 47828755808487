import get from 'lodash/get'

export const prepareAnswersPayload = ({ questions, fields, questionnaireId, meta, callback }) => {
  const answers = questions
    .filter(question => question.questionnaireId === questionnaireId)
    .map(({ id, Option, multiple }) => {
      let selectedOptions = Option.filter(o => o.selected).map(({ id, value }) => {
        return {
          id,
          value
        }
      })

      if (selectedOptions.length && !multiple) {
        selectedOptions = selectedOptions[0]
      }

      return {
        id,
        selectedOptions
      }
    })
  let fieldsNormalized = normalizeFields(fields)
  // Extract nested meta fields
  let customerMeta = fieldsNormalized.filter(field => {
    return field.name === 'meta'
  })
  customerMeta = get(customerMeta, '[0].value') || {}
  fieldsNormalized = fieldsNormalized.filter(field => {
    return field.name !== 'meta'
  })
  const payload = {
    questionnaireId: questionnaireId,
    answers,
    fields: fieldsNormalized,
    meta: { ...meta, ...customerMeta }
  }

  payload.callback = callback
  return payload
}

/**
 *
 * @param fields
 * @returns {Array}: { name, value }
 */
function normalizeFields(fields) {
  if (!Array.isArray(fields)) {
    return []
  }

  return fields.map(({ name, value }) => {
    return {
      name,
      value
    }
  })
}

export function processQuestion(question) {
  question.Option = question.Option.map(option => {
    option.selected = false
    return option
  })

  question.valid = !question.required
  question.error = false

  return question
}

export function parseQuestionnaire(response) {
  const questions = response.Questionnaire.Question.map(question => processQuestion(question))

  return {
    id: response.Questionnaire.id,
    intro: response.Questionnaire.Intro,
    config: response.Questionnaire.Configuration,
    closure: response.Questionnaire.Closure,
    questions: questions
  }
}
