export const LOAD_JOURNEY = 'LOAD_JOURNEY'
export const LOAD_JOURNEY_SUCCEEDED = LOAD_JOURNEY + '_SUCCEEDED'
export const loadJourney = journeyId => ({
  type: LOAD_JOURNEY,
  journeyId
})

export const loadJourneySucceeded = (journeyId, config) => ({
  type: LOAD_JOURNEY_SUCCEEDED,
  journeyId,
  config
})

export const OPEN_JOURNEY = 'OPEN_JOURNEY'
export const openJourney = journeyId => ({
  type: OPEN_JOURNEY,
  journeyId
})

export const SELECT_JOURNEY = 'SELECT_JOURNEY'
export const selectJourney = payload => ({
  type: SELECT_JOURNEY,
  payload
})
