export default {
  icon: {
    general: {
      width: '2.8rem',
      height: '2.8rem',
      position: 'absolute',
      left: '-3.4rem',
      top: -4
    },
    mobile: {
      display: 'none'
    }
  },
  timeslotText: {
    width: 362,
    height: 25,
    padding: '8px 6px',
    textAlign: 'left'
  },
  closeIcon: {
    general: {
      width: '1.0rem',
      height: '1.0rem',
      position: 'absolute',
      top: 8,
      right: 8,
      cursor: 'pointer'
    }
  },
  container: {
    display: 'inline-block',
    position: 'relative'
  }
}
