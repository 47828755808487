// @flow
import Loadable from 'react-loadable'
import React from 'react'

import Loading from '../components/loading/Loading'

const NoLoading = () => <span />

// Component lookup table
const componentMap = {
  AppointmentMaker: Loadable({
    loader: () => import('../components/appointmentMaker/AppointmentMakerContainer'),
    loading: Loading
  }),
  'Buttons.Link': Loadable({
    loader: () => import('../components/buttons/LinkButton'),
    loading: NoLoading
  }),
  'Buttons.Text': Loadable({
    loader: () => import('../components/buttons/TextButton'),
    loading: NoLoading
  }),
  'Buttons.Primary': Loadable({
    loader: () => import('../components/buttons/PrimaryButton'),
    loading: NoLoading
  }),
  CallToAction: Loadable({
    loader: () => import('../components/callToAction/CallToActionContainer'),
    loading: NoLoading
  }),
  Collapsible: Loadable({
    loader: () => import('../components/collapsible/CollapsibleComponent'),
    loading: NoLoading
  }),
  ErrorMessage: Loadable({
    loader: () => import('../components/error/ErrorMessage'),
    loading: NoLoading
  }),
  ExpandableImageBox: Loadable({
    loader: () => import('../components/imageBox/ExpandableImageBoxContainer'),
    loading: NoLoading
  }),
  Flexbox: Loadable({
    loader: () => import('../components/flexbox/FlexboxComponent'),
    loading: NoLoading
  }),
  Form: Loadable({
    loader: () => import('../components/form/FormContainer'),
    loading: Loading
  }),
  Grid: Loadable({
    loader: () => import('../components/grid/GridContainer'),
    loading: Loading
  }),
  Icon: Loadable({
    loader: () => import('../components/icons/IconComponent'),
    loading: NoLoading
  }),
  IconGrid: Loadable({
    loader: () => import('../components/grid/IconGridContainer'),
    loading: NoLoading
  }),
  ImageCard: Loadable({
    loader: () => import('../components/card/ImageCardContainer'),
    loading: Loading
  }),
  InteractiveGrid: Loadable({
    loader: () => import('../components/interactiveGrid/InteractiveGridContainer'),
    loading: NoLoading
  }),
  InteractiveGridElements: Loadable({
    loader: () => import('../components/interactiveGrid/InteractiveGridElementsContainer'),
    loading: NoLoading
  }),
  InteractiveGridOverlay: Loadable({
    loader: () => import('../components/interactiveGrid/InteractiveGridOverlayContainer'),
    loading: NoLoading
  }),
  Intro: Loadable({
    loader: () => import('../components/intro/IntroComponent'),
    loading: Loading
  }),
  Markdown: Loadable({
    loader: () => import('../components/markDown/MarkDownComponent'),
    loading: NoLoading
  }),
  ModalButton: Loadable({
    loader: () => import('../components/modalButton/ModalButtonContainer'),
    loading: NoLoading
  }),
  PreloadLocations: Loadable({
    loader: () => import('../components/preloadLocations/PreloadLocationsContainer'),
    loading: NoLoading
  }),
  ResponsiveImage: Loadable({
    loader: () => import('../components/responsiveImage/ResponsiveImageComponent'),
    loading: NoLoading
  }),
  ScrollIndicator: Loadable({
    loader: () => import('../components/scrollIndicator/ScrollIndicatorComponent'),
    loading: Loading
  }),
  Section: Loadable({
    loader: () => import('../components/section/SectionContainer'),
    loading: NoLoading
  }),
  Slider: Loadable({
    loader: () => import('../components/slider/ScrollableSliderContainer'),
    loading: NoLoading
  }),
  Subsection: Loadable({
    loader: () => import('../components/section/SubsectionContainer'),
    loading: NoLoading
  }),
  TagGallery: Loadable({
    loader: () => import('../components/tagGallery/TagGalleryContainer'),
    loading: NoLoading
  }),
  UserFeedback: Loadable({
    loader: () => import('../components/userFeedback/UserFeedbackComponent'),
    loading: NoLoading
  }),
  Switch: Loadable({
    loader: () => import('../components/switch/SwitchComponent'),
    loading: NoLoading
  })
}

export default componentMap
