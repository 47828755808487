// @flow
import React from 'react'
import MediaQuery from 'react-responsive'
import { StyleSheet, css } from 'aphrodite/no-important'
import { boxes, mediaBreakpointDown, sizes } from 'conversional-theme'

import { ICONS } from '../icons/IconList'
import Icon from '../icons/Icon'

// Todo: Refactor as IconButtons

type IconButtonProps = {
  close: () => void,
  icon?: string,
  backgroundColor?: string,
  backgroundHoverColor?: string,
  color?: string,
  hoverColor?: string,
  theme: {
    colors: {}
  }
}

class IconButton extends React.PureComponent<IconButtonProps, null> {
  render() {
    const theme = this.props.theme
    const icon = this.props.icon || ICONS.close
    const color = this.props.color || theme.colors.light
    const hoverColor = this.props.hoverColor || theme.colors.text
    const backgroundColor = this.props.backgroundColor || theme.colors.backgroundDark
    const backgroundHoverColor = this.props.backgroundHoverColor || theme.colors.menuElement

    return (
      <div
        className={
          'journey-close-button ' +
          css(Styles(backgroundColor, backgroundHoverColor, hoverColor).close)
        }
        onClick={this.props.close}
      >
        <MediaQuery minWidth={boxes.lg.min}>
          <Icon theme={theme} hoverColor={hoverColor} icon={icon} size={'24px'} color={color} />
        </MediaQuery>
        <MediaQuery maxWidth={boxes.md.max}>
          <Icon theme={theme} hoverColor={hoverColor} icon={icon} size={'16px'} color={color} />
        </MediaQuery>
      </div>
    )
  }
}

const Styles = (backgroundColor, backgroundHoverColor, hoverColor) =>
  StyleSheet.create({
    close: {
      borderRadius: '50%',
      backgroundColor: backgroundColor,
      cursor: 'pointer',
      width: '50px',
      height: '50px',
      color: '#fff',
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      [mediaBreakpointDown(sizes.md)]: {
        width: '30px',
        height: '30px'
      },
      ':hover': {
        backgroundColor: backgroundHoverColor
      },
      ':hover path': {
        fill: hoverColor
      }
    }
  })

export default IconButton
