// @flow
import * as React from 'react';
import { useState, useEffect } from 'react';
import storage from 'conversional-persistent-storage';
import { exitIntentConfig } from 'conversional-environment';

import onLeaveSite from '../monitorLeaveSite';
import ExitIntentComponent from './ExitIntentComponent';

type IProps = {
  // callbacks
  onClick: () => {},
  onClose: () => {},
  onTrigger: () => {},
  onOpen: () => {},

  // trigger props that control exit intent sensitivity
  delay: number,

  // other props
  image?: string,
  backdrop?: string,
  theme: {},
  show: boolean,
  children: React.Node
};

const ExitIntentContainer = (props: IProps) => {
  const [show, setShow] = useState(props.show);
  const [showModal, setShowModal] = useState(false);

  // initiate esc trigger
  useEffect(() => {
    const timeout = setTimeout(() => onLeaveSite(trigger), props.delay);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(
    () => {
      if (show) {
        trigger();
        window.addEventListener('keydown', closeOnEsc);
        return () => {
          window.removeEventListener('keydown', closeOnEsc);
        };
      }
    },
    [show]
  );

  const closeOnEsc = evt => {
    if (!show) {
      return;
    }

    evt = evt || window.event;
    let isEscape = false;
    if ('key' in evt) {
      isEscape = evt.key === 'Escape' || evt.key === 'Esc';
    } else {
      isEscape = evt.keyCode === 27;
    }
    if (isEscape) {
      close();
    }
  };

  const exitIntentShouldShow = () => {
    if (storage.getItem(exitIntentConfig.HIDE_FLAG)) {
      return false;
    }
    return true;
  };

  const trigger = () => {
    if (exitIntentShouldShow()) {
      storage.setItem(exitIntentConfig.HIDE_FLAG, true);
      props.onTrigger();
      setShow(true);
      setTimeout(() => setShowModal(true), 1);
    }
  };

  const close = () => {
    setShowModal(false); // will automatically trigger hide after exit animation through PoseJS
  };

  const hide = () => {
    props.onClose();
    setShow(false);
  };

  const open = e => {
    e.stopPropagation();
    hide();
    props.onOpen();
  };

  const newChildren = React.Children.map(props.children, child =>
    React.cloneElement(child, {
      closeModal: close
    })
  );

  return (
    <React.Fragment>
      {show && (
        <ExitIntentComponent
          backdrop={props.backdrop}
          close={close}
          display={props.image}
          hide={hide}
          onOpen={open}
          showModal={showModal}
          theme={props.theme}
        >
          {newChildren}
        </ExitIntentComponent>
      )}
    </React.Fragment>
  );
};

ExitIntentContainer.defaultProps = {
  onClick: () => {},
  onOpen: () => {},
  onClose: () => {},
  onTrigger: () => {},

  show: false,
  backdrop: 'rgba(142,142,142,0.75)',
  id: '',
  delay: 2000
};

export default ExitIntentContainer;
