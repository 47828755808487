import split from 'lodash/split'
import first from 'lodash.first'
import last from 'lodash.last'

export const parseQueryString = () =>
  window.location.search
    .substr(1)
    .split('&')
    .reduce((obj, item) => {
      let parts = item.split('=')
      obj[parts[0]] = parts[1]

      return obj
    }, {})

export const isRegex = str => /^\/.+\/(s|i|m|g)*$/.test(str)

export const patternMatchesUrl = (pattern, url = window.location.href) => {
  if (isRegex(pattern)) {
    const parts = pattern.toString().split(/\//)
    const flags = last(parts)
    const searchString = pattern.substring(1, pattern.length - 2)
    return new RegExp(searchString, flags).test(url)
  }
  return pattern === url
}

/**
 * Removes query and fragment component and returns the rest of URL
 * @param {string} url a string matching to the URL syntax as defined here: https://en.wikipedia.org/wiki/URL
 */
export const getUrlWithoutParams = (url = '') => {
  return first(split(url, '?'))
}

export const isUrlAllowed = (blackList, whiteList, url = window.location.href) => {
  const urlWithoutParam = getUrlWithoutParams(url)
  if (Array.isArray(whiteList) && whiteList.length) {
    if (whiteList.map(pattern => patternMatchesUrl(pattern, urlWithoutParam)).some(Boolean)) {
      return true
    }
    return false
  }
  if (Array.isArray(blackList)) {
    if (blackList.map(pattern => patternMatchesUrl(pattern, urlWithoutParam)).some(Boolean)) {
      return false
    }
  }
  return true
}

window.patternMatchesUrl = patternMatchesUrl
