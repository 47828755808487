// @flow
import React from 'react';

import { captureException } from 'conversional-exceptions';

/**
 * Error Boundary
 * React components to catch JS errors anywhere in their child component tree, log those errors, etc.
 * Error boundaries catch errors during rendering, in lifecycle methods, and in constructors of the whole tree below them.
 * See: https://reactjs.org/docs/error-boundaries.html
 *
 * @usage
 * Use static getDerivedStateFromError() to render a fallback UI after an error has been thrown.
 * Use componentDidCatch() to log error information.
 **/
type IProps = {
  children: React.Node
};

export default class ErrorBoundary extends React.Component<IProps> {
  componentDidCatch(stack, info) {
    // Print in the consule, but not in 'error' mode
    // Do not show an error in the UI
 // eslint-disable-line no-console
    captureException(stack, info);
  }

  render() {
    return this.props.children;
  }
}
