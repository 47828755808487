import 'idempotent-babel-polyfill';
import './shims/classList';
import './shims/closest';
import './shims/prepend';
import { makeIframe, runWhenConfigAvailable } from './iframe';
import { receiveMessage, sendMessage } from './messages';
import { enableRtStyleCheck } from './rtStyleChecks';
import RpcManager from './widget-rpc/rpcManager';
var initConversionalWidget = function () {
    var env = process.env.REACT_APP_JOURNEY_ENV;
    var urls = {
        development: 'http://localhost:3200',
        testing: 'https://dev-mariner-181313.appspot.com',
        staging: 'https://conversional-staging.appspot.com',
        production: 'https://conversional-production.appspot.com'
    };
    var run = function () {
        var url = urls[env];
        // origin is needed for window messaging
        var origin = window.location.origin;
        if (!window._conversionalWidget) {
            window._conversionalWidget = { origin: origin };
        }
        else {
            Object.assign(window._conversionalWidget, { origin: origin });
        }
        window.conversionalClient = new RpcManager({ sendRpc: sendMessage });
        makeIframe(url);
        enableRtStyleCheck();
        window.addEventListener('message', receiveMessage(url), false);
    };
    run();
};
runWhenConfigAvailable(initConversionalWidget);
