// @flow
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import RatingBase from './RatingBase';

type RatingCardProps = {
  img: string,
  title: string,
  ratings: Array<{ name: string, stars: number }>,
  theme: {}
};

/**
 * RatingCardComponent
 * Purpose      Wraps the RatingBaseComponent with card styles.
 * Responsive   No
 * Nestable     False
 * Base         False (Base components are only used by other components, never directly)
 */
function RatingCardComponent({ img, title, ratings, theme }: RatingCardProps) {
  return (
    <div className={css(Styles(theme).card)}>
      <div className={css(DynamicStyles(img).imageBox)} />
      <RatingBase title={title} ratings={ratings} theme={theme} />
    </div>
  );
}

const Styles = theme =>
  StyleSheet.create({
    card: {
      backgroundColor: theme.colors.backgroundLight,
      display: 'inline-block',
      marginRight: theme.spaces.desktop.s
    }
  });

const DynamicStyles = img =>
  StyleSheet.create({
    imageBox: {
      width: '226px',
      height: '172px',
      backgroundImage: `url(${img})`,
      backgroundPosition: 'center center',
      backgroundSize: 'cover'
    }
  });

export default RatingCardComponent;
