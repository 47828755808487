//@flow
import * as React from 'react'
import { connect } from 'react-redux'
import Form from 'react-jsonschema-form'
import { createStructuredSelector } from 'reselect'
import { StyleSheet } from 'aphrodite/no-important'
import { publish, addCallbacks } from 'conversional-callbacks'
import {
  ResultActions,
  TrackingActions,
  Events,
  JourneySelectors,
  CommonSelectors,
  ResultSelectors
} from 'conversional-journey'

import SubmitButton from './SubmitButtonComponent'
import Loading from '../loading/Loading'
import transformErrors from './ErrorList'
import FormComponent from './FormComponent'
import ErrorComponent from './ErrorComponent'
import SuccessComponent from './SuccessComponent'

import MuiSelect from './formFields/MuiSelect'
import IconInput from './formFields/IconInput'
import SelectListInput from './formFields/SelectListInput'
import SelectListInputWithLocations from './formFields/SelectListInputWithLocations'
import TimekitInput from './formFields/TimekitInput'

type IProps = {
  form: {},
  ui: {},
  idPrefix?: string,
  alias?: string,
  styles: {
    general?: {},
    mobile?: {},
    desktop?: {}
  },
  componentTitle: string,
  endpoint: {
    domain: string,
    controller?: string
  },
  theme: any,
  callbacks: any,
  uniqueId: string,
  submitView: any,
  closureFields?: Array<>,
  hideSubmit: boolean,
  className?: string,
  dispatch: () => {}
}

type IState = {
  error: string,
  formData: {},
  loading: boolean,
  submitted: boolean
}

const widgets = {
  iconInput: IconInput,
  muiSelect: MuiSelect,
  selectListInput: SelectListInput,
  selectListInputWithLocations: SelectListInputWithLocations,
  timekitInput: TimekitInput
}

const nameValidation =
  "^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'+-]+$"

const customFormats = {
  name: nameValidation
}

/**
 * Purpose      JSON-based form that is either submitted to a Conversional endpoint (through endpoint/controller or to an external domain)
 *              For details on form config: https://github.com/mozilla-services/react-jsonschema-form
 * Nestable     False
 * Base         True
 */
class FormContainer extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      formData: {},
      loading: false,
      submitted: false
    }
  }

  componentDidMount = () => {
    const supportedCallbacks = {
      updateFormDataCallback: this.updateFormData
    }

    addCallbacks(this, this.props.callbacks || {}, supportedCallbacks)
  }

  updateFormData = (_event, data) => {
    this.setState(state => ({
      formData: {
        ...state.formData,
        ...data
      }
    }))
  }

  onChange = data => {
    publish('FormContainer.changed', data.formData, this.props.uniqueId)
  }

  onFocus = event => {
    const id = typeof event === 'string' ? event : event.target.id
    publish(`FormContainer.field.focus.${id}`, id)
  }

  onBlur = event => {
    const id = typeof event === 'string' ? event : event.target.id
    publish(`FormContainer.field.blur.${id}`, id)
  }

  onSubmit = async data => {
    this.props.dispatch(
      TrackingActions.event({
        ...Events.FORM_SUBMIT,
        label: this.props.componentTitle || 'untitled'
      })
    )
    this.setState({ formData: data.formData })
    this.setState({ loading: true })

    if (this.props.endpoint) {
      const {
        endpoint: { controller }
      } = this.props

      try {
        const { formData } = data
        formData.alias = this.props.alias
        if (controller) {
          await this.props.dispatch(ResultActions.sendForm(controller, formData))
        }
      } catch (error) {
        if (this.hasUserInputError(error)) {
          return this.setError('Eingabe fehlerhaft')
        }
        return this.setError('Daten konnten nicht übermittelt werden.')
      }
    }

    const fields = []

    // eslint-disable-next-line no-unused-vars
    for (const field in data.formData) {
      fields.push({
        name: field,
        value: data.formData[field]
      })
    }

    publish('FormContainer.submitted', { fields }, this.props.uniqueId)

    this.setState({ loading: false, submitted: true })
  }

  setError = (message: string) => {
    setTimeout(() => this.setState({ error: message, loading: false }), 200)
  }

  hasUserInputError(error) {
    return error.response && error.response.status && error.response.status === 400
  }

  addDefaultValues(schema) {
    // eslint-disable-next-line no-unused-vars
    if (!this.props.closureFields) {
      return schema
    }

    for (const closureField of this.props.closureFields) {
      if (schema.properties[closureField.name]) {
        schema.properties[closureField.name].default = closureField.value
      }
    }
    return schema
  }

  render() {
    if (this.state.submitted) {
      if (this.props.submitView === false) {
        return <div />
      }
      return <SuccessComponent theme={this.props.theme}>Anfrage gesendet.</SuccessComponent>
    }

    const { hideSubmit, className, idPrefix = 'root' } = this.props
    return (
      <FormComponent styles={this.props.styles} theme={this.props.theme}>
        <style>
          {`
          .conversional-input {
            alignSelf: center;
            text-align:center;
            margin: 4px !important;
            background: transparent;
          },
          .conversional-checkbox {
            min-width: 0;
          }

          .conversional-input input {
            border: none;
            borderBottom: 1px solid #eee;
            textAlign: center;
            width: 350px;
            backgroundColor: #fff;
            minHeight: 25px;
            fontSize: 1rem;
            fontWeight: 100;
            outline: none;
            maxWidth: 100%;
            minWidth: 150px;
            padding: 6px;
          }
          @media(max-width: 700px) {
            .conversional-input input {
              width: 260px;
            }
          }
          .conversional-input::focus {
            borderColor: ${this.props.theme.colors.brand} !important,
            transition: all 0.5s ease;
          }
          .conversional-input input::placeholder {
            color: #333;
          }
          `}
        </style>
        <Form
          className={className}
          customFormats={customFormats}
          idPrefix={idPrefix}
          formData={this.state.formData}
          transformErrors={transformErrors}
          schema={this.addDefaultValues(this.props.form)}
          showErrorList={false}
          uiSchema={this.props.ui}
          onSubmit={this.onSubmit}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          widgets={widgets}
        >
          {this.state.loading ? (
            <Loading message="" />
          ) : (
            <SubmitButton
              theme={this.props.theme}
              styles={Styles().primaryButton}
              hideSubmit={hideSubmit}
            >
              {this.props.componentTitle || 'Abschicken'}
            </SubmitButton>
          )}
          {this.state.error && (
            <ErrorComponent theme={this.props.theme}>{this.state.error}</ErrorComponent>
          )}
        </Form>
      </FormComponent>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  alias: ResultSelectors.selectCustomerAlias,
  theme: CommonSelectors.selectTheme,
  closureFields: JourneySelectors.selectClosureFields
})

const Styles = () =>
  StyleSheet.create({
    primaryButton: {
      borderRadius: 6
    }
  })

export default connect(mapStateToProps)(FormContainer)
