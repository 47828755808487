import { find } from 'lodash';

// Takes a location list { locationKey: locationName }
// and turns it into [{ key: locationKey, name: locationName }]
export const locationsToArray = (locationsArray, list) => {
  for (const key in list) {
    // add list.data(?)
    const exists = find(locationsArray, { key });
    if (!exists) {
      locationsArray.push({ key, name: list[key] });
    }
  }
  return locationsArray;
};
