// @flow
import * as React from 'react'
import CallToActionComponent from './CallToActionComponent'

type IProps = {
  buttonType: 'primary' | 'outline',
  url: string,
  linkText: string,
  title: string,
  img: string,
  spots?: Array<React.Node> | null,
  styles?: {
    desktop?: {},
    mobile?: {},
    general?: {}
  },
  theme: {}
}

class CallToActionContainer extends React.PureComponent<IProps> {
  render() {
    return <CallToActionComponent {...this.props} />
  }
}

export default CallToActionContainer
