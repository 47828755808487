import React from 'react';
import classNames from 'classnames';

const ArrowRight = (props: any) => (
  <svg
    className={classNames('arrow_big', props.className)}
    style={{ top: '10px', height: '1rem', width: '50px', ...props.style }}
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 7.6 18.73"
  >
    <polyline points="1 17.73 6.6 9.3 1.14 1" />
  </svg>
);

export default ArrowRight;
