//@flow
import * as React from 'react'
import { StyleSheet } from 'aphrodite/no-important'

import { text } from 'conversional-theme'

import BaseComponent from '../base/BaseComponent'

type IProps = {
  styles: {
    general?: {},
    desktop?: {},
    mobile?: {}
  },
  children?: Array<React.Node>,
  theme: {}
}

class FormComponent extends React.PureComponent<IProps, null> {
  render() {
    return (
      <BaseComponent
        styles={Styles(this.props.theme).formContainer}
        injectedStyles={this.props.styles}
      >
        {this.props.children}
      </BaseComponent>
    )
  }
}

const Styles = theme => {
  const inputStyles = {
    background: 'transparent',
    border: 'none',
    borderBottom: `1px solid ${theme.colors.border}`,
    backgroundColor: theme.colors.light,
    fontSize: theme.typo.sizes.m,
    fontFamily: theme.typo.fonts.regular,
    minHeight: 25,
    outline: 'none',
    marginBottom: theme.spaces.desktop.xs,
    maxWidth: '100%',
    ':focus': {
      borderColor: theme.colors.brand,
      transition: 'all 0.5s ease'
    },
    '::placeholder': {
      color: theme.colors.textLight
    }
  }

  return StyleSheet.create({
    formContainer: {
      fontFamily: theme.typo.fonts.regular,
      ':nth-child(1n) .field-radio-group': {
        textAlign: 'left'
      },
      ':nth-child(1n) input[type="text"]': {
        ...inputStyles
      },
      ':nth-child(1n) input[type="tel"]': {
        ...inputStyles
      },
      ':nth-child(1n) .field-description': {
        marginBottom: theme.spaces.desktop.m
      },
      ':nth-child(1n) legend': {
        //display: 'none'
        ...text(theme).h4,
        marginBottom: 12
      },
      ':nth-child(1n) .error-detail': {
        color: theme.colors.error,
        listStyle: 'none',
        marginTop: 0,
        paddingLeft: 0
      },
      ':nth-child(1n) .control-label': {
        fontFamily: theme.typo.fonts.bold,
        marginTop: theme.spaces.desktop.xs
      },
      ':nth-child(1n) .form-group': {
        marginBottom: theme.spaces.desktop.m
      },
      ':nth-child(1n) fieldset': {
        outline: 'none',
        padding: 0,
        border: 'none',
        marginBottom: theme.spaces.desktop.m,
        minInlineSize: 'auto'
      }
    }
  })
}

export default FormComponent
