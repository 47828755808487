/**
 * Takes an object of keyed React components and returns a sorted array of named components.
 *
 * @param object: {string: <React.Node>}
 * @returns {<Array of <React.Node>>}
 */
const renderObjectOfComponents = function(object) {
  if (!object) return null;

  const componentList = Object.keys(object);
  componentList.sort();

  return componentList.map(component => {
    return object[component] || null;
  });
};

export default renderObjectOfComponents;
