import { createSelector } from 'reselect'
import { initialState, defaultJourneyValue } from '../reducers'
import { selectConfig } from '../../common/selectors'
import {
  selectIsClosurePath,
  selectIsQuestionnairePath,
  selectIsResultPath
} from '../../router/selectors'

export const selectSelectedJourneyId = state => (state.journey || initialState).selectedJourney

export const selectJourneys = state => state.journey || initialState

export const selectJourney = createSelector(
  selectJourneys,
  selectSelectedJourneyId,
  (journeys, selected) => journeys[selected] || defaultJourneyValue
)

export const selectJourneysLoaded = state => state.journeyLoaded || {}
export const selectJourneyLoaded = createSelector(
  selectJourneysLoaded,
  selectSelectedJourneyId,
  (loadedJourneys, selected) => Boolean(loadedJourneys[selected])
)

export const selectQuestionnaire = createSelector(selectJourney, journey => journey.questionnaire)
export const selectQuestions = createSelector(
  selectQuestionnaire,
  (questionnaire = {}) => questionnaire.questions || []
)

export const selectJourneyConfig = createSelector(selectJourney, journey => journey.config)

export const selectDefaultJourneyId = createSelector(selectConfig, config => config.questionnaireId)
export const selectDefaultJourney = createSelector(
  selectJourneys,
  selectDefaultJourneyId,
  (journeys, selected) => journeys[selected] || defaultJourneyValue
)

export const selectLauncher = createSelector(
  selectDefaultJourney,
  journey => journey.config.callToAction.loader || null
)

export const selectLauncherActions = createSelector(
  selectLauncher,
  launcher => (launcher || {}).actions || []
)

export const selectAdditionalJourneys = createSelector(
  selectLauncherActions,
  selectDefaultJourneyId,
  (actions, defaultJourney) =>
    actions.filter(action => action.type === 'startJourney' && action.payload.id !== defaultJourney)
)

export const selectCallToAction = createSelector(
  selectJourney,
  journey => journey.config.callToAction
)

export const selectExitIntentMobile = createSelector(
  selectJourney,
  journey => journey.config.callToAction.exitIntentMobile
)

export const selectClosureFields = createSelector(
  selectQuestionnaire,
  questionnaire => questionnaire && questionnaire.closure.fields
)

export const selectCanGoBackFromQuestionnaire = createSelector(
  selectQuestions,
  selectIsQuestionnairePath,
  (questions, isQuestionnairePath) =>
    !isQuestionnairePath || // allow if not on questionnaire page, this is to support follow up checks
    (isQuestionnairePath && Boolean(questions.length)) // if on closure page and has questions
)

export const selectCanGoBackFromClosure = createSelector(
  selectQuestions,
  selectIsClosurePath,
  (questions, isClosurePath) =>
    !isClosurePath || // allow if not on closure page
    (isClosurePath && Boolean(questions.length)) // if on closure page and has questions
)

export const selectCanGoBackFromResult = createSelector(
  selectCanGoBackFromClosure,
  selectIsResultPath,
  (isClosurePath, isResultPath) => isClosurePath && !isResultPath // if on closure page and has questions
)

export const selectCanGoBackward = createSelector(
  selectCanGoBackFromQuestionnaire,
  selectCanGoBackFromResult,
  (canGoBackFromQuestionnaire, canGoBackFromResult) =>
    canGoBackFromResult && canGoBackFromQuestionnaire
)
