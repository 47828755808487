// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { CommonSelectors } from 'conversional-journey'
import IconGridComponent from './IconGridComponent'

type IProps = {
  backgroundColor?: string,
  cols: number,
  componentKey: string,
  defaultIcon: string,
  iconColor: string,
  iconSize: string,
  list: Array<{ icon: string, label: string }>,
  listType: 'text' | 'icon',
  notResponsive?: boolean,
  title?: string,
  subtitle?: string,
  iconRowStyle: {},
  styles: {},
  theme: {}
}

class IconGridContainer extends React.PureComponent<IProps> {
  render() {
    return <IconGridComponent {...this.props} />
  }
}

const mapStateToProps = createStructuredSelector({
  theme: CommonSelectors.selectTheme
})

export default connect(mapStateToProps)(IconGridContainer)
