// @flow
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { sizes, mediaBreakpointUp, mediaBreakpointDown } from 'conversional-theme';

type Props = {
  img: string,
  children?: React.Node
};

// Todo: change how h1 is created
export class ImageBox extends React.PureComponent<Props, null> {
  render() {
    const { img, children } = this.props;
    return <div className={css(imageStyles({ img }).box)}>{children}</div>;
  }
}

const imageStyles = ({ img }) =>
  StyleSheet.create({
    box: {
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      [mediaBreakpointUp(sizes.md)]: {
        color: '#FFF',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        textAlign: 'center',
        background: `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),url(${img})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover'
      },
      [mediaBreakpointDown(sizes.sm)]: {
        justifyContent: 'start',
        alignItems: 'center'
      }
    }
  });

export default ImageBox;
