// @flow
import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { sizes, mediaBreakpointDown } from 'conversional-theme';

type GridProps = {
  cols: number,
  rows: number,
  notResponsive?: boolean,
  children?: Array<React.Node>,
  mobileStyles: boolean,
  gridRows?: string,
  gridColumns?: string,
  autoAdjustGrid: boolean,
  theme: {}
};

/**
 * GridBase
 * Purpose      Organize child components in a grid structure. Use gridColumns/rows optionally for un-even rows/columns
 * Responsive   Optionally collapses to a flexbox list of items.
 * Nestable     True
 * Base         Yes
 */
function GridBase({
  cols,
  rows,
  children,
  notResponsive,
  gridColumns,
  gridRows,
  mobileStyles,
  autoAdjustGrid,
  theme
}: GridProps) {
  return (
    <div
      className={css(
        autoAdjustGrid && renderGridStyles(cols, children, theme),
        Styles({ cols, rows, gridColumns, gridRows, children }).grid,
        !notResponsive && MobileStyles(theme).flex,
        mobileStyles && MobileStyles(theme).borderList
      )}
    >
      {children}
    </div>
  );
}

GridBase.defaultProps = {
  mobileStyles: true,
  autoAdjustGrid: true
};

const renderGridStyles = (cols, children, theme) => {
  return children.map((c, index) => {
    return gridStyles(index, cols, theme).gridContainer;
  });
};

const gridStyles = (index, cols, theme) =>
  StyleSheet.create({
    gridContainer: {
      [`:nth-child(1n) > *:nth-child(${index + 1})`]: {
        marginLeft: index % cols > 0 ? theme.spaces.desktop.s : '0px',
        marginTop: Math.ceil((index + 1) / cols) > 1 ? theme.spaces.desktop.s : '0px',
        lineHeight: `inherit;-ms-grid-column: ${(index % cols) + 1};-ms-grid-row: ${Math.ceil(
          (index + 1) / cols
        )}`
      },
      [mediaBreakpointDown(sizes.sm)]: {
        [`:nth-child(1n) > *:nth-child(${index + 1})`]: {
          marginLeft: 0
        }
      }
    }
  });

const Styles = props => {
  return StyleSheet.create({
    grid: {
      gridTemplateColumns: props.gridColumns || '1fr '.repeat(props.cols),
      gridTemplateRows: props.gridRows || '1fr '.repeat(props.rows),

      MsGridColumns: props.gridColumns || '1fr '.repeat(props.cols),
      MsGridRows: props.gridRows || '1fr '.repeat(props.rows),

      display: 'grid;display:-ms-grid;', // Hack for ie11
      position: 'relative'
    }
  });
};

const MobileStyles = theme =>
  StyleSheet.create({
    flex: {
      [mediaBreakpointDown(sizes.sm)]: {
        display: 'flex',
        flexDirection: 'column'
      }
    },
    borderList: {
      [mediaBreakpointDown(sizes.sm)]: {
        ':nth-child(1n) > *': {
          // Hacky to circumvent shortcoming of aphrodite
          borderBottom: `1px solid ${theme.colors.border}`
        }
      }
    }
  });

export default GridBase;
