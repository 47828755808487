// @flow
import React from 'react'
import { css, StyleSheet } from 'aphrodite/no-important'

import renderObjectOfComponents from '../../utilities/components/renderObjectOfComponents'
import ScrollIndicator from './ScrollIndicator'
import SectionTracking from '../sectionTracking/SectionTrackingComponent'

const ScrollIndicatorComponent = (props: any) => {
  const containerStyle = css(Styles().container)
  const wrapperStyle = css(Styles().wrapper)
  return (
    <div className={containerStyle}>
      <ScrollIndicator theme={props.theme} />
      <SectionTracking />
      <div className={wrapperStyle}>{renderObjectOfComponents(props.spots)}</div>
    </div>
  )
}

export default ScrollIndicatorComponent

export const Styles = () =>
  StyleSheet.create({
    container: {
      boxSizing: 'border-box',
      overflow: 'hidden'
    }
  })
