//@flow
import * as React from 'react'
import { css, StyleSheet } from 'aphrodite/no-important'
import MediaQuery from 'react-responsive'

import { IconButton, Primary } from 'conversional-components'
import { boxes } from 'conversional-theme'

type IProps = {
  open: () => {},
  close: () => {},
  activate: () => {},
  leadText: string,
  buttonText: string,
  displayOnScroll: boolean,
  theme: any,
  style: {}
}

function ExitIntentComponent({
  leadText,
  buttonText,
  displayOnScroll,
  open,
  close,
  theme,
  style
}: IProps) {
  return (
    <MediaQuery maxWidth={boxes.sm.max}>
      <div style={HeadroomStyles(displayOnScroll, style)}>
        <div className={css(Styles(theme).container)}>
          <p className={css(Styles(theme).content)}>{leadText}</p>
          <Primary onClick={open} theme={theme} customStyles={Styles(theme).button}>
            {buttonText}
          </Primary>
          <div className={css(Styles(theme).close)}>
            <IconButton
              close={close}
              hoverColor={theme.colors.brand}
              color={theme.colors.text}
              backgroundColor="transparent"
            />
          </div>
        </div>
      </div>
    </MediaQuery>
  )
}

const HeadroomStyles = (display, style) => ({
  backgroundColor: '#EEE',
  justifyContent: 'center',
  padding: 6,
  paddingLeft: 18,
  paddingRight: 18,
  boxShadow: display ? '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' : 'none',
  ...style
})

const Styles = theme =>
  StyleSheet.create({
    container: {
      alignItems: 'center',
      color: theme.colors.text,
      display: 'flex',
      flexDirection: 'row',
      ':nth-child(1n) > *': {
        fontSize: '14px !important'
      },
      width: '100%',
      justifyContent: 'space-between'
    },
    content: {
      fontFamily: theme.typo.fonts.regular,
      marginBottom: 0,
      marginTop: 0
    },
    button: {
      width: 'auto !important',
      padding: '6 !important',
      minWidth: 150,
      height: 50
    },
    close: {
      position: 'absolute',
      top: -3,
      right: 6,
      width: '20px !important',
      cursor: 'pointer'
    }
  })

export default ExitIntentComponent
