// @flow
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { sizes, mediaBreakpointUp, mediaBreakpointDown } from 'conversional-theme';
import IconGridComponent from '../grid/IconGridComponent';
import BaseComponent from '../base/BaseComponent';

type IconListCardProps = {
  img: string,
  cols: number,
  rows: number,
  title?: string,
  footer?: string,
  notResponsive?: boolean,
  list: Array<string>,
  iconColor?: string,
  backgroundColor?: string,
  label: string,
  listType: string,
  styles: {},
  theme: {}
};

/**
 * IconListCardComponent
 * Purpose      Wraps an IconGrid with a card/imagebox.
 * Responsive   No
 * Nestable     False
 * Base         False (Base components are only used by other components, never directly)
 * // Todo: reconsider: maybe do the nesting per configuration? Is this a base component?
 */
function IconListCardComponent({
  img,
  title,
  label,
  listType,
  footer,
  cols,
  iconColor,
  backgroundColor,
  list,
  styles,
  theme
}: IconListCardProps) {
  return (
    <BaseComponent injectedStyles={styles} styles={Styles(theme).card}>
      <div className={css(DynamicStyles(img).imageBox)} />
      <span className={css(Styles(theme).cardLabel)}>{label}</span>
      <div className={css(Styles(theme).cardContent)}>
        <div className={css(Styles(theme).inner)}>
          <IconGridComponent
            title={title}
            footer={footer}
            cols={cols}
            iconColor={iconColor}
            list={list}
            listType={listType}
            backgroundColor={backgroundColor}
            theme={theme}
          />
        </div>
      </div>
    </BaseComponent>
  );
}

const Styles = theme =>
  StyleSheet.create({
    card: {
      backgroundColor: theme.colors.backgroundLight,
      display: 'inline-block',
      marginRight: theme.spaces.desktop.s
    },
    cardContent: {
      maxWidth: '226px',
      whiteSpace: 'normal'
    },
    inner: {
      [mediaBreakpointUp(sizes.md)]: {
        padding: '0 18px 18px 18px'
      },
      [mediaBreakpointDown(sizes.sm)]: {
        padding: '0 12px 12px 12px'
      }
    },
    cardLabel: {
      padding: '18px 18px 0 18px',
      display: 'block',
      fontFamily: theme.typo.fonts.regular,
      [mediaBreakpointUp(sizes.md)]: {
        paddingBottom: '18px',
        fontFamily: theme.typo.fonts.bold,
        textTransform: 'uppercase'
      }
    }
  });

const DynamicStyles = img =>
  StyleSheet.create({
    imageBox: {
      width: '226px',
      height: '172px',
      backgroundImage: `url(${img})`,
      backgroundPosition: 'center center',
      backgroundSize: 'cover'
    }
  });

export default IconListCardComponent;
