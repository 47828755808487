//@flow
import * as React from 'react'
import ReactModal from 'react-modal'
import { css, StyleSheet } from 'aphrodite/no-important'
import { sizes, mediaBreakpointDown, mediaBreakpointUp } from 'conversional-theme'
import { ComponentStyles } from 'conversional-theme'
// todo: add modal animation

import IconButton from '../buttons/IconButtonContainer'

type IProps = {
  children: React.Element<*>,
  closeModal: () => {},
  showModal: boolean,
  theme: {},
  styles: {}
}

class ModalComponent extends React.PureComponent<IProps, null> {
  render() {
    const { closeModal, showModal, children, theme, styles } = this.props
    const className = css(Styles(theme).Content, ComponentStyles(styles))

    // conversional-journey-globals ensures that global styles are also applied to out-of-dom modals
    return (
      <React.Fragment>
        <ReactModal
          ariaHideApp={false}
          closeTimeoutMS={100}
          isOpen={showModal}
          onRequestClose={closeModal}
          style={ModalStyles}
          className={className}
        >
          <div
            className="conversional-journey-globals"
            style={{ height: '100%', overflow: 'scroll' }}
          >
            {children}
            <div className={css(Styles(theme).close)}>
              <IconButton close={closeModal} />
            </div>
          </div>
        </ReactModal>
      </React.Fragment>
    )
  }
}

const ModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.80)',
    zIndex: 99999999
  }
}

const Styles = theme =>
  StyleSheet.create({
    Content: {
      overflow: 'hidden',
      position: 'absolute',
      backgroundColor: theme.colors.light,
      color: theme.colors.text,
      border: 'none',
      borderRadius: 0,
      [mediaBreakpointDown(sizes.sm)]: {
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        padding: theme.spaces.mobile.mL,
        top: 0,
        left: 0
      },
      [mediaBreakpointUp(sizes.md)]: {
        left: '50%',
        marginRight: '-50%',
        maxHeight: '90%',
        maxWidth: 700,
        minHeight: 200,
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        padding: theme.spaces.desktop.m
      }
    },
    close: {
      position: 'absolute',
      zIndex: 999,
      top: `${theme.spaces.desktop.s}`,
      right: `${theme.spaces.desktop.s}`,
      [mediaBreakpointDown(sizes.sm)]: {
        top: `${theme.spaces.mobile.m}`,
        right: `${theme.spaces.mobile.m}`
      }
    }
  })

export default ModalComponent
