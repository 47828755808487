import { trackingConfig } from 'conversional-environment';
import { getTracker } from 'conversional-analytics';
import store from 'conversional-persistent-storage';
import { enableExitIntentMobile, enableExitIntent } from './exitIntent';
import { getJourneyId, getNoCookies } from './iframe';
import { applyStylesToHost } from "./parent";
import { updateHostInfo } from "./messages";
export var onFocusModal = function () {
    if (!window._conversionalWidget || !window._conversionalWidget.iframe || !window._conversionalWidget.iframe.focus) {
        return false;
    }
    window._conversionalWidget.iframe.focus();
};
export var onReady = function () {
    applyStylesToHost();
    updateHostInfo();
    // @ts-ignore
    trackingConfig.journeyId = getJourneyId();
    trackingConfig.prefix = 'conversional';
    getTracker(trackingConfig);
    // @ts-ignore
    getTracker().pageView('/', false, false);
    window.conversionalClient.setReady();
};
export var onTrackingEvent = function (payload) {
    var category = payload.category, action = payload.action, label = payload.label, hostTracking = payload.hostTracking;
    // @ts-ignore
    getTracker().event({ category: category, action: action, label: label, hostTracking: hostTracking, useAbTest: true, noCookies: getNoCookies() });
};
export var onRouteChange = function (payload) {
    var route = payload.route, hostTracking = payload.hostTracking, usePrefix = payload.usePrefix;
    // @ts-ignore
    getTracker().setEventPath(route);
    // @ts-ignore
    getTracker().pageView({ page: route, hostTracking: hostTracking, usePrefix: usePrefix, journeyId: getJourneyId() });
    updateHostInfo();
};
export var onStoreVar = function (payload) {
    var varName = payload.varName, varValue = payload.varValue;
    store.setItem(varName, varValue);
};
export var onHostTrackingConfig = function (payload) {
    // @ts-ignore
    getTracker().updateHostConfig(payload);
};
export var onEnableExitIntentMobile = function () {
    enableExitIntentMobile();
};
export var onEnableExitIntent = function () {
    enableExitIntent();
};
export var onReceiveRpcResponse = function (response) {
    window.conversionalClient.handleResponse(response);
};
