// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import { CommonSelectors } from 'conversional-journey'

import { useStyles } from './MuiSelect.styles'

type IProps = {
  schema: {
    list: Array<any>,
    placeholder: string
  },
  className?: string,
  disabled?: boolean,
  id: string,
  label: string,
  placeholder?: string,
  rawErrors: string[],
  required: boolean,
  theme: any,
  value?: string,

  onChange: (value: string) => {},
  onFocus: () => {},
  onBlur: () => {}
}
type Error = {
  isError: boolean,
  text: string
}

function MuiSelect(props: IProps) {
  const {
    theme,
    schema: { list = [], placeholder = '' }
  } = props

  const error: Error = {
    isError: props.rawErrors.length > 0,
    text: props.rawErrors.join()
  }

  const handleOnChange = value => {
    props.onChange(value)
  }

  const classes = useStyles(theme, error.isError)()
  return (
    <div key={props.id} className={classes.container}>
      <FormControl variant="outlined" className={classes.root} error={error.isError}>
        <InputLabel htmlFor={props.id} className={classes.label}>
          {placeholder}
        </InputLabel>
        <Select
          native
          disabled={props.disabled}
          value={props.value}
          onChange={event => handleOnChange(event.target.value)}
          required={props.required}
          label={props.label}
          InputProps={{
            id: props.id
          }}
        >
          <option aria-label="None" value="" />
          {list.map(entry => {
            return (
              <option key={entry} value={entry}>
                {entry}
              </option>
            )
          })}
        </Select>
        {error.isError && <FormHelperText>{error.text}</FormHelperText>}
      </FormControl>
    </div>
  )
}

MuiSelect.defaultProps = {
  rawErrors: []
}

const mapStateToProps = createStructuredSelector({
  theme: CommonSelectors.selectTheme
})

export default connect(mapStateToProps)(MuiSelect)
