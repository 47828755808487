export const PAGEVIEW = 'PAGEVIEW'
export const pageview = pageview => ({
  type: PAGEVIEW,
  pageview
})

export const EVENT = 'EVENT'
export const event = event => ({
  type: EVENT,
  event
})
