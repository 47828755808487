//@flow
export default function transformErrors(
  errors: Array<{ name: string, message: string, params?: { limit?: number } }>
) {
  return errors.map(error => {
    if (error.name === 'pattern') {
      error.message = 'Ungültige Eingabe';
    } else if (error.name === 'minLength') {
      error.message = `Mindestens ${error.params.limit} Zeichen`;
    } else if (error.name === 'maxLength') {
      error.message = `Maximale ${error.params.limit} Zeichen`;
    }
    return error;
  });
}
