import find from 'lodash.find';
import get from 'lodash.get';

const applyExternalConfiguration = config => {
  const nextConfig = { ...config };
  if (typeof window.conversionalWidget !== 'undefined') {
    const Closure = get(window.conversionalWidget, 'configuration.Questionnaire.Closure');
    if (Closure) {
      const { meta, ...fields } = Closure;
      const configFields = get(nextConfig, 'journey.questionnaire.closure.fields');
      if (configFields) {
        for (const field in fields) {
          const existingField = find(configFields, { name: field });
          if (existingField) {
            existingField.value = fields[field];
          }
        }
        nextConfig.journey.questionnaire.closure.meta = meta;
      }
    }
  }
  return nextConfig;
};

export default applyExternalConfiguration;
