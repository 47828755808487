// @flow
import * as React from 'react'
import { css, StyleSheet } from 'aphrodite/no-important'
import { areas, text } from 'conversional-theme'
import renderObjectOfComponents from '../../utilities/components/renderObjectOfComponents'
import BaseComponent from '../base/BaseComponent'

/**
 * Section
 * A section vertically seperates two distinct areas from each other.
 */

type ComponentProps = {
  componentKey: string,
  componentTitle: string
}

type SectionProps = {
  title?: string,
  spots: {
    [string]: (props: ComponentProps) => React.Node
  },
  styles: {},
  theme: {}
}

/**
 * SectionComponent
 * Purpose      Used to seperate sections e.g. through standard-spacing.
 * Responsive   Styling (space) adjusts to mobile.
 * Nestable     True
 * Base         False
 */
function SectionComponent({ title, spots, styles, theme }: SectionProps) {
  return (
    <BaseComponent injectedStyles={styles} htmlTag="section" styles={Styles(theme).section}>
      {title && <h2 className={css(Styles(theme).headline)}>{title}</h2>}
      {renderObjectOfComponents(spots)}
    </BaseComponent>
  )
}

SectionComponent.defaultProps = {
  spots: {}
}

const Styles = theme =>
  StyleSheet.create({
    section: {
      ...areas.section
    },
    headline: {
      ...text(theme).h2,
      textAlign: 'center',
      marginLeft: `${theme.spaces.mobile.m}`,
      marginRight: `${theme.spaces.mobile.m}`
    }
  })

export default SectionComponent
