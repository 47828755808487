import * as Actions from '../actions'

export const initialState = {
  loading: false
}

export const mergeByJourneyId = (state = {}, journeyId, data) => ({
  ...state,
  [journeyId]: {
    ...state[journeyId],
    ...data
  }
})

export function questionnaire(state = initialState, action) {
  switch (action.type) {
    case Actions.SEND_ANSWERS:
      return {
        ...mergeByJourneyId(state, action.journeyId, { showClosure: false }),
        loading: true
      }

    case Actions.SEND_ANSWERS_SUCCEEDED:
      return { ...state, loading: false }

    case Actions.SEND_ANSWERS_FAILED:
      return { ...state, loading: false, error: action.error }

    case Actions.SET_VIEW:
      return { ...state, currentView: action.params }

    case Actions.OPEN_CLOSURE:
      return mergeByJourneyId(state, action.payload.journeyId, { showClosure: true })

    case Actions.OPEN_QUESTIONNAIRE:
      return mergeByJourneyId(state, action.payload.journeyId, { showClosure: false })

    default:
      return state
  }
}

export function questions(state = [], action) {
  if (action.type === Actions.SAVE_ANSWERS) {
    return action.payload.questions
  }
  return state
}
