const production = {
  appointmentBookingApiKey: 'live_widget_key_ZP6zuISVKAiEMsbbIKjpVTXTYepIEH2g',
  baseUrl: 'https://api.conversional.de/',
  version: '2.0',
  apiKey: '',
  questionnaireId: '',
  mouseTracking: {
    iframe: '1599561',
    parent: '1599498'
  }
};

export default production;
