import { createRequest } from "./utils";
import WidgetClient from './widgetClient';
var RpcManager = /** @class */ (function () {
    function RpcManager(_a) {
        var _this = this;
        var _b = _a.isReady, isReady = _b === void 0 ? false : _b, _c = _a.queue, queue = _c === void 0 ? [] : _c, _d = _a.timeout, timeout = _d === void 0 ? 100 : _d, _e = _a.sendRpc, sendRpc = _e === void 0 ? function () { } : _e;
        this.requestTimeout = 10000;
        /**
         * Set isReady to true and call all the requests
         */
        this.setReady = function () {
            _this.isReady = true;
            _this.drainQueue(_this.queue);
            if (_this.requests.onReady) {
                _this.requests.onReady.resolve(_this.client);
            }
        };
        /**
         * Promise which is only resolved only once Widget RPC is ready.
         */
        this.onReady = function () { return _this.onReadyPromise; };
        /**
         * Send requests in the queue with a timeout specified by this.timeout
         */
        this.drainQueue = function (queue) {
            if (queue.length) {
                var request = queue[0], rest_1 = queue.slice(1);
                _this.sendRpc(request);
                setTimeout(function () {
                    _this.drainQueue(rest_1);
                }, _this.timeout);
            }
        };
        this.getRequestTimeout = function (id, reject) {
            return setTimeout(function () {
                // Reject request after 10,000 ms
                reject('Request Timeout');
                delete _this.requests[id];
            }, _this.requestTimeout);
        };
        /**
         * Creates an JSON RPC request, send it to widget window if RPCManager is ready, else put it in queue
         */
        this.request = function (id, method, params) {
            if (params === void 0) { params = []; }
            return new Promise(function (resolve, reject) {
                var request = createRequest(id, method, params);
                _this.requests[id] = {
                    reject: reject,
                    resolve: resolve,
                    timeout: _this.getRequestTimeout(id, reject)
                };
                if (_this.isReady) {
                    _this.sendRpc(request);
                }
                else {
                    _this.queue.push(request);
                }
            });
        };
        this.handleResponse = function (response) {
            var responder = _this.requests[response.id];
            if (responder) {
                responder.resolve(response); // resolve promise with response
                clearTimeout(responder.timeout); // clear timeout so it does not get rejected
                delete _this.requests[response.id]; // cleanup
            }
        };
        this.client = new WidgetClient(this.request);
        this.isReady = isReady;
        this.queue = queue;
        this.timeout = timeout;
        this.requests = {};
        this.sendRpc = sendRpc;
        this.onReadyPromise = new Promise(function (resolve, reject) {
            _this.requests.onReady = { resolve: resolve, reject: reject };
        });
    }
    return RpcManager;
}());
export { RpcManager };
export default RpcManager;
