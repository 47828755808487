// @flow
import * as React from 'react'
import { connect } from 'react-redux'

import {
  JourneyActions,
  CommonActions,
  ExitIntentActions,
  TrackingActions,
  Events,
  JourneySelectors,
  ResultSelectors
} from 'conversional-journey'
import { exitIntentConfig } from 'conversional-environment'
import store from 'conversional-persistent-storage'

import ExitIntentMobileComponent from './ExitIntentMobileComponent'

type IProps = {
  leadText: string,
  buttonText: string,
  onOpen: () => {},
  logCta: (string, string) => void,
  wasOpened: boolean,
  style: {},
  exitIntentMobile: any,
  theme: any,
  openLauncher: () => {},
  closeExitIntentMobile: () => {},
  trackEvent: () => {}
}

type IState = {
  hidden: boolean,
  displayOnScroll: boolean
}

class ExitIntentMobileContainer extends React.Component<IProps, IState> {
  state = { hidden: false, displayOnScroll: false }

  exitIntentShouldShow() {
    if (store.getItem(exitIntentConfig.HIDE_FLAG)) {
      return false
    }
    return true
  }

  componentDidMount() {
    if (this.exitIntentShouldShow()) {
      this.timeout = setTimeout(
        () => this.setState({ hidden: false }),
        exitIntentConfig.DELAY_TO_ACTIVATE
      )
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  close = () => {
    this.props.trackEvent(Events.EXITINTENTMOBILE_CLOSE)
    this.hidePermanently()
  }

  hidePermanently = () => {
    store.setItem(exitIntentConfig.HIDE_FLAG, true)
    this.props.closeExitIntentMobile()
  }

  open = () => {
    this.props.logCta('exitIntentMobile')
    this.props.trackEvent(Events.EXITINTENTMOBILE_OPEN)
    this.hidePermanently()
    this.props.onOpen()
  }

  render() {
    return (
      <ExitIntentMobileComponent
        activate={() => this.setState({ displayOnScroll: true })}
        buttonText={this.props.exitIntentMobile.buttonText}
        close={this.close}
        displayOnScroll={this.state.displayOnScroll}
        leadText={this.props.exitIntentMobile.leadText}
        open={this.open}
        theme={this.props.theme}
        style={this.props.exitIntentMobile.style}
      />
    )
  }
}

const mapStateToProps = state => ({
  wasOpened: Boolean(
    JourneySelectors.selectQuestionnaire(state) || ResultSelectors.selectResultContent(state)
  ),
  exitIntentMobile: JourneySelectors.selectExitIntentMobile(state)
})

const mapDispatchToProps = {
  logCta: CommonActions.logCta,
  onOpen: JourneyActions.openJourney,
  trackEvent: TrackingActions.event,
  closeExitIntentMobile: ExitIntentActions.closeExitIntentMobile
}

export default connect(mapStateToProps, mapDispatchToProps)(ExitIntentMobileContainer)
