import { generatePath } from 'react-router-dom'
import * as paths from './paths'

export const getJourneyApi = questionnaireId => ({
  path: questionnaireId
    ? generatePath(paths.GET_JOURNEY_PATH, { questionnaireId })
    : paths.GET_JOURNEY_PATH
})

export const getQuestionnaireApi = questionnaireId => ({
  path: questionnaireId
    ? generatePath(paths.GET_QUESTIONNAIRE_PATH, { questionnaireId })
    : paths.GET_QUESTIONNAIRE_PATH
})

export const sendAnswersApi = (questionnaireId, answers, fields, meta) => {
  const Answers = answers.reduce((acc, { id, selectedOptions }) => {
    let value

    if (selectedOptions.constructor === Array) {
      value = selectedOptions.map(option => option.value)
    } else {
      value = selectedOptions.value
    }

    acc[id] = value
    return acc
  }, {})

  const Customer = fields.reduce((acc, cur) => {
    acc[cur.name] = cur.value
    return acc
  }, {})

  const data = {
    Id: questionnaireId,
    Answers,
    Customer,
    meta
  }

  return {
    path: paths.SEND_ANSWERS_PATH,
    method: 'POST',
    payload: { data }
  }
}

export const customEndpointApi = (path, data) => ({
  path: path,
  method: 'POST',
  payload: { ...data }
})

export const getResultsApi = () => ({
  path: paths.GET_RESULT_PATH
})

export const sendFeedbackApi = (rating, alias, comment) => ({
  path: paths.SEND_FEEDBACK_PATH,
  method: 'POST',
  query: { alias },
  payload: { comment, rating }
})

export const getLocationsApi = ({ closeTo, journeyId }) => {
  const query = {}
  if (closeTo) {
    query.close_to = closeTo
  }
  query.journeyId = journeyId
  return {
    path: paths.GET_LOCATIONS_PATH,
    query
  }
}
