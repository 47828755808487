const THRESHOLD = 120;
const DELAY = 400;

export default function onLeaveSite(callback = function() {}) {
  const eventExit = 'mouseleave';
  const stopExit = 'mouseenter';
  const target = window.document.documentElement;
  let _delayTimer = null;

  function handleMouseLeave(e) {
    if (e.clientY > THRESHOLD) {
      return;
    }
    _delayTimer = setTimeout(triggerExitIntent, DELAY);
  }

  function handleMouseEnter() {
    if (_delayTimer) {
      clearTimeout(_delayTimer);
      _delayTimer = null;
    }
  }

  function triggerExitIntent() {
    callback();
  }

  target.addEventListener(eventExit, handleMouseLeave);
  target.addEventListener(stopExit, handleMouseEnter);
}
