// @flow
import React from 'react'
import { css, StyleSheet } from 'aphrodite/no-important'
import throttle from 'lodash.throttle'

import { matches } from '../../utilities/ie11'

export const SCROLLED_ELEMENT = '#conversional-posed-modal'
export const REF_ELEM_HEIGHT = '.rt-result'

export const getScrolledPercent = target => {
  const scrolled = target.scrollTop
  const wrapped = window.document.body.querySelector(REF_ELEM_HEIGHT)

  if (wrapped) {
    const height = wrapped.offsetHeight - window.innerHeight
    return (scrolled / height) * 100
  }

  return 0
}

const ScrollIndicator = (props: any) => {
  const [width, setWitdh] = React.useState(0)

  const scrollIndicatorStyle = css(Styles(props.theme).scrollIndicator)

  const onScroll = event => {
    const { target } = event

    if (target && matches(target, SCROLLED_ELEMENT)) {
      setWitdh(getScrolledPercent(target))
    }
  }

  const listenerOptions = {
    capture: true,
    passive: true
  }

  React.useEffect(() => {
    const throttledScroll = throttle(onScroll, 100)

    window.document.addEventListener('scroll', throttledScroll, listenerOptions)

    return () => {
      window.document.removeEventListener('scroll', throttledScroll, listenerOptions)
    }
  })

  return <div className={scrollIndicatorStyle} style={{ width: `${width}vw` }} />
}

export default ScrollIndicator

export const Styles = theme =>
  StyleSheet.create({
    scrollIndicator: {
      backgroundColor: theme.colors.brand,
      boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.05)',
      height: theme.spaces.desktop.xs,
      left: 0,
      position: 'fixed',
      top: 0,
      transform: 'translate3d(0,0,0)',
      transition: 'width 0.25s ease-out',
      zIndex: 999
    }
  })
