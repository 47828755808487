import { RPC_VERSION } from "./constants";
/**
 * Create a simple JSON-RPC 2.0 request object
 */
export var createRequest = function (id, method, params) {
    if (params === void 0) { params = []; }
    return ({
        id: id,
        method: method,
        jsonrpc: RPC_VERSION,
        params: params,
    });
};
