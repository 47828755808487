//  problem with type hinting the refs
import * as React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Responsive from 'react-responsive'
import { StyleSheet } from 'aphrodite/no-important'
import { boxes } from 'conversional-theme'
import { CommonSelectors, ResultSelectors } from 'conversional-journey'
import includes from 'lodash.includes'
import orderBy from 'lodash.orderby'
import omit from 'lodash.omit'

import ScrollableSliderComponent from './ScrollableSliderComponent'
import BaseComponent from '../base/BaseComponent'
// Todo: read style parameter for SELECTED material and put that first

// Import card types
import CardComponent from '../card/CardComponent'
import RatingCardComponent from '../card/RatingCardComponent'
import IconListCardComponent from '../card/IconListCardComponent'
import ImageCardComponent from '../card/ImageCardComponent'
import Flexbox from '../flexbox/FlexboxComponent'

type Props = {
  cards: Array<{
    img: string,
    title: string,
    ratings: Array<{
      name: string,
      stars: number
    }>
  }>,
  styles: {},
  cardType: string,
  cardSize: string,
  categories: string[],
  swipeDesktop: boolean,
  swipeMobile: boolean,
  theme: {},
  profile: {
    material: string[]
  }
}

type State = {
  showControls: boolean
}

const CARDS = {
  simple: CardComponent,
  rating: RatingCardComponent,
  icon: IconListCardComponent,
  nested: ImageCardComponent
}

/**
 * Slider Component: Renders a list of cards depending on the quantity and resolution
 * Mobile: The slider is resolved using horizontal scrolling
 * Desktop: if cards < 4, the slider is rendered as a simple flexbox (no swiping); otherwise it shows a "draggable" slider
 */
export class ScrollableSliderContainer extends React.Component<Props, State> {
  // TODO maybe move this standalone, pure function somewhere else?
  static cardMatchesProfile(profile, categories, card) {
    if (categories.length === 0) return false
    return categories.map(category => includes(profile[category], card.label)).every(Boolean)
  }

  static defaultProps = {
    cards: [],
    cardType: 'simple',
    categories: [],
    swipeDesktop: false,
    swipeMobile: true
  }

  slider = React.createRef()
  viewBox = React.createRef()

  constructor(props: Props) {
    super(props)
    this.state = {
      activeControls: false
    }
  }

  componentDidMount() {
    // Timeout required so that CSS rendering is finished when calculating
    setTimeout(() => {
      if (this.viewBox.current) {
        this.setState({
          activeControls: this.viewBox.current.offsetWidth < this.slider.current.scrollWidth
        })
      }
    }, 0)
  }

  render() {
    const {
      cards,
      styles,
      cardType,
      cardSize,
      categories,
      profile = {},
      swipeDesktop,
      swipeMobile,
      theme
    } = this.props
    const { slider, viewBox } = this
    const Card = CARDS[cardType]

    const { activeControls } = this.state
    const scrollDesktop = cards.length > 4 || swipeDesktop

    const containerWidth = this.slider.current ? this.slider.current.scrollWidth : 0
    const viewWidth = this.viewBox.current ? this.viewBox.current.offsetWidth : 0

    const decoratedCards = categories
      ? cards.map(card => ({
          ...card,
          selected: ScrollableSliderContainer.cardMatchesProfile(profile, categories, card)
        }))
      : cards

    const orderedCards = orderBy(decoratedCards, 'selected').reverse()

    const children = orderedCards.map((card, i) => (
      <Card
        theme={theme}
        key={card.img + '_' + i}
        {...omit(card, ['selected'])}
        size={cardSize}
        selected={card.selected}
      />
    ))

    return (
      <React.Fragment>
        <Responsive minWidth={boxes.md.min}>
          {scrollDesktop ? (
            <BaseComponent injectedStyles={styles}>
              <ScrollableSliderComponent
                ref={{
                  viewBox,
                  slider
                }}
                nativeScroll={false}
                containerWidth={containerWidth}
                viewWidth={viewWidth}
                showControls={false && activeControls}
                theme={theme}
              >
                {children}
              </ScrollableSliderComponent>
            </BaseComponent>
          ) : (
            <BaseComponent injectedStyles={styles}>
              <Flexbox flexDirection="row" spots={children} />
            </BaseComponent>
          )}
        </Responsive>

        {/* MOBILE */}
        <Responsive maxWidth={boxes.sm.max}>
          {swipeMobile ? (
            <BaseComponent injectedStyles={styles} styles={DefaultStyles.scrollRoot}>
              <ScrollableSliderComponent
                ref={{
                  viewBox,
                  slider
                }}
                nativeScroll={true}
                showControls={this.state.activeControls}
              >
                {children}
              </ScrollableSliderComponent>
            </BaseComponent>
          ) : (
            <BaseComponent injectedStyles={styles}>
              <Flexbox flexDirection="row" spots={children} />
            </BaseComponent>
          )}
        </Responsive>
      </React.Fragment>
    )
  }
}

const DefaultStyles = StyleSheet.create({
  scrollRoot: {
    maxWidth: '100%',
    overflow: 'hidden'
  }
})

const mapStateToProps = createStructuredSelector({
  theme: CommonSelectors.selectTheme,
  profile: ResultSelectors.selectResultProfile
})

export default connect(mapStateToProps)(ScrollableSliderContainer)
