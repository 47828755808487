//@flow
import React from 'react'
import Draggable from 'react-draggable'
import { sizes, mediaBreakpointDown } from 'conversional-theme'

import { css, StyleSheet } from 'aphrodite/no-important'
import Icon from '../icons/Icon'
import { ICONS } from '../icons/IconList'

type IProps = {
  children?: Array<React.Node>,
  showArrows: boolean,
  viewWidth: number,
  containerWidth: number,
  theme: {}
}

const STEP = 120
const PADDING = 3

/**
 * Uses 'react-draggable' to let the user "drag" any element
 * `bound` sets the margins where the user can drag. This Component assumes all elements start at x=0, y=0.
 *
 * For other types of dragging see: https://www.npmjs.com/package/react-draggable
 */
class DraggableComponent extends React.Component<IProps> {
  constructor(props) {
    super(props)

    this.state = {
      controlledPosition: {
        x: 0,
        y: 0
      },
      containerWidth: props.containerWidth,
      viewWidth: props.viewWidth
    }

    this.adjustXPos = this.adjustXPos.bind(this)
    this.moveLeft = this.moveLeft.bind(this)
    this.moveRight = this.moveRight.bind(this)
    this.onControlledDrag = this.onControlledDrag.bind(this)
    this.getRightMargin = this.getRightMargin.bind(this)
  }

  moveRight(e) {
    const { x, y } = this.state.controlledPosition
    const { viewWidth, containerWidth } = this.state

    if (x + containerWidth - STEP >= viewWidth) {
      this.adjustXPos(e, -STEP)
    } else {
      this.setState({ controlledPosition: { x: this.getRightMargin() + PADDING, y } })
    }
  }

  moveLeft(e) {
    const { x, y } = this.state.controlledPosition

    if (x + STEP <= 0) {
      this.adjustXPos(e, STEP)
    } else {
      this.setState({ controlledPosition: { x: -PADDING, y } })
    }
  }

  adjustXPos(e, STEP) {
    e.preventDefault()
    e.stopPropagation()
    const { x, y } = this.state.controlledPosition
    this.setState({ controlledPosition: { x: x + STEP, y } })
  }

  onControlledDrag(e, position) {
    const { x, y } = position
    this.setState({ controlledPosition: { x, y } })
  }

  getRightMargin() {
    return -(this.state.containerWidth - this.state.viewWidth)
  }

  render() {
    const { children, showArrows, theme } = this.props

    return (
      <div>
        <Draggable
          position={this.state.controlledPosition}
          onDrag={this.onControlledDrag}
          bounds={{ top: 0, bottom: 0, right: 0, left: this.getRightMargin() }}
        >
          <div>{children}</div>
        </Draggable>

        {showArrows && (
          <span
            className={css(Styles(theme).navElement, Styles(theme).navElementLeft)}
            onClick={this.moveLeft}
          >
            <Icon theme={theme} icon={ICONS.chevron} color={'#FFF'} rotation={180} />
          </span>
        )}

        {showArrows && (
          <span
            className={css(Styles(theme).navElement, Styles(theme).navElementRight)}
            onClick={this.moveRight}
          >
            <Icon theme={theme} icon={ICONS.chevron} color={'#FFF'} />
          </span>
        )}
      </div>
    )
  }
}

const Styles = theme =>
  StyleSheet.create({
    navElement: {
      position: 'absolute',
      top: '45%',
      cursor: 'pointer',
      borderRadius: '50%',
      backgroundColor: 'rgba(0,0,0,0.7)',
      color: theme.colors.textOnBrand,
      width: '39px',
      height: '39px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [mediaBreakpointDown(sizes.sm)]: {
        display: 'none'
      }
    },
    navElementLeft: {
      left: theme.spaces.desktop.s
    },
    navElementRight: {
      right: theme.spaces.desktop.s
    }
  })

export default DraggableComponent
