import { getTracker } from 'conversional-analytics';
import { STYLE_CHECK } from "./config";
import { sendOpenJourney, sendLogCTA } from "./messages";
var styles = ".rt-stylecheck {cursor: pointer;}";
var createStyleTag = function () {
    var style = document.createElement('style');
    style.type = 'text/css';
    style.appendChild(document.createTextNode(styles));
    document.head.appendChild(style);
};
export var enableRtStyleCheck = function () {
    createStyleTag();
    var rtStyleChecks = window.document.querySelectorAll("." + STYLE_CHECK);
    for (var i = 0; i < rtStyleChecks.length; i++) {
        var rtStyleCheck = rtStyleChecks[i];
        // @ts-ignore
        getTracker().event({ category: 'callToAction', action: 'shown', label: rtStyleCheck, hostTracking: false });
    }
    window.document.addEventListener('click', function (e) {
        try {
            var target = e.target;
            var journeyId = e.target.dataset.journeyId || null;
            var isStyleCheck = Boolean(target.classList.contains(STYLE_CHECK));
            var hasStyleCheckParent = Boolean(target.closest("." + STYLE_CHECK));
            if (isStyleCheck || hasStyleCheckParent) {
                sendOpenJourney(journeyId);
                sendLogCTA('callToAction', target);
                // @ts-ignore
                getTracker.event({ category: 'event', action: 'clicked', label: 'undefined', hostTracking: true });
            }
        }
        catch (error) {
            // ignore elements that are not style checks
        }
    }, false);
};
