import { createSelector } from 'reselect'
import * as JourneySelectors from '../../journey/selectors'

export const selectResultState = state => state.result || {}

export const selectResult = createSelector(
  selectResultState,
  JourneySelectors.selectSelectedJourneyId,
  (result, selectedJourney) => result[selectedJourney] || {}
)

export const selectResultProfile = createSelector(selectResult, result => result.profile)

export const selectResultContent = createSelector(selectResult, result => result.content)

export const selectResultReadyState = createSelector(selectResult, result => result && result.ready)

export const selectCustomerAlias = createSelector(
  selectResult,
  result => result.customer && result.customer.alias
)
