export var styleParentBody = function (style) {
    for (var prop in style) {
        var value = style[prop];
        if (typeof value === 'number') {
            value = value + "px";
        }
        window.document.body.style[prop] = value;
    }
};
// apply style to host (client)
export var applyStylesToHost = function () {
};
