export const modalStyle = {
  borderRadius: 0,
  bottom: 0,
  height: '100%',
  left: 0,
  right: 0,
  top: 0,
  width: '100% !important',
  overflow: 'hidden'
};

export const offStyle = {
  borderRadius: 0,
  bottom: 0,
  height: 0,
  left: '100vw',
  right: 0,
  top: '100vh',
  width: 0
};

export const parentOnStyle = {
  overflow: 'hidden'
};

export const parentOffStyle = {
  overflow: 'auto'
};
