// @flow
import React from 'react'
import PlaceSearchComponent from '../placeSearch/PlaceSearchComponent'
import { css } from 'aphrodite/no-important'

import Primary from '../buttons/Buttons'
import { ICONS } from '../icons/IconList'
import Icon from '../icons/Icon'
import makeStyles from './PlaceSearchWrapper.styles'

type IProps = {
  theme: any,
  image?: string
}

const PlaceSearchWrapper = (props: IProps) => {
  const { theme, image } = props
  const containerClass = css(makeStyles(theme).container)
  const headlineClass = css(makeStyles(theme).headline)
  const hintClass = css(makeStyles(theme).hint)
  const contentClass = css(makeStyles(theme).contentBox)
  const inputContainerClass = css(makeStyles(theme).inputContainer)

  return (
    <div className={containerClass}>
      <h5 className={headlineClass}>Gleich geschafft, bitte geben Sie Ihren Ort an:</h5>
      <div className={contentClass}>
        <ImageBox theme={theme} image={image} />
        <div className={inputContainerClass}>
          <span className={hintClass}>Für die Suche nach Küchen-Fachhändlern in Ihrer Region</span>
          <PlaceSearch {...props} />
        </div>
      </div>
    </div>
  )
}

const ImageBox = ({ image, theme }: IProps) => {
  if (!image) {
    return null
  }

  const imageContainerClass = css(makeStyles(theme).imageContainer)
  const imageClass = css(makeStyles(theme).image)
  return (
    <div className={imageContainerClass}>
      <img src={image} className={imageClass} />
    </div>
  )
}

const PlaceSearch = props => {
  const iconClass = css(makeStyles(props.theme).icon)
  const buttonStyles = makeStyles(props.theme).button

  return (
    <PlaceSearchComponent {...props}>
      <Primary theme={props.theme} customStyles={buttonStyles}>
        weiter
        <span className={iconClass}>
          <Icon theme={props.theme} icon={ICONS.chevron} color={props.theme.colors.textOnBrand} />
        </span>
      </Primary>
    </PlaceSearchComponent>
  )
}

export default PlaceSearchWrapper
