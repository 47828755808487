import { METHODS } from "./constants";
var WidgetClient = /** @class */ (function () {
    function WidgetClient(request) {
        var _this = this;
        /**
         * Trigger a JSON-RPC request to show Exit Intent
         */
        this.openExitIntent = function (id) {
            if (id === void 0) { id = Date.now(); }
            return _this.request(id, METHODS.OPEN_EXIT_INTENT);
        };
        /**
         * Trigger a JSON-RPC request to disable Exit Intent
         */
        this.disableExitIntent = function (id) {
            if (id === void 0) { id = Date.now(); }
            return _this.request(id, METHODS.DISABLE_EXIT_INTENT);
        };
        /**
         * Trigger a JSON-RPC request to enable Exit Intent
         */
        this.enableExitIntent = function (id) {
            if (id === void 0) { id = Date.now(); }
            return _this.request(id, METHODS.ENABLE_EXIT_INTENT);
        };
        this.request = request;
    }
    return WidgetClient;
}());
export { WidgetClient };
export default WidgetClient;
