// @flow
import * as React from 'react'
import Responsive from 'react-responsive'
import { css, StyleSheet } from 'aphrodite/no-important'

import { boxes, text } from 'conversional-theme'
import IconButton from '../buttons/IconButtonComponent'

// Todo: add type hinting
// Todo: add ability to actually open other components (thorugh extended dummy component)
// Test open/close

type ComponentProps = {
  componentKey: string,
  componentTitle: string,
  theme: {}
}

type CommonProps = {
  activeOverlay: string,
  spots: {
    [string]: (props: ComponentProps) => React.Node
  },
  open: (component: string, e: Event) => void,
  close: () => void,
  theme: {}
}

type Props = {
  styles: {
    [string]: {},
    mobileStyles: {
      [string]: {
        string?: string | number
      }
    }
  }
}

type ChildProps = {
  activeOverlay: string, // is not optional any more
  spotList: Array<string>,
  activeOverlay: string,
  styles?: {
    [string]: {
      order?: number
    }
  }
}

// Todo: style SPAN element
// Todo: Add transition effects
const Desktop = ({
  activeOverlay,
  spots,
  spotList,
  open,
  close,
  theme
}: CommonProps & ChildProps) => (
  <div
    className={css(generalStyles(theme).overlay, activeOverlay && generalStyles(theme).openOverlay)}
  >
    <div className={css(generalStyles(theme).close)}>
      <IconButton close={close} theme={theme} />
    </div>

    <div className={css(generalStyles(theme).navigation)}>
      {spotList.map(spot => {
        return (
          <span
            onClick={e => open(spot, e)}
            className={css(
              generalStyles(theme).navigationElement,
              activeOverlay === spot && generalStyles(theme).selected
            )}
            key={`desktop_${spot}`}
          >
            {spots[spot].props.children.props.componentTitle}
          </span>
        )
      })}
    </div>
    {activeOverlay && (
      <div className={css(generalStyles(theme).overlayContent)}>{spots[activeOverlay]}</div>
    )}
  </div>
)

const Mobile = ({ activeOverlay, spots, spotList, styles }: CommonProps & ChildProps) => (
  <React.Fragment>
    {spotList.map(spot => {
      const elementStyles = styles && styles[spot]
      return (
        <div
          key={`mobile_${spot}`}
          className={css(
            mobileStyles(elementStyles).flexItem,
            activeOverlay === spot && mobileStyles().show
          )}
        >
          {spots[spot]}
        </div>
      )
    })}
  </React.Fragment>
)

function InteractiveGridOverlayComponent({
  spots,
  open,
  close,
  activeOverlay,
  styles,
  theme
}: CommonProps & Props) {
  if (!spots || !activeOverlay || !spots[activeOverlay]) {
    return <div className={css(generalStyles(theme).overlay)} />
  }

  const spotList = Object.keys(spots)
  const childProps = { spots, spotList, open, close, activeOverlay }
  return (
    <React.Fragment>
      <Responsive minWidth={boxes.md.min}>
        <Desktop {...childProps} theme={theme} />
      </Responsive>
      <Responsive maxWidth={boxes.sm.max}>
        <Mobile {...childProps} styles={styles.mobileStyles} theme={theme} />
      </Responsive>
    </React.Fragment>
  )
}

InteractiveGridOverlayComponent.defaultProps = {
  activeOverlay: ''
}

const generalStyles = theme =>
  StyleSheet.create({
    overlay: {
      position: 'relative',
      width: '0',
      height: '0',
      overflow: 'hidden',
      opacity: 0,
      display: 'flex',
      flexDirection: 'column',
      gridColumn: '1/end',
      gridRow: '1/end',
      MsGridColumn: '1',
      MsGridColumnSpan: '10',
      MsGridRow: '1',
      MsGridRowSpan: '10'
    },
    openOverlay: {
      width: '100%',
      height: '100%',
      backgroundColor: '#FFF',
      opacity: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    overlayContent: {
      margin: `${theme.spaces.desktop.l} 0px 0px ${theme.spaces.desktop.l}`,
      paddingBottom: 50,
      overflowX: 'hidden',
      overflowY: 'auto'
    },
    close: {
      position: 'absolute',
      top: `${theme.spaces.desktop.s}`,
      right: `${theme.spaces.desktop.s}`
    },
    navigation: {
      display: 'flex',
      flexDirection: 'row',
      textAlign: 'center',
      margin: `${theme.spaces.desktop.s}`,
      justifyContent: 'center'
    },
    navigationElement: {
      ...text(theme).link,
      ...text(theme).h4,
      marginLeft: `${theme.spaces.desktop.s}`,
      marginRight: `${theme.spaces.desktop.s}`,
      marginTop: 'auto',
      textDecoration: 'none',
      transition: 'all 250ms ease-out',
      borderBottom: '4px solid transparent',
      ':hover': {
        borderBottom: `4px solid ${theme.colors.brand}`
      }
    },
    selected: {
      color: theme.colors.brand,
      borderBottom: '4px solid'
    }
  })

const mobileStyles = styles =>
  StyleSheet.create({
    flexItem: {
      margin: '0 !important',
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
      order: 'inherit',
      display: 'none',
      overflow: 'hidden',
      transition: 'height 500ms ease-out',
      ...styles,
      ':nth-child(1n) > div > *': {
        maxWidth: '100%'
      }
    },
    show: {
      display: 'block'
    }
  })

export default InteractiveGridOverlayComponent
