import * as Actions from '../actions'

export const initialState = false

export function modal(state = initialState, action) {
  if (action.type === Actions.OPEN_MODAL) {
    return true
  }
  if (action.type === Actions.CLOSE_MODAL) {
    return false
  }
  return state
}
