// @flow
import * as React from 'react'
import { connect } from 'react-redux'
import { TrackingActions, Events, CommonSelectors } from 'conversional-journey'
import { createStructuredSelector } from 'reselect'

import InteractiveGridComponent from './InteractiveGridComponent'
import BaseComponent from '../base/BaseComponent'

type Props = {
  cols: number,
  rows: number,
  componentKey: string,
  spots: {
    GridElements: React.Element<*>,
    GridDetails: ?React.Element<*>
  },
  styles: {},
  theme: {},
  dispatch: () => {}
}

type State = {
  activeOverlay: ?string
}

export class InteractiveGridContainer extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = { activeOverlay: null }
  }

  open = (component: string, e: Event) => {
    e.preventDefault()

    if (!this.props.spots.GridDetails) {
      return
    }

    // If the component is opened, just close it
    if (this.state.activeOverlay === component) {
      this.setState({ activeOverlay: null })
      this.props.dispatch(
        TrackingActions.event({
          ...Events.INTERACTIVEGRID_CLOSE,
          category: `${Events.INTERACTIVEGRID_CLOSE.category} | ${this.props.componentKey}`,
          label: component
        })
      )
      return
    }

    // Get the available children elements with overlay
    const details = this.props.spots.GridDetails.props.children
    const availableComponents = Object.keys(details.props.spots)
    if (availableComponents.indexOf(component) !== -1) {
      this.props.dispatch(
        TrackingActions.event({
          ...Events.INTERACTIVEGRID_OPEN,
          category: `${Events.INTERACTIVEGRID_CLOSE.category} | ${this.props.componentKey}`,
          label: component
        })
      )
      this.setState({ activeOverlay: component })
    }
  }

  close = () => {
    this.props.dispatch(
      TrackingActions.event({ ...Events.INTERACTIVEGRID_OPEN, label: this.state.activeOverlay })
    )
    this.setState({ activeOverlay: null })
  }

  render() {
    const { spots, rows, cols, componentKey, styles, theme } = this.props
    return (
      <BaseComponent injectedStyles={styles}>
        <InteractiveGridComponent
          key={componentKey}
          spots={spots}
          cols={cols}
          rows={rows}
          activeOverlay={this.state.activeOverlay}
          open={this.open}
          close={this.close}
          theme={theme}
        />
      </BaseComponent>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  theme: CommonSelectors.selectTheme
})

export default connect(mapStateToProps)(InteractiveGridContainer)
