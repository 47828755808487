import { createSelector } from 'reselect'
import { initialState, initialConfigState } from '../reducers'
import { initialState as initialJourneyState } from '../../journey/reducers'

export const selectCommonState = state => state.common || initialState
export const selectConfig = state => state.config || initialConfigState
export const selectDefaultJourneyId = createSelector(selectConfig, config => config.questionnaireId)

// Importing this from '../../jourenys/selectors' is throwing unnecessary
export const selectSelectedJourneyId = state =>
  (state.journey || initialJourneyState).selectedJourney

export const selectThemes = createSelector(selectCommonState, common => common.theme)

export const selectTheme = createSelector(
  selectThemes,
  selectSelectedJourneyId,
  (themes, selected) => themes[selected]
)

export const selectDefaultTheme = createSelector(
  selectThemes,
  selectDefaultJourneyId,
  (themes, selected) => themes[selected]
)

export const selectToken = createSelector(
  selectConfig,
  selectSelectedJourneyId,
  (config, selected) => config.tokens[selected]
)
