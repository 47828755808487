// @flow
import * as React from 'react'
import { css, StyleSheet } from 'aphrodite/no-important'

import Icon from '../icons/Icon'
import { ICONS } from '../icons/IconList'

type RatingProps = {
  title: string,
  ratings: Array<{ name: string, stars: number }>,
  theme: {}
}

/**
 * RatingBaseComponent
 * Purpose      Renders a list of ratings, e.g. star ratings
 * Responsive   No
 * Nestable     False
 * Base         True
 * // Todo: reconsider: maybe do the nesting per configuration? Is this a base component?
 */
function RatingBase({ title, ratings, theme }: RatingProps) {
  return (
    <div className={css(Styles(theme).flexVertical)}>
      <p className={css(Styles(theme).title)}>{title}</p>
      <div className={css(Styles(theme).flex)}>
        <table>
          <tbody>
            {ratings.map(rating => (
              <tr key={`rating_${title}_${rating.name}`}>
                <td>{rating.name}</td>
                <td className={css(Styles(theme).rating)}>
                  <Icon
                    theme={theme}
                    key={`rating_${title}_${rating.name}`}
                    icon={ICONS.star}
                    multiple={rating.stars}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const Styles = theme =>
  StyleSheet.create({
    flex: {
      display: 'flex',
      flexDirection: 'row'
    },
    flexVertical: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spaces.desktop.s
    },
    rating: {
      paddingLeft: theme.spaces.desktop.s
    },
    title: {
      fontFamily: theme.typo.fonts.bold,
      textTransform: 'uppercase',
      marginTop: theme.spaces.desktop.xs,
      marginBottom: theme.spaces.desktop.m
    }
  })

export default RatingBase
