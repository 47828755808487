import React from 'react'
import { css } from 'aphrodite/no-important'
import classNames from 'classnames'

import BaseComponent from '../base/BaseComponent'
import makeStyles from './Intro.styles'

const Intro = (props: any) => {
  const styles = makeStyles(props.theme, props.backgroundImage)

  return (
    <BaseComponent styles={styles.rtIntroGrid} injectedStyles={props.styles}>
      <div className={css(styles.rtIntroContent)}>
        <p className={css(styles.rtSmall)}>
          Powered by{' '}
          <a href="https://conversion.al" target="_blank" rel="noopener noreferrer">
            Conversional
          </a>
        </p>
        <img src={props.image} className={classNames('logo', css(styles.img))} alt="" />
        <div className={css(styles.rtText)}>
          <p>
            <b>{props.title}</b>
          </p>
          <p>{props.description}</p>
        </div>
      </div>
      <div className={css(styles.rtIntroBackground)} />
    </BaseComponent>
  )
}

export default Intro
