import * as Actions from '../actions'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import entries from 'lodash/entries'
import first from 'lodash/first'
import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'

import { locationsUtils } from 'conversional-utils'
import { CommonActions } from '../../..'

/*
RULES FOR REDUCERES
should not have any side effects
should not read global application state
should not manipulate data structures passed with the action,
it should only pick the right data and merge it into state
*/
export function result(state = {}, action) {
  switch (action.type) {
    case Actions.LOAD_RESULTS_SUCCEEDED:
      return {
        ...state,
        [action.journeyId]: {
          ...action.result,
          ready: true
        }
      }

    case CommonActions.SET_CONFIG:
      if (get(action, 'payload.config.tokens')) {
        const [journeyId, token] = first(entries(get(action, 'payload.config.tokens')))
        if (get(state, `${journeyId}.config.alias`) !== token) {
          return omit(cloneDeep(state), [journeyId])
        }
      }

      return state

    default:
      return state
  }
}

export const getLocationsStatus = (state = 'IDLE', action) => {
  switch (action.type) {
    case Actions.GET_LOCATIONS:
      return 'IN_PROGRESS'

    case Actions.GET_LOCATIONS_FAILURE:
      return 'FAILED'

    case Actions.GET_LOCATIONS_SUCCESS:
      return 'DONE'

    case Actions.SET_LOCATIONS:
      return 'DONE'

    default:
      return state
  }
}

export const locations = (state = [], action) => {
  switch (action.type) {
    case Actions.GET_LOCATIONS_SUCCESS: {
      if (!isEmpty(action.response)) {
        return locationsUtils.locationsToArray([], action.response)
      }
      return state
    }

    case Actions.SET_LOCATIONS: {
      const { payload } = action
      if (!Array.isArray(payload)) {
        return locationsUtils.locationsToArray([], payload)
      }
      return payload
    }

    default:
      return state
  }
}

export const isEmptyLocations = (state = false, action) => {
  switch (action.type) {
    case Actions.GET_LOCATIONS_SUCCESS: {
      if (isEmpty(action.response)) {
        return true
      }
      return state
    }

    default:
      return state
  }
}

export const placeSearchError = (state = '', action) => {
  switch (action.type) {
    case Actions.GET_LOCATIONS_FAILURE: {
      return action.error
    }

    case Actions.GET_LOCATIONS_SUCCESS: {
      return ''
    }

    default:
      return state
  }
}

export const filterByPlaceSearch = (state = false, action) => {
  switch (action.type) {
    case Actions.FILTER_LOCATIONS_BY_PLACE_SEARCH: {
      return true
    }
    default:
      return state
  }
}
