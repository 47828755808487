export default [
  'journey.loading',
  'journey.error',

  'questionnaire.loading',
  'questionnaire.error',

  'getLocationsStatus',
  'locations',

  'router',
  'journeyLoaded',
  'questions'
];
